import { TripDestination } from '@packages/types';

/**
 * get sorted destinations by isTrending, isCity, countryName
 * @param destinations
 */
export const getSortedDestinations = (destinations: TripDestination[]): TripDestination[] => {
  return destinations.sort((a: TripDestination, b: TripDestination) => {
    if (a.isTrending && !b.isTrending) {
      return -1;
    }
    if (!a.isTrending && b.isTrending) {
      return 1;
    }
    if (a.isCity && !b.isCity) {
      return -1;
    }
    if (!a.isCity && b.isCity) {
      return 1;
    }
    return (a.countryName ?? '').localeCompare(b.countryName ?? '');
  });
};
