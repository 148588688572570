import { Boost } from '@packages/types';

import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { ROUTES, ROUTE_PATHS, getCurrentPathname, router } from '@routes/router';
import { closeModalSelector, useBoostDetailsStore, useModalStore, useTripStore } from '@store';
import { testProps } from '@utils';

import { Text } from '../text';

const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
export function BoostModalHeader({ boost }: { boost: Boost }) {
  const closeModal = useModalStore(closeModalSelector);
  const isCruisePopUp = useTripStore(
    state => state.cruiseBoostModalState === 'DISPLAY' && boost?.code === 'CRSF',
  );
  const setCruiseBoostModalState = useTripStore(state => state.setCruiseBoostModalState);
  const isShowingDetailsButton = !getCurrentPathname()?.includes(ROUTES.BOOST_DETAILS);
  const boostName = boost?.name ?? '';

  const onClick = async () => {
    await getBoostDetails(boost.boostId);
    router.navigate(ROUTE_PATHS().boostDetails(boost.boostId));
    if (isCruisePopUp) {
      setCruiseBoostModalState('SEEN');
    }
    closeModal();
  };

  return (
    <div className="flex flex-row  items-center">
      <Text className="uppercase" variant="body-16/r">
        {boostName}
      </Text>
      {isShowingDetailsButton && (
        <Button
          {...testProps(`show-trip-extra-detail-button`)}
          variant="secondary"
          size="sm"
          className="ml-2 mb-1"
          onClick={onClick}
          title={i18n.t('boostDetails.modal.actions.details')}
        />
      )}
    </div>
  );
}
