import get from 'lodash/get';
import { useMemo, useState } from 'react';

import { REGIONS } from '@packages/constants';
import { getRegionSelector, useRegionStore } from '@packages/stores';
import { SelectOption, State } from '@packages/types';

import { Container } from '@components/container';
import { Text } from '@components/text';
import { Button, ButtonProps } from '@elements/button';
import { Dropdown } from '@elements/dropdown';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { useTripStore } from '@store';
import { testProps } from '@utils';

const setRegion = useRegionStore.getState().setRegion;
const resetTripState = useTripStore.getState().resetTripState;

export const StateOfResidence = () => {
  const region = useRegionStore(getRegionSelector);
  const [selectedState, setSelectedState] = useState(region?.state);

  const canGoNext = !!selectedState;

  const states = useMemo<State[]>(
    () => (region?.country === 'AU' ? [] : get(REGIONS, `${region?.country}.states`) || []),
    [region?.country],
  );

  const selectedValue = states.find(item => item.code === selectedState);
  const defaultValue = {
    id: selectedValue?.code,
    label: selectedValue?.name,
    value: selectedValue?.code,
  } as SelectOption;

  const handleNext = () => {
    if (region?.country && canGoNext) {
      resetTripState(); // reset trip sortKey and trip state for new policy flow
      setRegion({ ...region, state: selectedState });
      router.navigate(ROUTE_PATHS().tripDestinations);
    }
  };

  return (
    <main>
      <Container
        size="xl"
        titleText={i18n.t('stateOfResidence.title')}
        subtitleText={i18n.t('stateOfResidence.subtitle')}
        className="flex flex-1 flex-col"
        hasRegionResidencyStatus>
        <div className="mx-auto w-full max-w-lg flex-1 z-10">
          <Dropdown
            items={states.map(item => ({
              id: item.code,
              label: item.name,
              value: item,
            }))}
            onSelect={item => setSelectedState((item as State).code)}
            inputProps={{
              placeholder: i18n.t('stateOfResidence.input.placeholder'),
              rightIcon: (props: ButtonProps) => <Dropdown.Button as={Button} {...props} />,
            }}
            initialValue={defaultValue}
          />
          <div className="mt-6 flex w-full flex-col items-center gap-4 border-t pt-6">
            <Button
              {...testProps('state-of-residence-agreement-next-button')}
              variant="primary"
              size="md"
              disabled={!canGoNext}
              onClick={handleNext}
              title="Next"
              className="w-full"
            />

            <Text variant="subHeading-14/m" withMarkDown>
              {i18n.t('stateOfResidence.footNote.title')}
            </Text>
          </div>
        </div>
      </Container>
    </main>
  );
};
