import { closeModalSelector, openModalSelector, useModalStore } from '@store';

export const useEditTravellersModal = () => {
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);

  const onNext = async () => {
    closeModal();
  };

  const openEditTravellersModal = () =>
    openModal('Modal', {
      body: {
        type: 'EditTravellers',
        isModal: true,
        onNext: onNext,
        onBack: closeModal,
      },
      size: 'lg',
      sendExitEvent: true,
    });

  return { openEditTravellersModal };
};
