import { Assets } from '@assets';

interface AddToAppleWalletProps {
  onClick: () => void;
}

export const AddToAppleWallet = ({ onClick }: AddToAppleWalletProps) => {
  return (
    <button onClick={onClick}>
      <Assets.AddToAppleWallet />
    </button>
  );
};
