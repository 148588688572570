import { Assets } from '@assets';
import { Text } from '@components';
import { useCheckoutStore } from '@store';
import { capitalizeFirstLetter } from '@utils';

export const GoogleEditPayment = () => {
  const setGoogleCreditCardDetails = useCheckoutStore(state => state.setGoogleCreditCardDetails);
  const googleCreditCardDetails = useCheckoutStore(state => state.googleCreditCardDetails);
  return (
    <div className="w-full mx-auto -mt-8">
      <div className="flex flex-col w-full">
        <button
          className="flex flex-start underline mb-4"
          onClick={() =>
            setGoogleCreditCardDetails({
              cardNumber: '',
              cardType: undefined,
              paymentToken: '',
            })
          }>
          Edit Payment Method
        </button>
        <div className="flex flex-row border-y border-gray-200 py-5 space-x-2 mt-6">
          <Text variant="subHeading-14/r">Pay with</Text>
          <Assets.GooglePay height={20} width={35} className="rounded-full" />
          <Text variant="subHeading-14/r">
            {capitalizeFirstLetter(googleCreditCardDetails?.cardType)}
            ****{googleCreditCardDetails?.cardNumber}
          </Text>
        </div>
      </div>
    </div>
  );
};
