import React from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Note. Please wrap up this component with a parent div and add a class animate-pulse for that animation effect.
 */
const skeletonStyle = 'bg-surface-disabled h-s16 rounded-md ';

type SkeletonDivProps = {
  numberOfRows?: number;
} & React.HTMLAttributes<HTMLDivElement>;

export const SkeletonDiv = ({ className, numberOfRows = 1 }: SkeletonDivProps) => {
  const rows = Array.from({ length: numberOfRows }, () => 'paragraph');
  return (
    <>
      {rows.map((row, index) => (
        <div key={`${row}-${index}`} className={twMerge(skeletonStyle, className)} />
      ))}
    </>
  );
};
