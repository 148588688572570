import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { cognitoCredentialsProvider } from 'aws-amplify/auth/cognito';
import shuffle from 'lodash/shuffle';

// Returns the username from cognito user info
export const getUsername = (userInfo: any) => userInfo?.sub ?? userInfo.userSub ?? null;

export const currentAuthenticatedUser = async () => {
  return await getCurrentUser();
};

export const currentCredentials = async (refreshGuest = false) => {
  try {
    if (refreshGuest) {
      await cognitoCredentialsProvider.clearCredentialsAndIdentityId();
    }
    const authSession = await fetchAuthSession();

    return {
      ...authSession.credentials,
      identityId: authSession.identityId,
      cognitoIdentityId: authSession.identityId,
    };
  } catch {
    /* empty */
  }
  return null;
};

export const refreshCognitoSession = async (onError?: (err: Error) => void) => {
  try {
    await fetchAuthSession({ forceRefresh: true });
  } catch {
    if (onError) {
      onError(new Error('Something went wrong in refresh token.'));
    }
  }
};

export const getCognitoJwtToken = async () => {
  try {
    const authSession = await fetchAuthSession();
    return authSession.tokens?.idToken?.toString() ?? '';
  } catch {
    return '';
  }
};

export const getAuthHeader = async (isAuthenticated: boolean) =>
  isAuthenticated ? {} : { identity: await currentCredentials() };

export const generatePassword = () => {
  const charsets = [
    {
      chars: 'abcdefghijklmnopqrstuvwxyz',
      occurances: 6,
    },
    {
      chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      occurances: 6,
    },
    {
      chars: '0123456789',
      occurances: 2,
    },
    { chars: '^$*.[]{}()?"!@#%&/,><=+-', occurances: 2 },
  ];
  const password = [];
  for (let c = 0; c < charsets.length; c++) {
    for (let i = 0, n = charsets[c]?.chars.length; i < (charsets[c]?.occurances ?? 0); ++i) {
      password.push(charsets[c]?.chars.charAt(Math.floor(Math.random() * (n ?? 1))));
    }
  }
  return shuffle(password).join('');
};
