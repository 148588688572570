import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import {
  getRegionSelector,
  protectionRestrictionDaysSelector,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import { DepositDateForm, FormOnChange } from '@packages/types';
import { getRegionDateTime, validateDepositDate } from '@packages/utils';

import { usePriceChangeModal } from '@hooks';
import { i18n } from '@i18n';
import { closeModalSelector, tripDepositDateSelector, useModalStore, useTripStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

import { Calendar } from '../calendar';
import { Container } from '../container';
import { FooterButtonGroup } from '../footerButtonGroup';
import { GetQuoteProvider } from '../getQuoteProvider';

export type SelectDepositDateProps = {
  onBack: () => void;
  onNext: () => void;
  isModal?: boolean;
};

export const SelectDepositDate = (props: SelectDepositDateProps) => {
  const depositDate = useTripStore(tripDepositDateSelector) ?? '';
  return (
    <GetQuoteProvider defaultFormValues={{ depositDate }} mode="all" reValidateMode="onChange">
      <SelectDepositDateContent {...props} />
    </GetQuoteProvider>
  );
};

export const SelectDepositDateContent = ({ onBack, onNext, isModal }: SelectDepositDateProps) => {
  // global state
  const region = useRegionStore(getRegionSelector);
  const protectionRestrictionDays = useConfigStore(protectionRestrictionDaysSelector);
  const validateTripDuration = useTripStore(state => state.validateTripDuration);
  const tripDepositDate = useTripStore(tripDepositDateSelector);
  const setTripDepositDate = useTripStore(state => state.setDepositDate);
  const currentDate = getRegionDateTime(region?.country);
  const { openPriceChangeModal } = usePriceChangeModal();
  const syncTrip = useTripStore(state => state.syncTrip);
  const closeModal = useModalStore(closeModalSelector);

  //formstate
  const {
    control,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useFormContext<DepositDateForm>();
  const depositDate = watch('depositDate');

  // local state

  const handleSelectDateDuration = (onChange: FormOnChange) => (depositDateValue: string) => {
    onChange(depositDateValue);
  };

  const onSubmit = async (data: DepositDateForm) => {
    if (isModal) {
      return openPriceChangeModal({
        onNext: async () => {
          setTripDepositDate(data.depositDate);
          onNext();
          await syncTrip();
          closeModal();
        },
      });
    } else {
      setTripDepositDate(data.depositDate);
      sendAnalyticsEvent('Quote Submitted');
      onNext();
    }
    sendAnalyticsEvent('Trip Deposit Date Added', {
      depositDate: data.depositDate,
    });
  };

  return (
    <div className="flex flex-auto justify-center">
      <div className="flex w-full max-w-[56.25rem] flex-col items-center pb-8">
        <Container
          size="xl"
          className="flex flex-col items-center justify-center"
          titleText={i18n.t('depositDate.title')}
          subtitleText={i18n.t('depositDate.subtitle')}>
          <Controller
            control={control}
            name="depositDate"
            rules={{
              validate: {
                validateDepositDate: v =>
                  validateDepositDate({ selectedDepositDate: v, isModal, tripDepositDate }),
              },
            }}
            render={({ field: { onChange } }) => {
              return (
                <Calendar
                  editMode
                  onSelectDuration={handleSelectDateDuration(onChange)}
                  country={region?.country || 'US'}
                  numberOfProtectionRestrictionDays={protectionRestrictionDays}
                  maxDate={currentDate}
                  onValidate={validateTripDuration}
                  mode="DAY"
                  startDate={depositDate}
                />
              );
            }}
          />
          <hr className="border-cabo-200 my-6 w-full" />
          <div className={twMerge('flex w-full flex-col gap-4', isModal && 'md:flex-row-reverse')}>
            <FooterButtonGroup
              nextButtonProps={{
                variant: 'primary',
                title: isModal
                  ? i18n.t('depositDate.actions.save')
                  : i18n.t('depositDate.actions.quote'),
                onClick: handleSubmit(onSubmit),
                disabled: !isValid,
                className: 'w-full',
              }}
              backButtonProps={{
                title: isModal
                  ? i18n.t('depositDate.actions.cancel')
                  : i18n.t('global.actions.back'),
                onClick: onBack,
                className: 'w-full',
                variant: 'secondary',
              }}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};
