import { useFormContext } from 'react-hook-form';

import { regionDateUtils, useConfigStore } from '@packages/stores';
import { TravellersForm } from '@packages/types';
import { getTravellerAgeType } from '@packages/utils';

import { IsDependantCheckBox } from './isDependentCheckBox.component';

type Props = {
  index: number;
};

const SecondaryTravellerDependencyComponent = {
  ['Young Adult']: IsDependantCheckBox,
  ['Child']: IsDependantCheckBox,
  ['Adult']: ReturnNull,
};

export function DependencyCheckBox({ index }: Props) {
  const {
    watch,
    formState: { errors },
  } = useFormContext<TravellersForm>();

  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const residentCheckBoxStatus = regionSpecificConfig?.RULES.RESIDENT_CHECKBOX;
  const secondaryTravellerErrors = errors.secondaryTravellers?.[index];
  const secondaryTravellerAgeRules = regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE ?? {
    min: 0,
    mid: { min: 18, max: 21 },
    max: 99,
  };
  const dob = watch(`secondaryTravellers.${index}.dob`) ?? '';
  const dobError = !!secondaryTravellerErrors?.['dob'];

  if (dobError || !residentCheckBoxStatus || !dob) {
    return null;
  }

  const age = regionDateUtils().getAge(dob);
  const travellerType = getTravellerAgeType(age, secondaryTravellerAgeRules);
  const DependencyComponent = SecondaryTravellerDependencyComponent?.[travellerType] ?? ReturnNull;

  return (
    <div className="flex flex-row">
      <DependencyComponent index={index} />
    </div>
  );
}

function ReturnNull() {
  return null;
}
