import { HTMLProps, useMemo } from 'react';

import { FREELY_WEB_DEFAULT_PAYMENTS } from '@packages/constants';
import { FreelyPaymentMethod, PAYMENT_TYPE } from '@packages/types';

import { Assets } from '@assets';
import { AfterPayBanner } from '@components/afterPayBanner';
import { useTripStore } from '@store';

export interface Props extends HTMLProps<HTMLDivElement> {
  variant?: 'footer';
}

export const PaymentMethodIcons = ({ variant, ...rest }: Props) => {
  const getPaymentMethods = useMemo(() => {
    const isFooter = variant === 'footer';
    const payments: FreelyPaymentMethod[] = [...FREELY_WEB_DEFAULT_PAYMENTS];

    if (!isFooter) {
      payments.push(PAYMENT_TYPE.APPLE_PAY, PAYMENT_TYPE.GOOGLE_PAY);
    }

    payments.push('AfterPay');

    return payments;
  }, [variant]);

  return (
    <div {...rest} className="gap-1 flex">
      {getPaymentMethods.map(it => (
        <PaymentMethodIcon type={it} key={it} />
      ))}
    </div>
  );
};

function PaymentMethodIcon({ type }: { type: FreelyPaymentMethod }) {
  const Icon = Assets[type];
  const totalPrice = useTripStore(state => state?.trip?.price);

  if (type === 'AfterPay') {
    return <AfterPayBanner variant="icon" price={totalPrice} />;
  }

  return <Icon height={29} width={40} />;
}
