export const emcSharedScreeningResultAU = {
  summary: {
    description:
      'We can provide cover for your medical condition listed below. An additional premium may apply',
    additionalPremium:
      'Would you like to include cover for your approved condition(s) for an additional premium?',
  },
  button: {
    redeclaring: 'Continue to Payment',
    covered: {
      primary: 'Yes, Include Cover',
      secondary: 'No, Decline Cover',
    },
    rejected: {
      primary: 'Continue',
    },
  },
  notification: {
    heading: 'Unfortunately we are unable to offer cover for your existing medical conditions. ',
    description: 'Please contact us if you wish to discuss this outcome.',
  },
  tripSummary: {
    additionalPremium: {
      title: 'Additional Premium',
    },
    total: 'Total',
    boost: {
      duration_one: '{{count}} day',
      duration_other: '{{count}} days',
    },
  },
  assessmentNo: 'Assessment No. {{assessmentNumber}}',
  paymentSummaryTitle: 'Payment Summary',
  conditionCard: {
    button: 'Change',
    title: {
      covered: 'Assessed medical condition(s):',
      notCovered: 'We cannot provide cover for your medical condition listed below.',
    },
  },
};
