import { CreateStore } from '../utils/utils';

export type ModalParams<P> = {
  Modal: P;
  RegionGridModal: P;
  FreelyModal: P;
  FullScreenModal: P;
};

export type ModalName<P> = keyof ModalParams<P>;

type ModalProp<P, T extends ModalName<P>> = ModalParams<P>[T];

export interface ModalConfig<P> {
  modalName: ModalName<P>;
  params?: ModalProp<P, ModalName<P>> & { body?: any };
}

export interface ModalState<P> {
  modals: ModalConfig<P>[];
  uniqueModals: ModalName<P>[];
  currentOpenModals: string[];
}

export interface ModalActions<P, K = void> {
  resetModalState: () => void;
  openModal: <T extends ModalName<P>>(modalName: T, params: ModalParams<P>[T]) => void;
  closeModal: () => void;
  closeAllModals: () => void;
  closeSingleModal: (modalName: K) => void;
}

export type ModalStore<P, K = void> = ModalState<P> & ModalActions<P, K>;

export const modalStoreGenerator = <P, K = void>(initialState?: Partial<ModalState<P>>) => {
  const _initialState: ModalState<P> = {
    modals: [...(initialState?.modals ?? [])],
    uniqueModals: [...(initialState?.uniqueModals ?? [])],
    currentOpenModals: [],
  };

  const modalStore: CreateStore<ModalStore<P, K>> = set => ({
    ..._initialState,
    openModal: (modalName, params) => {
      set(state => {
        if (
          (state.uniqueModals.includes(modalName) &&
            state.modals.find(m => m.modalName === modalName)) ||
          state.currentOpenModals.includes((params as any)?.body?.type)
        ) {
          return;
        }
        state.modals.push({ modalName, params: { ...params } as never });
        state.currentOpenModals.push((params as any)?.body?.type);
      });
    },
    closeAllModals: () => {
      set(state => {
        state.modals = [];
        state.currentOpenModals = [];
      });
    },
    closeModal: () => {
      set(state => {
        state.modals.pop();
        state.currentOpenModals.pop();
      });
    },
    resetModalState: () => set(_initialState, false),
    closeSingleModal: modalName => {
      set(state => {
        state.modals = state.modals.filter(m => m?.params?.body?.type !== modalName);
        state.currentOpenModals = state.currentOpenModals.filter(m => m !== modalName);
      });
    },
  });

  return modalStore;
};
