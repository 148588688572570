import React from 'react';

import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { testProps } from '@utils';

import { Text } from '../../text';

export interface Props {
  onNext: () => Promise<void> | void;
  isLoading: boolean;
  isNextDisabled: boolean;
}

export const DestinationsFooter: React.FC<Props> = ({ isLoading, isNextDisabled, onNext }) => {
  return (
    <div className="flex w-full flex-col items-center gap-4 justify-items-start">
      <Button
        variant="primary"
        onClick={onNext}
        title="Next"
        isLoading={isLoading}
        disabled={isNextDisabled}
        className="w-full"
        {...testProps('country-destination-select-next-button')}
      />
      <Text className="leading-5" variant="subHeading-14/m" withMarkDown>
        {i18n.t('selectDestinations.footer.note')}
      </Text>
    </div>
  );
};
