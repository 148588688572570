import { shallow } from 'zustand/shallow';

import { colors } from '@packages/design';

import { Assets } from '@assets';
import { ListItem } from '@components/listItem';

import { useTripStore } from '@store';

export const AutoDiscountCard = () => {
  const [appliedDiscountValue, appliedAutoDiscountCodeLabel] = useTripStore(
    state => [
      state.trip.promotionCode?.discount ?? '',
      state.trip.promotionCode?.autoDiscountCodeLabel,
    ],
    shallow,
  );

  if (!appliedAutoDiscountCodeLabel || !appliedDiscountValue) {
    return null;
  }

  return (
    <ListItem
      leftIcon={
        <div className="w-5 h-5">
          <Assets.Redeem fill={colors.icon.brand[2]} />
        </div>
      }
      disabled
      variant="edit"
      backgroundColor="bg-surface-informative"
      titleTextProps={{ children: appliedAutoDiscountCodeLabel, variant: 'body-16/sb' }}
      rightText={{ children: appliedDiscountValue }}
      className="pt-s12"
    />
  );
};
