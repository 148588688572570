import { useCallback } from 'react';

import { getBoostModalType } from '@packages/utils';

import { useBoostSelectionModal } from '@hooks';
import { getSelectedBoostById, useTripStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

interface UseBoostSelection {
  boostId: string;
}

/**
 * This hook is used to handle the boost selection: add/remove/edit
 * 1. opens modals for specific boost items to add/edit boosts
 * 2. adds boost to trip if not already added
 * 3. handles removal of boosts if already added
 * @param boostId
 * @returns onPrimaryAction
 */
export const useBoostSelection = ({ boostId }: UseBoostSelection) => {
  const addBoost = useTripStore(state => state.addBoost);
  const removeBoost = useTripStore(state => state.removeBoost);

  const selectedBoost = useTripStore(getSelectedBoostById(boostId));
  const isBoostAddedToTrip = selectedBoost?.toUpdate?.isAdded ?? selectedBoost?.isAdded;
  const isMandatoryBoostAdded = !!selectedBoost?.isMandatory && isBoostAddedToTrip;

  const openBoostSelectionModal = useBoostSelectionModal(boostId);
  const boostModalType = getBoostModalType(selectedBoost);

  const onPrimaryAction = async () => {
    if (isMandatoryBoostAdded) {
      return;
    }

    // add specified items will have the remove all option if the boost is added
    if (boostModalType === 'addSpecifiedItems' && isBoostAddedToTrip) {
      sendAnalyticsEvent('All Specified Items Removed');
      return await removeBoost({ boostId });
    }

    /**
     * Opens boost selection modal, if the boost is not added
     */
    if (boostModalType) {
      return openBoostSelectionModal();
    }

    /**
     * Boost is added to trip
     * So we remove
     */
    if (isBoostAddedToTrip) {
      return await removeBoost({ boostId });
    }

    /**
     * All other scenarios will lead the action to add the boost
     */

    return await addBoost({ boostId, updatePayload: {} });
  };

  const onEdit = useCallback(() => {
    if (boostModalType) {
      return openBoostSelectionModal();
    }
  }, [boostModalType, openBoostSelectionModal]);

  return { onPrimaryAction, onEdit };
};
