import React from 'react';

import { TRIP_STATUS } from '@packages/types';

import { Container } from '@components';
import { Button } from '@elements/button';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { useTripStore } from '@store';

import { Text } from '../../text';

const ConfirmationHeader: React.FC = () => {
  const { openRedirectToFreelyModal } = useNavigateToFreely();
  const tripStatus = useTripStore(state => state.trip.state);
  const isPaid = tripStatus === TRIP_STATUS.PAID;
  return (
    <div className="bg-cabo-50 relative flex flex-row">
      <Button
        variant="tertiary"
        onClick={openRedirectToFreelyModal}
        icon="FreelyPrime"
        className="[&>svg]:h-8 [&>svg]:w-20 lg:[&>svg]:h-12 lg:[&>svg]:w-24 [&>svg]:text-fuji-800 xl:absolute xl:top-1/2 transform xl:-translate-y-1/2 "
      />
      <Container className="bg-cabo-50">
        {isPaid && (
          <div className="lg:max-w-content flex flex-1 items-center">
            <div className="py-2">
              <Text variant={{ sm: 'body-16/sb', md: 'subTitle-20/sb', lg: 'h4-24/sb' }}>
                {i18n.t('checkout.confirmation.header.title')}
                <span className="text-fuji-300">
                  {i18n.t('checkout.confirmation.header.title2')}
                </span>
              </Text>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default ConfirmationHeader;
