import gql from 'graphql-tag';

import * as fragments from './fragments';

export const postCustomerTextMessage = gql`
  mutation PostCustomerTextMessage($input: PostChatMessageInput!) {
    postCustomerTextMessage(input: $input) {
      ... on HyperMessageEvent {
        __typename
        id
        eventType
        hyperMessage {
          ...HyperMessageFields
        }
        userId
      }
    }
  }
  ${fragments.HyperMessageFields}
`;

export const downloadFileObject = gql`
  mutation DownloadFileObject($input: DownloadFileObjectInput!) {
    downloadFileObject(input: $input) {
      id
      presignedUrl
      error
    }
  }
`;

export const patchPinpoint = gql`
  mutation PatchPinpoint($input: PatchPinpointInput!) {
    patchPinpoint(input: $input) {
      id
    }
  }
`;

export const rateTicket = gql`
  mutation rateTicket($input: RateTicketInput!) {
    rateTicket(input: $input) {
      ... on HyperMessageEvent {
        __typename
        id
        eventType
        hyperMessage {
          ...HyperMessageFields
        }
        userId
      }
    }
  }
  ${fragments.HyperMessageFields}
`;

export const postTypingStatus = gql`
  mutation PostTypingStatus($input: PostTypingStatusInput!) {
    postTypingStatus(input: $input) {
      eventType
      ... on TypingEvent {
        __typename
        id
        isTyping
        userId
        sender {
          firstName
          id
          lastName
        }
      }
    }
  }
`;
