import { FC } from 'react';

import { useRegionStore } from '@packages/stores';

import { BookingDateListItem as US, Props as USProps } from './us/bookingDate.component';

type Props = USProps;

export const BookingDateListItem: FC<Props> = props => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent({ US });
  return <Component {...props} />;
};
