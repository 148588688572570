import { BOOST_CODE, Boost } from '@packages/types';

import { i18n } from '@i18n';
import { useToastStore } from '@store';

const useDisplayToast = () => {
  const addToast = useToastStore(state => state.addToast);

  const displayBoostToast = (boost: Boost) => {
    if (boost.code === BOOST_CODE.MOTORCYCLE) {
      return addToast({
        id: boost.boostId,
        message: `${i18n.t('boostModals.boostUpsell.motorCycleAdded')}`,
      });
    }
  };

  return { displayBoostToast };
};

export { useDisplayToast };
