import React from 'react';
import { createRoot } from 'react-dom/client';

import '@i18n';
import { i18n } from '@i18n';

import { App } from './src/App';
import './src/styles/index.css';

(async () => {
  if (location.pathname?.includes('/us')) {
    await i18n.changeLanguage('en-US');
  }

  createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
})();
