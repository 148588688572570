export const QuoteLoader: React.FC = () => (
  <div className="flex justify-center items-center">
    <svg
      className="animate-spin"
      width="121"
      height="121"
      viewBox="0 0 121 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.8381 7.78606C87.7605 10.4568 94.089 14.4648 99.4624 19.5812C104.836 24.6977 109.149 30.8224 112.155 37.6058C115.162 44.3891 116.802 51.6981 116.984 59.1156C117.166 66.533 115.885 73.9136 113.214 80.836C110.543 87.7584 106.535 94.0869 101.419 99.4603C96.3024 104.834 90.1777 109.147 83.3944 112.153C76.6111 115.16 69.302 116.8 61.8846 116.982C54.4671 117.164 47.0865 115.883 40.1641 113.212C33.2418 110.541 26.9132 106.533 21.5398 101.417C16.1664 96.3003 11.8534 90.1756 8.847 83.3923C5.84062 76.609 4.19974 69.2999 4.01807 61.8825C3.83639 54.465 5.11747 47.0844 7.78816 40.162C10.4589 33.2397 14.4669 26.9111 19.5833 21.5377C24.6998 16.1643 30.8245 11.8513 37.6079 8.84491C44.3912 5.83852 51.7002 4.19765 59.1177 4.01597C66.5351 3.83429 73.9158 5.11537 80.8381 7.78607L80.8381 7.78606Z"
        stroke="#D3DCE4"
        strokeWidth="8"
      />
      <path
        d="M80.8381 7.78606C87.7605 10.4568 94.089 14.4648 99.4624 19.5812C104.836 24.6977 109.149 30.8224 112.155 37.6058C115.162 44.3891 116.802 51.6981 116.984 59.1156C117.166 66.533 115.885 73.9136 113.214 80.836C110.543 87.7584 106.535 94.0869 101.419 99.4603C96.3024 104.834 90.1777 109.147 83.3944 112.153C76.6111 115.16 69.302 116.8 61.8846 116.982C54.4671 117.164 47.0865 115.883 40.1641 113.212"
        stroke="#AAFFDE"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  </div>
);
