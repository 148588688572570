import React from 'react';

import { BoostCoverTable } from '@packages/types';

import { sluggify, testProps } from '@utils';

import { Text } from '../text';

interface Props {
  item: BoostCoverTable;
}

export const TableItem: React.FC<Props> = ({ item }) => {
  return (
    <div>
      <Text
        variant="body-16/sb"
        className="pb-3.5"
        {...testProps(sluggify(item.columnNames.title))}>
        {item.columnNames.title}
      </Text>
      <table className="w-full">
        <tbody>
          {item.items.map((rowItem, index) => {
            return (
              <tr className="border-y-2 border-gray-200" key={index}>
                <td className="py-4 px-0">
                  <Text variant="body-16/r" {...testProps(sluggify(rowItem.title))}>
                    {rowItem.title}
                  </Text>
                </td>
                <td className="py-4 text-right">
                  <Text
                    variant="body-16/sb"
                    {...testProps(`${sluggify(rowItem.title)}-description`)}>
                    {rowItem.value}
                  </Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
