import { useEffect } from 'react';

import { EmcDataType } from '@packages/types';

import { EmcNeedHelp, Text } from '@components';
import { Notification } from '@elements/notification';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import { sendAnalyticsEvent } from '@utils';

import { BoostSummary } from './boostSummary/boostSummary.component';
import { ConditionCard } from './conditionCard/conditionCard.component';
import { EmcScreeningResultsFooterButtons } from './emcScreeningResultsFooterButtons.component';

export const EmcScreeningResult = () => {
  const { emcCurrentTraveller } = useEmcTraveller();
  const { isCovered, assessmentNumber, riskScore } = emcCurrentTraveller as EmcDataType;

  useEffect(() => {
    sendAnalyticsEvent('Medical assessment completed', {
      TravellerID: emcCurrentTraveller?.travellerId ?? '',
      Coverage: isCovered ? 'covered' : 'not covered',
    });
  }, [emcCurrentTraveller?.travellerId, isCovered]);

  return (
    <div>
      {!isCovered && (
        <div className="mb-8">
          <Notification
            header={i18n.t('emcShared.screeningResult.notification.heading')}
            body={i18n.t('emcShared.screeningResult.notification.description')}
            variant="error"
          />
        </div>
      )}

      <div>
        {isCovered && (
          <Text variant="body-16/r" className="pb-4">
            {i18n.t('emcShared.screeningResult.summary.description')}
          </Text>
        )}
        <div className="md:w-[548px]">
          <ConditionCard coveredType="covered" className="mb-4" />
          <ConditionCard coveredType="notCovered" className="mb-4" />
          {import.meta.env.VITE_ENV !== 'prod' && isCovered ? (
            <Text variant="body-16/sb" className="my-2 text-red-500 ">
              Risk Score: {riskScore}
            </Text>
          ) : null}
        </div>

        {assessmentNumber && (
          <Text variant="body-16/sb" className="pb-4">
            {i18n.t('emcShared.screeningResult.assessmentNo', { assessmentNumber })}
          </Text>
        )}
      </div>

      {isCovered && <BoostSummary />}
      <div className="flex flex-col md:flex-row items-center gap-10 md:justify-end md:mt-5">
        <EmcScreeningResultsFooterButtons />
        <EmcNeedHelp className="sm:hidden mt-3 mb-8" />
      </div>
    </div>
  );
};
