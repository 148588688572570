import zod from 'zod';

export const storeSchema = zod.object({
  VITE_ENV: zod
    .union([zod.literal('dev'), zod.literal('staging'), zod.literal('prod'), zod.literal('dev3')])
    .optional(),
  VITE_ZUSTAND_PERSIST_STATE: zod.string().optional(),
  VITE_ZUSTAND_LOGGER: zod.string().optional(),
});

export const storeEnv = storeSchema.parse((import.meta as any).env);
