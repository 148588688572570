import React, { useCallback, useEffect } from 'react';

import { useToastStore } from '@store';

import { Toast } from '../toast';

const removeInitialToast = useToastStore.getState().removeInitialToast;
export const ToastsBoard: React.FC = () => {
  const toasts = useToastStore(state => state.toasts);
  const removeToast = useToastStore(state => state.removeToast);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | null | undefined = null;
    if (toasts.length > 0) {
      interval = setInterval(() => {
        removeInitialToast();
      }, 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [toasts]);

  const onOk = useCallback(
    (id: string) => {
      removeToast(id);
    },
    [removeToast],
  );

  return toasts.length > 0 ? (
    <div className="fixed left-4 right-4 top-4 z-50 sm:left-auto">
      <div className="mx-auto space-y-2">
        {toasts.map(({ id, message }) => (
          <Toast key={id} message={message} onOk={() => onOk(id)} />
        ))}
      </div>
    </div>
  ) : null;
};
