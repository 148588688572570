import { AccordionContainer, ExcessAmount, Text } from '@components';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { testProps } from '@utils';

import { BenefitsSkeleton } from './benefits.skeleton';

export const Benefits = () => {
  const boostMessage = useBoostDetailsStore(
    state => state.mandatoryBoostDetails?.template?.boostMessage,
  );
  const isMandatory = useBoostDetailsStore(state => state.mandatoryBoostDetails?.isMandatory);
  const title = useBoostDetailsStore(state => state.mandatoryBoostDetails?.template?.title);
  const isLoading = useBoostDetailsStore(state => state.pending);
  if (isLoading && isMandatory === undefined) {
    return <BenefitsSkeleton />;
  }

  return (
    <div>
      <div className="flex flex-row items-center flex-wrap mb-4">
        <Text
          variant={{
            lg: 'h3-28/sb',
            md: 'h3-28/sb',
            sm: 'subTitle-20/sb',
          }}
          className="pr-1 md:pr-2"
          {...testProps('benefits-heading')}>
          {title ?? ''}
        </Text>
        <Text
          variant={{ lg: 'h4-24/r', sm: 'body-16/r' }}
          {...testProps('benefits-heading-appendix')}>
          {i18n.t('myQuote.benefitsWhatsIncluded')}
        </Text>
        <ExcessAmount className="hidden md:flex md:ml-auto" />
      </div>
      <Text
        variant="body-16/r"
        className="mb-4 md:mb-8"
        {...testProps('benefits-heading-description')}>
        {boostMessage}
      </Text>
      <ExcessAmount className="mb-8 md:hidden" />
      <AccordionContainer />
    </div>
  );
};
