import { get, post, put } from 'aws-amplify/api';

import { ERROR_CODE } from '@packages/constants';
import { AWSInit, EmailQuoteDetails, Region, TimeOutErrorMessage, Trip } from '@packages/types';
import { getAuthHeader, throwError } from '@packages/utils';

const handleQuoteError = (error: any) => {
  const errorJson = JSON.parse(error.response?.body);
  const quoteError = new Error(errorJson.message ?? 'Getting quote failed. Please try again.');

  quoteError.name = errorJson?.errorCode;

  const errorMessage = errorJson?.message;

  if (Array.isArray(errorMessage) && errorMessage.length > 0) {
    if (errorMessage?.find((message: string) => message.includes(ERROR_CODE.QUOTE_ALREADY_PAID))) {
      quoteError.name = ERROR_CODE.QUOTE_ALREADY_PAID;
      throw quoteError;
    }
    if (
      errorMessage?.find((message: string) => message.includes(ERROR_CODE.QUOTE_START_DATE_PASSED))
    ) {
      quoteError.name = ERROR_CODE.QUOTE_START_DATE_PASSED;
      throw quoteError;
    }
    if (
      errorMessage?.find((message: string) => message.includes(ERROR_CODE.QUOTE_END_DATE_PASSED))
    ) {
      quoteError.name = ERROR_CODE.QUOTE_END_DATE_PASSED;
      throw quoteError;
    }
    if (
      errorMessage?.find((message: string) =>
        message.includes(ERROR_CODE.QUOTE_ELIGIBILITY_CHANGED),
      )
    ) {
      quoteError.name = ERROR_CODE.QUOTE_ELIGIBILITY_CHANGED;
      throw quoteError;
    }

    if (errorMessage?.find((message: string) => message.includes(ERROR_CODE.QUOTE_EXPIRED))) {
      quoteError.name = ERROR_CODE.QUOTE_EXPIRED;
      throw quoteError;
    }

    if (errorMessage?.find((message: string) => message.includes(ERROR_CODE.QUOTE_NOT_FOUND))) {
      quoteError.name = ERROR_CODE.QUOTE_NOT_FOUND;
      throw quoteError;
    }

    if (
      errorMessage?.find((message: string) => message.includes(ERROR_CODE.QUOTE_INVALID_STATUS))
    ) {
      quoteError.name = ERROR_CODE.QUOTE_INVALID_STATUS;
      throw quoteError;
    }
  }

  throw quoteError;
};

export const createQuote = async (arg: {
  trip: Trip;
  region: Region;
  isAuthenticated: boolean;
  withTrip?: boolean;
  onError?: (error: unknown) => void;
  queryParams?: AWSInit;
}) => {
  try {
    const apiName = 'Quote';
    const path = `/${arg.region.country.toLowerCase()}/${
      arg.isAuthenticated ? 'auth' : 'unauth'
    }/quotes`;
    const options: AWSInit = {
      body: {
        ...arg.trip,
        createTrip: arg.withTrip,
      },
      queryParams: arg.queryParams,
      ...(await getAuthHeader(arg.isAuthenticated)),
    };

    const result = await post({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    const timeoutMessages: TimeOutErrorMessage[] = ['Penguin Timeout', 'RA Timeout'];

    if (timeoutMessages.includes(error?.response?.body)) {
      if (arg.onError) {
        arg.onError(new Error('Timeout error'));
      }
      return throwError({ error, message: 'Timeout error' });
    }

    if (arg.onError) {
      arg.onError(error);
    }

    return throwError({ error, message: 'Creating quote failed. Please try again.' });
  }
};

export const updateQuote = async (arg: {
  quote: Partial<Trip>;
  region: Region;
  isAuthenticated: boolean;
  onError?: (error: unknown) => void;
  queryParams?: AWSInit;
}) => {
  try {
    const apiName = 'Quote';
    const path = `/${arg.region.country.toLowerCase()}/${
      arg.isAuthenticated ? 'auth' : 'unauth'
    }/quotes/${arg.quote.metaData?.quoteId}`;
    const options: AWSInit = {
      body: {
        ...arg.quote,
        productId: arg.quote.productId ?? arg.quote.promotionCode?.productId ?? '',
      },
      queryParams: arg.queryParams,
      ...(await getAuthHeader(arg.isAuthenticated)),
    };

    const result = await put({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    const timeoutMessages: TimeOutErrorMessage[] = ['Penguin Timeout', 'RA Timeout'];
    if (timeoutMessages.includes(error?.response?.body)) {
      if (arg.onError) {
        arg.onError(new Error('Timeout error'));
      }
      return throwError({ error, message: 'Timeout error' });
    }

    if (arg.onError) {
      arg.onError(error);
    }
    return throwError({ error, message: 'Updating quote failed. Please try again.' });
  }
};

export const fetchQuoteById = async (arg: {
  quoteId: string;
  region: Region;
  isAuthenticated: boolean;
  queryParams?: AWSInit;
}) => {
  try {
    const apiName = 'Quote';
    const path = `/${arg.region.country.toLowerCase()}/${
      arg.isAuthenticated ? 'auth' : 'unauth'
    }/quotes/${arg.quoteId}`;

    const options: AWSInit = {
      queryParams: arg.queryParams,
      ...(await getAuthHeader(arg.isAuthenticated)),
    };
    const result = await get({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    handleQuoteError(error);
    throwError({ error, message: 'Getting quote failed. Please try again.' });
  }
};

export const sendEmailQuote = async (arg: {
  emailQuoteDetails: EmailQuoteDetails;
  quoteId: string;
  region: Region;
  triggerType: 'emailQuote' | 'abandonCart';
}) => {
  try {
    const apiName = 'Quote';
    const path = `/${arg.region.country.toLowerCase()}/unauth/quotes/${arg.quoteId}/ortto`;
    const options: AWSInit = {
      body: {
        ...arg.emailQuoteDetails,
        triggerType: arg.triggerType,
      },
    };

    const result = await post({ apiName, path, options }).response;
    return (await result.body.json()) as any;
  } catch (error) {
    throwError({ error, message: 'Sending email quote failed. Please try again.' });
  }
};
