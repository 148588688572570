import { FC } from 'react';

import { REGIONS } from '@packages/constants';
import { Region } from '@packages/types';

import { useConfigStore } from '../config/config.store';
import { CreateStore, createStore } from '../utils/utils';

export interface RegionState {
  region: Region | undefined;
  pending: boolean;
  error: Error | undefined;
}

export interface RegionActions {
  setRegion: (region: Region) => void;
  resetRegionStore: () => void;
  createRegionSpecificComponent: <T>({ AU, US }: { AU?: FC<T>; US?: FC<T> }) => FC<T>;
}

export type RegionStore = RegionState & RegionActions;

const initialState: RegionState = {
  pending: false,
  region: undefined,
  error: undefined,
};

export const regionStore: CreateStore<RegionStore> = (set, get) => ({
  ...initialState,
  setRegion: (region?: Region) => {
    set(state => {
      if (state.region) {
        state.region.country = region?.country ?? 'AU';
        if (region?.state) {
          state.region.state = region?.state;
        }
      } else {
        state.region = region;
      }
    });
    useConfigStore.getState().setRegionSpecificConfig(region);
  },
  resetRegionStore: () => set(initialState, false),
  createRegionSpecificComponent: ({ AU, US }) => {
    const { region } = get();
    const returnNull = () => null;
    switch (region?.country) {
      case REGIONS.US.code:
        return US ?? returnNull;
      /**
       * Setting up AU region as default region fallback
       */
      default:
        return AU ?? returnNull;
    }
  },
});

export const useRegionStore = createStore(regionStore, {
  name: 'useRegionStore',
});
