import { useEffect } from 'react';

import { SelectDepositDate } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';
import { useGlobalStore, useTripStore } from '@store';
import { sendAnalyticsEvent, sendAnalyticsPage } from '@utils';

export const DepositDate = () => {
  const syncTrip = useTripStore(state => state.syncTrip);
  const handleNext = async () => {
    sendAnalyticsEvent('Quote Submitted');
    useGlobalStore.getState().setIsCovidConsentAgreed(false);

    const newTrip = await syncTrip();
    if (!newTrip) {
      return;
    }
    router.navigate(ROUTE_PATHS().myQuote);
  };

  useEffect(() => {
    sendAnalyticsPage('Deposit Date Page Viewed');
  }, []);

  return <SelectDepositDate onNext={handleNext} onBack={() => router.navigate(-1)} />;
};
