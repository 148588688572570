import { useEffect } from 'react';

import { useRegionStore } from '@packages/stores';

import { LegalConsentListItems as AU } from './au/legalConsentListItems.component';
import { LegalConsentListItems as US } from './us/legalConsentListItems.component';

type Props = {
  onMount?: () => void;
};

export const LegalConsentListItems: React.FC<Props> = ({ onMount }) => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent({ US, AU });

  useEffect(() => {
    onMount?.();
  }, [onMount]);

  return <Component />;
};
