import { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { formatPromoCode } from '@packages/utils';

import { Button } from '@elements/button';
import { Input } from '@elements/input';
import { promoCodeSelector, useTripStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

export const DiscountCodeInput = () => {
  const error = useTripStore(state => state.error);
  const setError = useTripStore(state => state.setError);
  const [isLoading, setIsLoading] = useState(false);
  const applyDiscountCode = useTripStore(state => state.applyDiscountCode);
  const tripPromotionCodeData = useTripStore(promoCodeSelector, shallow);
  const removeDiscountCode = useTripStore(state => state.removeDiscountCode);
  const [discountCode, setDiscountCode] = useState(() => {
    if (tripPromotionCodeData?.discountCode && tripPromotionCodeData?.appLabel) {
      return `${tripPromotionCodeData?.discountCode} (${tripPromotionCodeData?.appLabel})`;
    }
    return '';
  });

  const discountVariant = getDiscountCodeVariant({
    errorMessage: error?.message,
    discountCode: tripPromotionCodeData?.discountCode,
  });

  const handleClick = useCallback(async () => {
    if (!discountCode.trim()) {
      return false;
    }
    setIsLoading(true);
    const formattedDiscountCode = formatPromoCode(discountCode);
    const response = await applyDiscountCode(formattedDiscountCode);
    const { discountCode: appliedDiscountCode, appLabel: appliedDiscountCodeAppLabel } =
      response?.promotionCode ?? {};
    if (appliedDiscountCode && appliedDiscountCodeAppLabel) {
      setDiscountCode(`${appliedDiscountCode} (${appliedDiscountCodeAppLabel})`);
    }

    setIsLoading(false);
  }, [applyDiscountCode, discountCode]);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const handleClearError = () => {
    setError(undefined);

    setDiscountCode('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(undefined);

    setDiscountCode(e.target.value);
  };

  const formatInputOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const formattedCode = formatPromoCode(e.target.value);

    if (formattedCode) {
      sendAnalyticsEvent('Discount Code Typed', { text: formattedCode });
    }
  };

  const handleRemoveDiscountCode = async () => {
    setIsLoading(true);
    await removeDiscountCode();

    setDiscountCode('');
    setIsLoading(false);
  };

  const discountCodeVariant = {
    add: {
      icon: 'add',
      onClick: handleClick,
      variant: 'primary',
      leftIcon: undefined,
    },
    remove: {
      icon: 'close',
      onClick: handleRemoveDiscountCode,
      variant: 'secondary',
      leftIcon: 'loyalty',
    },
    error: {
      icon: 'close',
      onClick: handleClearError,
      variant: 'destructive',
      leftIcon: undefined,
    },
  } as const;

  return (
    <Input
      {...testProps('add-discount-code-input')}
      onBlur={formatInputOnBlur}
      placeholder="Add discount code"
      onChange={handleChange}
      value={discountCode}
      leftIcon={discountCodeVariant[discountVariant]?.leftIcon}
      errorMessage={error?.message}
      readOnly={discountVariant === 'remove'}
      rightIcon={props => (
        <Button
          onClick={discountCodeVariant[discountVariant]?.onClick}
          icon={discountCodeVariant[discountVariant]?.icon}
          {...props}
          variant={discountCodeVariant[discountVariant]?.variant}
          isLoading={isLoading}
          {...testProps('btn-right-icon')}
        />
      )}
      onKeyDown={handleKeyPress}
    />
  );
};

const getDiscountCodeVariant = ({
  errorMessage,
  discountCode,
}: {
  errorMessage?: string;
  discountCode?: string | null;
}) => {
  if (errorMessage) {
    return 'error';
  }
  if (discountCode) {
    return 'remove';
  }
  return 'add';
};
