import { PAYMENT_TYPE } from '@packages/types';

export const getCreditCardToken = async (payload: {
  authToken: string;
  apiUrl: string;
  paymentDetails: {
    cardNumber?: string;
    paymentType?: PAYMENT_TYPE;
    cardHolderName?: string;
    cvv?: string;
    cardExpirationDate?: string;
    country: 'USA';
  };
}) => {
  const headers = new Headers({
    Authorization: `Basic ${payload.authToken}`,
    'Content-Type': 'application/json',
  });

  const body = { paymentDetails: { ...payload.paymentDetails } };
  if (!body.paymentDetails.cvv) {
    delete body.paymentDetails.cvv;
  }
  const init: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  const response = await fetch(payload.apiUrl, init);

  const mediaType = response.headers.get('content-type');

  if (mediaType?.includes('json') && response.ok) {
    const data = (await response.json()) as { token: string };
    return data;
  } else {
    const data = await response.text();
    if (data?.includes('Invalid Card')) throw new Error('Invalid Card');
    throw new Error(data);
  }
};
