import { generateClient } from 'aws-amplify/api';

import { currentCredentials } from '@packages/utils';

import { guestSubscription as gs } from '../graphql/subscriptions';

const client = generateClient();

export const guestSubscription = async (
  onNext: (data: any) => void,
  onError: (data: any) => void,
) =>
  currentCredentials().then(guest =>
    (
      client.graphql({
        query: gs,
        authMode: 'iam',
        variables: {
          userId: guest?.identityId?.split(':')[1],
        },
      }) as any
    ).subscribe({ next: onNext, error: onError }),
  );
