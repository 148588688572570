import { Icon, IconSize } from '@elements/icon';
import { Text } from '@elements/text';
import { sluggify, testProps } from '@utils';
import React, { ButtonHTMLAttributes } from 'react';
import { tv } from 'tailwind-variants';

export type SelectorSize = 'sm' | 'md' | 'lg';

export type SelectorProps = {
  title: string;
  selected?: boolean;
  size?: SelectorSize;
  multiple?: boolean;
  fullWidth?: boolean;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children' | 'type'>;

const selectorVariants = tv({
  base: 'relative flex gap-s8 items-center duration-300 text-body transition-all rounded-full justify-center px-s20',
  variants: {
    selected: {
      false:
        'ring-1 ring-border-bold ring-inset bg-surface-primary hover:active:ring-2 focus:active:ring-2 hover:bg-surface-action-selected hover:active:ring-border-action-active ',
      true: 'ring-2 ring-border-action-active ring-inset bg-surface-action-selected justify-between',
    },
    size: {
      sm: 'py-s4',
      md: 'py-s8',
      lg: 'py-s16',
    },
    multiple: {
      false: 'flex-row-reverse',
    },
    fullWidth: {
      true: 'w-full justify-between',
    },
  },
  compoundVariants: [
    {
      selected: true,
      multiple: true,
      class: 'pr-s16',
    },
  ],
});

export const Selector: React.FC<SelectorProps> = ({
  title,
  multiple = false,
  selected = false,
  size = 'md',
  fullWidth,
  className,
  ...rest
}) => {
  const iconProps = {
    name: multiple ? 'cancel' : 'check',
    size: size === 'lg' ? 'md' : ('sm' as IconSize),
    className: 'text-icon-brand-2',
  };

  return (
    <button
      className={selectorVariants({
        selected,
        size,
        multiple,
        fullWidth,
        className,
      })}
      type="button"
      {...testProps(`selector-${sluggify(title)}`)}
      {...rest}>
      <Text variant={size === 'lg' ? 'subTitle-20/m' : 'body-16/m'}>{title}</Text>
      {selected && <Icon {...iconProps} />}
    </button>
  );
};
