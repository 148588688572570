import { FC } from 'react';

import { useRegionStore } from '@packages/stores';

import {
  AfterPayBanner as AU,
  AfterPayBannerProps as AUProps,
} from './au/afterPayBanner.component';

export const AfterPayBanner: FC<AUProps> = props => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent<AUProps>({ AU });
  return <Component {...props} />;
};
