import { useCallback } from 'react';

import { openModalSelector, useModalStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

import { ModalBody } from '../types/constants';

export const useBoostUpsellModel = () => {
  const openModal = useModalStore(openModalSelector);

  const openBoostUpsellModel = useCallback(
    (modalBody: ModalBody) => {
      sendAnalyticsEvent('Upsell Experiment Modal Opened', {
        upsellType: modalBody.boostUpsellType,
      });
      openModal('Modal', {
        body: modalBody,
        size: 'md',
        sendExitEvent: true,
        onClose: () =>
          sendAnalyticsEvent('Upsell Experiment Modal Closed', {
            upsellType: modalBody.boostUpsellType,
          }),
      });
    },
    [openModal],
  );

  return { openBoostUpsellModel };
};
