import { useRegionStore } from '@packages/stores';

import { TotalPriceButton as AU } from './au/totalPriceButton.component';
import { TotalPriceButton as US } from './us/totalPriceButton.component';

export const TotalPriceButton = () => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent({ AU, US });
  return <Component />;
};
