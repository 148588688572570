import { ROUTES, ROUTE_PATHS, getCurrentPathname } from '@routes/router';

import BoostTopHeader from './boostTopHeader.component';
import ConfirmationHeader from './confirmationHeader/confirmationHeader.component';
import DefaultHeader from './defaultHeader.component';

export const Header = () => {
  const { confirmation } = ROUTE_PATHS();
  const currentPathname = getCurrentPathname();
  const isBoostDetails = currentPathname?.includes(ROUTES.BOOST_DETAILS);
  const isConfirmation = currentPathname === confirmation;

  if (isBoostDetails) {
    return <BoostTopHeader />;
  }

  if (isConfirmation) {
    return <ConfirmationHeader />;
  }

  return <DefaultHeader />;
};
