export enum ERROR_CODE {
  INTERNAL_ERROR = 'internal_error',
  QUOTE_EXPIRED = 'quote_expired',
  QUOTE_ALREADY_PAID = 'quote_already_paid',
  QUOTE_START_DATE_PASSED = 'quote_start_date_passed',
  QUOTE_END_DATE_PASSED = 'quote_end_date_passed',
  QUOTE_ELIGIBILITY_CHANGED = 'quote_eligibility_changed',
  QUOTE_INVALID_STATUS = 'invalid_quote_status',
  QUOTE_NOT_FOUND = 'quote_not_found',
  PAYMENT_IN_PROGRESS = 'payment_in_progress',
}

export const ERROR_TEXT = {
  [ERROR_CODE.INTERNAL_ERROR]: {
    titleI18Text: 'errors.errorBoundary.errorText.internalError.title',
    bodyI18Text: 'errors.errorBoundary.errorText.internalError.body',
  },
  [ERROR_CODE.QUOTE_ALREADY_PAID]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteAlreadyPaid.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteAlreadyPaid.body',
  },
  // US Only Error: We add a fallback in AU to use the same textId but the text is different
  [ERROR_CODE.QUOTE_ELIGIBILITY_CHANGED]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteEligibilityChanged.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteEligibilityChanged.body',
  },
  [ERROR_CODE.QUOTE_EXPIRED]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteExpired.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteExpired.body',
  },
  [ERROR_CODE.QUOTE_INVALID_STATUS]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteExpired.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteExpired.body',
  },
  [ERROR_CODE.QUOTE_NOT_FOUND]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteNotFound.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteNotFound.body',
  },
  // both errors uses the same message
  [ERROR_CODE.QUOTE_START_DATE_PASSED]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteStartDatePassed.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteStartDatePassed.body',
  },
  [ERROR_CODE.QUOTE_END_DATE_PASSED]: {
    titleI18Text: 'errors.errorBoundary.errorText.quoteStartDatePassed.title',
    bodyI18Text: 'errors.errorBoundary.errorText.quoteStartDatePassed.body',
  },
  [ERROR_CODE.PAYMENT_IN_PROGRESS]: {
    titleI18Text: 'errors.errorBoundary.errorText.paymentInProgress.title',
    bodyI18Text: 'errors.errorBoundary.errorText.paymentInProgress.body',
  },
} as const;
