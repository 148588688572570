import {
  getRegionSelector,
  isAURegionSelector,
  isUSRegionSelector,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import {
  getPrimaryAndSecondaryTravellersListLabel,
  getSortedTravellersListLabel,
} from '@packages/utils';

import { getTripSelector, useTripStore } from '@store';

export function useGetTravellersList() {
  const trip = useTripStore(getTripSelector);
  const isAU = useRegionStore(isAURegionSelector);
  const isUS = useRegionStore(isUSRegionSelector);
  const region = useRegionStore(getRegionSelector);
  const defaultMaxDependantAge = useConfigStore(
    state => state?.regionSpecificConfig?.RULES.PRIMARY_TRAVELLER_AGE?.min,
  );

  return {
    getTravellersList() {
      if (!trip) {
        return ['', ''];
      }

      if (isUS) {
        return getPrimaryAndSecondaryTravellersListLabel({ trip, region });
      }

      if (isAU) {
        return getSortedTravellersListLabel({ trip, region, defaultMaxDependantAge });
      }
      // Invalid state
      return ['', ''];
    },
  };
}
