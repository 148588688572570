import { Boost, BoostUpsellType } from '@packages/types';

import { ModalComponentName } from '@components';

export const STEPS_CONFIG = {
  STEP_CIRCLE_WIDTH: '0.75rem', // width of circle
  SM_STEP_CIRCLE_WIDTH: '0.5rem', // width of circle
  INIT_STEP: 0, // initialize current step
};

export const FREELY_WEBSITE_URL = 'https://www.freely.me';

export enum FREELY_WEBSITE_ROUTES {
  GET_HELP = 'get-help',
}

export enum EMAIL_QUOTE_FLOW_TYPE {
  FOOTER = 'footer email a quote',
  ABANDON_CART = 'abandon cart popup',
}

export interface ModalBody {
  type: ModalComponentName;
  boost: Boost;
  title: string | null;
  description: string | null;
  boostUpsellType?: BoostUpsellType | null;
  onComplete?: () => void;
}
