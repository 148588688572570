export const notifications = {
  maxDestination: {
    title: 'destinationNote.maxDestination.title',
    description: 'destinationNote.maxDestination.description',
  },
  stoppingOver: {
    title: 'destinationNote.stoppingOver.title',
    description: 'destinationNote.stoppingOver.description',
  },
} as const;
