/**
 * concat and get the image path
 *
 * @param url
 * @param imagePath
 */
export const getImageUrl = (url: string, imagePath: string) => {
  if (!url || !imagePath) return;
  return url + imagePath;
};
