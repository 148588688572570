import zod from 'zod';

export const webEnvSchema = zod.object({
  VITE_ENV: zod.union([
    zod.literal('dev'),
    zod.literal('staging'),
    zod.literal('prod'),
    zod.literal('dev3'),
  ]),
  VITE_SENTRY_DSN_ENDPOINT: zod.string().nonempty(),
  VITE_STRIPE_PUBLISHABLE_KEY: zod.string().nonempty(),
  VITE_GOOGLE_MAPS_API_KEY: zod.string().nonempty(),
  VITE_SEGMENT_WRITE_KEY: zod.string().nonempty(),
  VITE_ZUSTAND_PERSIST_STATE: zod.string().nonempty(),
  VITE_ZUSTAND_LOGGER: zod.string().nonempty(),
  VITE_US_CARD_TOKEN_AUTH: zod.string().nonempty(),
  VITE_US_CARD_TOKEN_URL: zod.string().nonempty(),
  VITE_TRAVELX_APPLE_MERCHANT_ID: zod.string().nonempty(),
  VITE_TRAVELX_GOOGLE_PAY_PUBLIC_KEY: zod.string().nonempty(),
});

export const webEnv = webEnvSchema.parse((import.meta as any).env);
