import gql from 'graphql-tag';

import * as fragments from './fragments';

export const onChatSubscription = gql`
  subscription onChatSubscription($userId: String!) {
    customerIndividualSubscription(userId: $userId) {
      eventType
      ... on HyperMessageEvent {
        id
        hyperMessage {
          ...HyperMessageFields
        }
      }
    }
  }
  ${fragments.HyperMessageFields}
`;

export const onTypingEvent = gql`
  subscription onTypingEvent($userId: String!) {
    customerIndividualSubscription(userId: $userId) {
      eventType
      ... on TypingEvent {
        __typename
        id
        isTyping
        userId
        sender {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const customerIndividualSubscription = gql`
  subscription customerIndividualSubscription($userId: String!) {
    customerIndividualSubscription(userId: $userId) {
      userId
      eventType
      ... on COIUpdated {
        presignedCoiLink
        tripId
        id
      }
      ... on PolicyIssued {
        paymentStatus
        policyIssuance
        tripId
        id
      }
      ... on IndividualEvent {
        message
      }
    }
  }
`;

export const guestSubscription = gql`
  subscription guestSubscription($userId: String!) {
    guestSubscription(userId: $userId) {
      ... on COIUpdated {
        presignedCoiLink
        tripId
        eventType
        id
        userId
      }
      ... on PolicyIssued {
        paymentStatus
        policyIssuance
        policyNumber
        tripId
        eventType
        id
        userId
      }
    }
  }
`;
