import { getUserIpAddress as getUserIpAddressApi } from 'freely-shared-open-api';

export const getUserIpAddress = async () => {
  const ipAddress = await getUserIpAddressApi().catch(() => '');
  window.localStorage.setItem('ipAddress', ipAddress);
};

export const getLocalStorageIpAddress = () => {
  return window.localStorage.getItem('ipAddress') ?? '';
};
