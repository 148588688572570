import { useEffect, useRef } from 'react';

import { guestSubscription } from 'freely-shared-api';

import { INVALID_PROMO_CODE_DESCRIPTION, INVALID_PROMO_CODE_TITLE } from '@packages/constants';
import { useConfigStore } from '@packages/stores';
import {
  COIUpdatedEvent,
  GeneralEventType,
  IndivudualEvent,
  PolicyIssuedEvent,
  TRIP_STATUS,
} from '@packages/types';

import { ROUTE_PATHS, router } from '@routes/router';
import { captureException, withScope } from '@sentry/react';
import { useGuestStore, useTripStore } from '@store';
import { sentryTags } from '@utils';

type GuestSubscriptionPayload = {
  data: { guestSubscription: COIUpdatedEvent | PolicyIssuedEvent | IndivudualEvent };
};

/**
 * A hook that subscribes to guestSubscription only when the guestId is created.
 */
export function useGuestSubscription() {
  const updateTripPolicyStatus = useTripStore(state => state.updateTripPolicyStatus);
  const setTripError = useTripStore(state => state.setError);

  const userId = useGuestStore(state => state.guest?.userId);

  const subscription = useRef<any>(null);

  const isPenguinTimeoutEnabled =
    useConfigStore(state => state?.regionSpecificConfig?.RULES.PAYMENT_TIMEOUT_ENABLED) ?? true;

  const processPaymentTimeoutSecs =
    useConfigStore(
      state => state?.regionSpecificConfig?.RULES.PAYMENT_PROCESSING_TIMEOUT_SECONDS,
    ) ?? 15;

  const isNewPolicyIssuanceTagsEnabled =
    useConfigStore.getState().regionSpecificConfig?.FEATURE_TOGGLE?.newPolicyIssuanceTags?.enabled;

  useEffect(() => {
    withScope(() => {
      sentryTags({ event: 'effect.subscribe', source: 'useGuestSubscription' });
      function onNext({ data: { guestSubscription } }: GuestSubscriptionPayload) {
        const eventType = guestSubscription?.eventType;

        switch (eventType) {
          case GeneralEventType.POLICY_ISSUED:
            // Edge case: If the policy issuance is TIMEOUT before 15 secs, we should navigate to the confirmation page
            if (isPenguinTimeoutEnabled && guestSubscription?.policyIssuance === 'TIMEOUT') {
              updateTripPolicyStatus({
                status: TRIP_STATUS.WAITING_PAYMENT,
              });
              router.navigate(ROUTE_PATHS().confirmation);
            }
            if (
              (guestSubscription.policyIssuance === 'SUCCESS' && !isNewPolicyIssuanceTagsEnabled) ||
              (guestSubscription.policyIssuance !== 'FAILURE' &&
                guestSubscription.policyIssuance !== 'INVALID_CODE' &&
                isNewPolicyIssuanceTagsEnabled)
            ) {
              updateTripPolicyStatus({
                status: TRIP_STATUS.PAID,
                policyNumber: guestSubscription.policyNumber,
              });
            }

            if (guestSubscription?.policyIssuance === 'FAILURE') {
              updateTripPolicyStatus({ policyStatus: 'ERROR' });
            }

            if (guestSubscription?.policyIssuance === 'INVALID_CODE') {
              updateTripPolicyStatus({ policyStatus: 'ERROR' });
              setTripError(
                new Error(INVALID_PROMO_CODE_DESCRIPTION, {
                  cause: INVALID_PROMO_CODE_TITLE,
                }),
              );
            }
            break;
        }
      }

      function onError(data: any) {
        captureException(new Error(data?.error?.errors[0].message), {
          tags: { source: 'guestSubscription.onError' },
        });
      }

      try {
        if (isPenguinTimeoutEnabled) {
          // Set up the timer to unsubscribe after 15 seconds
          const timerId = setTimeout(
            () => {
              if (subscription.current) {
                subscription.current.unsubscribe();
              }
            },
            (processPaymentTimeoutSecs - 1) * 1000,
          );

          if (userId && !subscription?.current) {
            guestSubscription(onNext, onError)
              .then(sub => {
                subscription.current = sub;
                return;
              })
              .catch(err => {
                captureException(err, { tags: { source: 'guestSubscription' } });
              });
          }
          return () => {
            clearTimeout(timerId);
          };
        } else {
          if (userId && !subscription?.current) {
            guestSubscription(onNext, onError)
              .then(sub => {
                subscription.current = sub;
                return;
              })
              .catch(err => {
                captureException(err, { tags: { source: 'guestSubscription' } });
              });
          }
        }
      } catch (e) {
        captureException(e);
      }
    });
  }, [
    isPenguinTimeoutEnabled,
    processPaymentTimeoutSecs,
    updateTripPolicyStatus,
    userId,
    isNewPolicyIssuanceTagsEnabled,
    setTripError,
  ]);
}
