import { CLAIM_TYPE } from '@packages/types';

export const CLAIM_MAX_FILES_SIZE = 20000000; // 20 MB
export const CLAIM_PHOTO_QUALITY = 0.8;
export const CLAIM_PHOTO_MAX_WIDTH = 2048;
export const CLAIM_PHOTO_MAX_HEIGHT = 2048;
export const CLAIM_CONDITIONAL_APPROVAL_AMOUNT_LIMIT = 1000;
export const CLAIM_CONDITIONAL_APPROVAL_CURRENCIES = ['AUD'];
export const CLAIM_EVIDENCE_NOT_FOUND =
  'There is an error with this file, please try re-uploading it again.';

export const LEGACY_CLAIM_TYPE = {
  CANCELLATION: CLAIM_TYPE.MY_TRIP,
  FLIGHTS: CLAIM_TYPE.MY_TRIP,
  RENTAL: CLAIM_TYPE.MY_TRIP,
  LIABLE: CLAIM_TYPE.MY_TRIP,
  MEDICAL: CLAIM_TYPE.MY_HEALTH,
  DENTAL: CLAIM_TYPE.MY_HEALTH,
  LUGGAGE: CLAIM_TYPE.MY_STUFF,
  PASSPORT: CLAIM_TYPE.MY_STUFF,
  MONEY: CLAIM_TYPE.MY_STUFF,
  OTHER: CLAIM_TYPE.SOMETHING_ELSE,
};
