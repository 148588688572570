import { CreateStore, createStore } from '@packages/stores';
import { PAYMENT_TYPE } from '@packages/types';

export type CheckoutSectionType = 'travellerDetails' | 'legalStuff' | 'payment';

export type PaymentSectionType = 'card' | 'afterpay' | 'applepay' | 'googlepay';

interface CreditCardDetails {
  cardNumber: string;
  cardType?: PAYMENT_TYPE;
  paymentToken: string;
}

export interface CheckoutState {
  isManualAddressSectionOpen: boolean;
  openSection: CheckoutSectionType;
  paymentSectionType?: PaymentSectionType;
  isLegalConsentChecked: boolean;
  hasLegalConsentError?: boolean;
  isStripeFormComplete?: boolean;
  paymentErrorMessage: string;
  displaySummaryDetails?: boolean;
  emcErrors?: boolean;
  hasConfirmedAssessment: boolean;
  googleCreditCardDetails?: CreditCardDetails;
  isApplePayAvailable?: boolean;
}

export interface CheckoutActions {
  setIsManualAddressSectionOpen: (isOpen: boolean) => void;
  setOpenSection: (section: CheckoutSectionType) => void;
  setPaymentSectionType: (paymentSectionType: PaymentSectionType) => void;
  setIsLegalConsentChecked: (isLegalConsentChecked: boolean) => void;
  setHasLegalConsentError: (hasLegalConsentError: boolean) => void;
  setIsStripeFormComplete: (isStripeFormComplete: boolean) => void;
  setDisplaySummaryDetails: (displaySummaryDetails: boolean) => void;
  setValidateEmcProceedError: (emcErrors: boolean) => void;
  setHasConfirmedAssessment: (hasConfirmedAssessment: boolean) => void;
  setGoogleCreditCardDetails: (creditDetails: CreditCardDetails) => void;
  setIsApplePayAvailable: (isApplePayAvailable: boolean) => void;
  setPaymentErrorMessage: (paymentErrorMessage: string) => void;
}

export type CheckoutStore = CheckoutState & CheckoutActions;

const initialState: CheckoutState = {
  isManualAddressSectionOpen: false,
  openSection: 'travellerDetails',
  isLegalConsentChecked: false,
  hasLegalConsentError: false,
  isStripeFormComplete: false,
  displaySummaryDetails: false,
  emcErrors: undefined,
  hasConfirmedAssessment: true,
  googleCreditCardDetails: {
    cardNumber: '',
    cardType: undefined,
    paymentToken: '',
  },
  isApplePayAvailable: false,
  paymentErrorMessage: '',
};

export const checkoutStore: CreateStore<CheckoutStore> = set => ({
  ...initialState,
  setIsManualAddressSectionOpen(isOpen) {
    set(state => {
      state.isManualAddressSectionOpen = isOpen;
    });
  },
  setOpenSection(section) {
    set(state => {
      state.openSection = section;
    });
  },
  setPaymentSectionType(paymentSectionType) {
    set(state => {
      state.paymentSectionType = paymentSectionType;
    });
  },
  setIsLegalConsentChecked(isLegalConsentChecked) {
    set(state => {
      state.isLegalConsentChecked = isLegalConsentChecked;
    });
  },
  setHasLegalConsentError(hasLegalConsentError) {
    set(state => {
      state.hasLegalConsentError = hasLegalConsentError;
    });
  },
  setIsStripeFormComplete(isStripeFormComplete) {
    set(state => {
      state.isStripeFormComplete = isStripeFormComplete;
      state.paymentErrorMessage = '';
    });
  },
  setDisplaySummaryDetails(isSummaryDetailsDisplayed) {
    set(state => {
      state.displaySummaryDetails = isSummaryDetailsDisplayed;
    });
  },
  setValidateEmcProceedError(emcErrors) {
    set(state => {
      state.emcErrors = emcErrors;
    });
  },
  setGoogleCreditCardDetails: googleCreditCardDetails => {
    set(state => {
      state.googleCreditCardDetails = googleCreditCardDetails;
    });
  },
  setIsApplePayAvailable: isApplePayAvailable => {
    set(state => {
      state.isApplePayAvailable = isApplePayAvailable;
    });
  },
  setHasConfirmedAssessment: hasConfirmedAssessment => {
    set(state => {
      state.hasConfirmedAssessment = hasConfirmedAssessment;
    });
  },
  setPaymentErrorMessage: paymentErrorMessage => {
    set(state => {
      state.paymentErrorMessage = paymentErrorMessage;
    });
  },
});

export const useCheckoutStore = createStore(checkoutStore, {
  name: 'useCheckoutStore',
});
