import { CreateStore, createStore } from '@packages/stores';

export interface GlobalState {
  isCovidConsentAgreed: boolean;
  isReferralModalClosed: boolean;
  isExtraCancellationAddedByDefaultForCanstar: boolean;
}

export interface GlobalActions {
  setIsCovidConsentAgreed: (isCovidConsentAgreed: boolean) => void;
  setIsReferralModalClosed: (isReferralModalClosed: boolean) => void;
  setIsExtraCancellationAddedByDefaultForCanstar: (
    isExtraCancellationAddedByDefaultForCanstar: boolean,
  ) => void;
}

export type GlobalStore = GlobalState & GlobalActions;

const initialState: GlobalState = {
  isCovidConsentAgreed: false,
  isReferralModalClosed: false,
  isExtraCancellationAddedByDefaultForCanstar: false,
};

const globalStore: CreateStore<GlobalStore> = set => ({
  ...initialState,
  setIsCovidConsentAgreed(isCovidConsentAgreed: boolean) {
    set(state => {
      state.isCovidConsentAgreed = isCovidConsentAgreed;
    });
  },
  setIsReferralModalClosed(isReferralModalClosed: boolean) {
    set(state => {
      state.isReferralModalClosed = isReferralModalClosed;
    });
  },
  setIsExtraCancellationAddedByDefaultForCanstar(
    isExtraCancellationAddedByDefaultForCanstar: boolean,
  ) {
    set(state => {
      state.isExtraCancellationAddedByDefaultForCanstar =
        isExtraCancellationAddedByDefaultForCanstar;
    });
  },
});

export const useGlobalStore = createStore(globalStore, {
  name: 'useGlobalStore',
});
