import { FC, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';
import { CreditCardForm, GoogleEditPayment, Text } from '@components';
import { useApplePay, useGooglePay } from '@hooks';
import { PaymentSectionType, useCheckoutStore } from '@store';

interface PaymentSectionProps {
  type: PaymentSectionType;
}

const NativePayContent: FC<PaymentSectionProps> = ({ type }) => {
  const Icon = type === 'googlepay' ? Assets.GooglePay : Assets.ApplePay;
  const text = type === 'googlepay' ? 'Google Pay' : 'Apple Pay';
  return (
    <div className="p-4 border rounded-md w-full">
      <div className="flex flex-col space-y-2 border-b pb-2">
        <Icon height={32} width={50} />
        <Text className="pb-2" variant="subHeading-14/r">
          {text} selected for checkout.
        </Text>
      </div>
      <div className="flex items-center pt-4">
        <Assets.RedirectModal width={60} height={30} />
        <Text variant="subHeading-14/r" className="ml-2 text-gray-600">
          Another step will appear after submitting your order to complete your purchase details.
        </Text>
      </div>
    </div>
  );
};

const EmptyContent: FC<PaymentSectionProps> = () => {
  return null;
};

const CreditCardFormWrapper: FC<PaymentSectionProps> = () => {
  return <CreditCardForm />;
};

type ComponentMappingType = {
  [key in PaymentSectionType]: {
    icon: React.ReactNode;
    label: string;
    content: FC<PaymentSectionProps>;
  };
};

const ComponentMapping: ComponentMappingType = {
  afterpay: {
    icon: null,
    label: 'Afterpay',
    content: EmptyContent,
  },
  card: {
    icon: <Assets.CreditCard />,
    label: 'Card',
    content: CreditCardFormWrapper,
  },
  applepay: {
    icon: <Assets.ApplePay height={20} width={35} />,
    label: 'Apple Pay',
    content: NativePayContent,
  },
  googlepay: {
    icon: <Assets.GooglePay height={20} width={35} />,
    label: 'Google Pay',
    content: NativePayContent,
  },
};

interface TabProps {
  type: PaymentSectionType;
}

const TabButton: FC<TabProps> = ({ type }) => {
  const paymentSectionType = useCheckoutStore(state => state.paymentSectionType ?? 'card');
  const setPaymentSectionType = useCheckoutStore(state => state.setPaymentSectionType);
  const isActive = paymentSectionType === type;
  const Icon = ComponentMapping[type].icon;

  const onClick = () => setPaymentSectionType(type);
  return (
    <button
      className={twMerge(
        'py-2 px-4 w-full rounded-lg text-left border border-nevada-300 text-gray-600 bg-cabo-50',
        isActive && 'border-fuji-800 text-fuji-800 bg-mono-100 shadow-lg',
      )}
      onClick={onClick}>
      <div className="flex flex-col items-start space-y-1">
        <div className="flex flex-col items-start space-y-1">
          <div className="w-6 h-6">{Icon}</div>
          <Text variant="body-16/m">{ComponentMapping[type].label}</Text>
        </div>
      </div>
    </button>
  );
};

export const PaymentElement: FC = () => {
  const paymentSectionType = useCheckoutStore(state => state.paymentSectionType) ?? 'card';
  const { isGooglePayAvailable } = useGooglePay();
  const isApplePayAvailable = useCheckoutStore(state => state.isApplePayAvailable);
  const googleCreditCardDetails = useCheckoutStore(state => state.googleCreditCardDetails);
  const googlePaymentToken = googleCreditCardDetails?.paymentToken;

  const RenderComponent = ComponentMapping[paymentSectionType].content;

  const { initApplePay } = useApplePay();

  // Initializing Apple Pay only for US
  useEffect(() => {
    initApplePay();
  }, [initApplePay]);

  if (googlePaymentToken) {
    return <GoogleEditPayment />;
  }

  return (
    <div className="w-full mx-auto -mt-8">
      <div className="flex space-x-2">
        <TabButton type="card" />
        {isApplePayAvailable && <TabButton type="applepay" />}
        {isGooglePayAvailable && <TabButton type="googlepay" />}
      </div>
      <div className="w-full mt-2">
        <RenderComponent type={paymentSectionType} />
      </div>
    </div>
  );
};
