import { closeModalSelector, openModalSelector, useModalStore, useTripStore } from '@store';

export const useEditTripCostModal = () => {
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);
  const syncTrip = useTripStore(state => state.syncTrip);

  const onNext = async () => {
    await syncTrip();
    closeModal();
  };

  const openEditTripCostModal = () =>
    openModal('Modal', {
      body: {
        type: 'TripCostEditor',
        isModal: true,
        onNext: onNext,
        onBack: closeModal,
      },
      size: 'lg',
      sendExitEvent: true,
    });

  return { openEditTripCostModal };
};
