import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';

type AccordionItemProps = {
  titleChildren: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
};

export const AccordionItem = ({ titleChildren, children, className }: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div
        className={twMerge(
          'flex w-full border border-gray-200 p-4 hover:cursor-pointer hover:bg-cabo-50 transition-colors duration-200 ease-in-out',
          isOpen ? 'border-b-[1px]' : 'border-b-[0.5px]',
          className,
        )}
        role="menu-item"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
        onClick={handleClick}>
        <div className="flex items-center w-full">
          <div className="flex-grow overflow-hidden">{titleChildren}</div>
          {/* TODO: Consider how to make this super flexible for other icons */}
          <div>
            <Assets.ChevronDown
              fill="black"
              width="24px"
              height="24px"
              className={twMerge(
                'ml-auto transform transition-transform cursor-pointer',
                isOpen ? 'rotate-180' : 'rotate-0',
              )}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          'flex px-5 py-6 justify-center items-center bg-cabo-50 w-full',
          isOpen ? 'block' : 'hidden',
        )}>
        {children}
      </div>
    </div>
  );
};
