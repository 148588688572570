import { useRegionStore } from '@packages/stores';

import { PaymentElement as AU } from './au/paymentElement.componet';
import { PaymentElement as US } from './us/paymentElement.component';

export const PaymentElement = () => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent({ US, AU });

  return <Component />;
};
