import React from 'react';

import { FooterButtonGroup } from '@components';
import { testProps } from '@utils';

export interface Props {
  onBack: () => void;
  onNext: () => Promise<void> | void;
  isLoading: boolean;
  isNextDisabled: boolean;
}

export const DestinationsFooter: React.FC<Props> = ({
  isNextDisabled,
  isLoading,
  onBack,
  onNext,
}) => (
  <>
    <FooterButtonGroup
      nextButtonProps={{
        ...testProps('destination-next-button'),
        variant: 'primary',
        className: 'w-full',
        isLoading: isLoading,
        disabled: isNextDisabled,
        onClick: onNext,
        title: 'Next',
      }}
      backButtonProps={{
        ...testProps('destination-back-button'),
        variant: 'secondary',
        className: 'w-full',
        onClick: onBack,
        title: 'Back',
      }}
    />
  </>
);
