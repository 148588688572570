import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import { useEmcStore } from '@store';

import { CheckboxSVGIcon } from '../checkboxSVG';
import { Text } from '../text';

export type StepperBarProps = HTMLAttributes<HTMLDivElement>;

const Steps = {
  information: 'Information',
  'pre-assessment': 'Pre-assessment check',
  assessment: 'Medical Assessment',
  'screening-result': 'Quote',
};

const afterClasses =
  "after:content-[''] md:after:!w-[84%] xl:after:!w-[90%] after:rounded after:top-0 after:h-1 md:after:!left-8 after:absolute";
const beforeClasses =
  "before:content-[''] before:absolute before:rounded-full before:h-5 before:w-5 before:border before:border-nusa-200 before:top-[-8px] before:left-[0px] before:bg-mono-100 md:before:block";
const smallDeviceClasses = 'after:left-0 after:w-full before:hidden mr-2';

export const StepperBar = (props: StepperBarProps) => {
  const openEmcSection = useEmcStore(state => state.openEmcSection);

  const currentStep = openEmcSection ? Object.keys(Steps).indexOf(openEmcSection) + 1 : 1;
  if (currentStep > Object.values(Steps).length) {
    return null;
  }
  return (
    <div {...props} className={twMerge('flex ', props.className)}>
      {Object.values(Steps).map((step, index) => {
        const { bgColor, status, statusColor, circleColor } = getStepStatusAndStyle(
          currentStep,
          index,
        );
        return (
          <div key={step} className="w-1/4 relative">
            <div
              className={twMerge(
                afterClasses,
                beforeClasses,
                smallDeviceClasses,
                'relative md:mb-4',
                bgColor,
                Object.values(Steps).length - 1 === index ? 'md:after:bg-transparent' : '',
                circleColor,
              )}></div>
            <div className="hidden md:block">
              <CheckboxSVGIcon
                isChecked
                className={twMerge(
                  'h-5 w-5 absolute hidden left-[0px] -top-[8px]',
                  getStepStatusAndStyle(currentStep, index)?.status === 'Completed' ? 'block' : '',
                )}
                variant={{ shape: 'circle' }}
              />
              <Text variant="subHeading-14/m" className="text-fuji-800">
                {step}
              </Text>
              <Text variant="footnote-12/m" className={statusColor}>
                {status}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getStepStatusAndStyle = (step: number, index: number) => {
  const isCurrentStep = step === index + 1;
  const isPreviousStep = step > index + 1;
  const stepColors = {
    current: {
      bgColor: 'after:bg-nusa-100',
      status: 'In progress',
      statusColor: 'text-nusa-500',
      circleColor: 'before:bg-nusa-200',
    },
    previous: {
      bgColor: 'after:bg-nusa-200 md:after:bg-nusa-100',
      status: 'Completed',
      statusColor: 'text-green-500',
      circleColor: '',
    },
    next: {
      bgColor: 'after:bg-fuji-50 md:after:bg-nusa-100 ',
      status: 'Pending',
      statusColor: 'text-gray-700',
      circleColor: '',
    },
  };

  const key = isCurrentStep ? 'current' : isPreviousStep ? 'previous' : 'next';
  return stepColors[key];
};
