type DestinationNoteAUProps = {
  maxDestination: {
    title: string;
    description: string;
  };
  stoppingOver: {
    title: string;
    description: string;
  };
};

export const destinationNoteAU: DestinationNoteAUProps = {
  maxDestination: {
    title: 'We only support up to 10 destinations per policy.',
    description:
      'To add another, first remove a destination. You can also try selecting a region (e.g. All of Europe).',
  },
  stoppingOver: {
    title: 'Stopping over somewhere?',
    description: 'No need to enter or select a country if less than 72 hours.',
  },
};
