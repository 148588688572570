import { BOOST_CATEGORY } from '@packages/types';

import { useBoostDetailsStore, useFeatureFlagsStore, useGlobalStore, useTripStore } from '@store';

useTripStore.subscribe(
  state => state?.trip?.boosts?.find?.(it => it.category === BOOST_CATEGORY.MY_HEALTH)?.boostId,
  async (currentBoostId, previousBoostId) => {
    const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
    if (!currentBoostId || currentBoostId === previousBoostId) {
      return;
    }
    await getBoostDetails(currentBoostId);
  },
);

useTripStore.subscribe(
  state => state?.trip?.boosts?.find?.(it => it.code === 'CANX'),
  async newBoost => {
    const setIsExtraCancellationAddedByDefaultForCanstar =
      useGlobalStore.getState().setIsExtraCancellationAddedByDefaultForCanstar;
    const canstarExtraCancellationPayload =
      useFeatureFlagsStore.getState().featureFlags?.[
        'extra-cancellation-added-by-default-for-canstar-tooltip'
      ]?.payload;
    if (
      newBoost?.isAdded &&
      newBoost?.boostProperty?.selectedExtraCover !==
        canstarExtraCancellationPayload?.canstarCanxAmount
    ) {
      setIsExtraCancellationAddedByDefaultForCanstar(false);
    }
  },
);
