import { twMerge } from 'tailwind-merge';
import { type VariantProps, tv } from 'tailwind-variants';

import { Assets } from '@assets';

export type IconProps = React.SVGProps<SVGElement> & {
  type: 'Add' | 'Close' | 'CheckCircle' | 'ArrowRight';
  className?: string;
  iconClassName?: string;
} & VariantProps<typeof container>;

const container = tv({
  base: 'h-10 w-10',
  variants: {
    rounded: {
      true: 'rounded-full',
    },
    fill: {
      undefined: 'bg-none',
      default: 'bg-fuji-300',
      mint: 'bg-nusa-200',
      cherry: 'bg-red-200',
      snow: 'bg-cabo-50',
      charcoal: 'bg-fuji-800',
    },
  },
});

const icon = tv({ base: 'h-full w-full fill-fuji-800 p-2' });

export const Icon = ({ type, rounded, fill, className, iconClassName, ...rest }: IconProps) => {
  const containerStyle = twMerge(container({ className, rounded, fill }));
  const iconStyle = twMerge(icon({ className: iconClassName }));

  const Component = Assets[type];

  return (
    <div className={containerStyle}>
      <Component className={iconStyle} {...rest} />
    </div>
  );
};
