import { QuoteDestinationsResponse, TripDestination } from '@packages/types';

import { CreateStore, createStore } from '../utils/utils';
import { fetchQuoteDestinations } from './destinations.api';

export interface DestinationsState {
  destinations: QuoteDestinationsResponse['destinations'] | undefined;
  trendingDestinations?: TripDestination[];
  userSearchedDestinations: TripDestination[];
  error: Error | undefined;
  pending: boolean;
}

export interface DestinationsActions {
  resetDestinationsState: () => void;
  setDestinations: (destinations?: QuoteDestinationsResponse['destinations']) => void;
  setTrendingDestinations: (destinations?: TripDestination[]) => void;
  setPending: (pending: boolean) => void;
  setError: (error: Error) => void;
  getDestinations: () => Promise<DestinationsState['destinations'] | undefined>;
  toggleUserSearchedDestination: (item: TripDestination) => void;
  setUserSearchedDestinations: (destinations: TripDestination[]) => void;
  getQuoteDestinations: () => Promise<QuoteDestinationsResponse['destinations'] | undefined>;
}

export type DestinationsStore = DestinationsState & DestinationsActions;

const initialState: DestinationsState = {
  pending: false,
  error: undefined,
  destinations: undefined,
  userSearchedDestinations: [],
  trendingDestinations: undefined,
};

const destinationStore: CreateStore<DestinationsStore> = (set, get) => ({
  ...initialState,
  setDestinations: (destinations?: QuoteDestinationsResponse['destinations']) => {
    set(state => {
      state.destinations = destinations;
    });
  },
  setTrendingDestinations: (trendingDestinations?: TripDestination[]) => {
    set(state => {
      state.trendingDestinations = trendingDestinations;
    });
  },
  getDestinations: async () => await fetchQuoteDestinations(get),
  getQuoteDestinations: async () => await fetchQuoteDestinations(get),
  resetDestinationsState: () => set(initialState, false),
  toggleUserSearchedDestination: (destination: TripDestination) => {
    set(state => {
      state.userSearchedDestinations = state.userSearchedDestinations.find(
        item => item.longName === destination.longName,
      )
        ? state.userSearchedDestinations.filter(item => item.longName !== destination.longName)
        : [...state.userSearchedDestinations, destination];
    });
  },
  setUserSearchedDestinations: (destinations: TripDestination[]) => {
    set(state => {
      state.userSearchedDestinations = destinations;
    });
  },
  setPending: (pending: boolean) => {
    set(state => {
      state.pending = pending;
    });
  },
  setError: (error: Error) => {
    set(state => {
      state.error = error;
    });
  },
});

export const useDestinationsStore = createStore(destinationStore, {
  name: 'useDestinationsStore',
});
