import React, { ReactNode } from 'react';

import { captureException } from '@sentry/react';

import { DefaultFallback } from './defaultFallback.component';

class ErrorBoundary extends React.Component<{ children?: ReactNode; fallback?: ReactNode }, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    if (import.meta.env.VITE_ENV !== 'prod') {
      console.log('ErrorBoundary: getDerivedStateFromError');
      console.error(error);
    }
    // Update state so the next render will show the fallback UI.
    captureException(error, { tags: { source: 'errorBoundary' } });
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this?.props?.fallback ?? <DefaultFallback />;
    }

    return this?.props?.children;
  }
}

export default ErrorBoundary;
