import { useCallback } from 'react';

import { ERROR_CODE } from '@packages/constants';

import { ErrorScreen } from '@components/errorScreen';
import { useErrorText } from '@hooks';
import { navigateToFirstRegionView } from '@routes/router';

export const DefaultFallback = () => {
  const handleGoHomepage = useCallback(() => {
    window.location.replace('/');
  }, []);

  const { getErrorScreenProps } = useErrorText({
    quoteId: '123',
    onGetNewQuote: navigateToFirstRegionView,
    onGoHomepage: handleGoHomepage,
  });

  return (
    <ErrorScreen
      {...getErrorScreenProps({ name: ERROR_CODE.INTERNAL_ERROR } as unknown as Error)}
    />
  );
};
