import { useEffect } from 'react';

import { useRegionStore } from '@packages/stores';

import { EditTravellers } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';
import { useTripStore } from '@store';
import { sendAnalyticsEvent, sendAnalyticsPage } from '@utils';

export const Travellers = () => {
  const syncTrip = useTripStore(state => state.syncTrip);
  const country = useRegionStore(state => state?.region?.country);
  const onNext = async () => {
    if (country === 'US') {
      router.navigate(ROUTE_PATHS().tripCost);
      return;
    }

    sendAnalyticsEvent('Quote Submitted');

    const newTrip = await syncTrip();
    if (!newTrip) {
      return;
    }
    router.navigate(ROUTE_PATHS().myQuote);
  };

  useEffect(() => {
    sendAnalyticsPage('Primary Traveller Page Viewed');
  }, []);

  return <EditTravellers onNext={onNext} onBack={() => router.navigate(-1)} />;
};
