import React, { useCallback } from 'react';

import { Text } from '@components';
import { Button } from '@elements/button';
import { useEmailQuote } from '@hooks';
import { i18n } from '@i18n';
import { useQuoteStore } from '@store';
import { EMAIL_QUOTE_FLOW_TYPE } from '@types';
import { sendAnalyticsEvent, testProps } from '@utils';

export interface EmailQuoteButtonProps {
  type: 'button' | 'link';
}

export const EmailQuoteButton: React.FC<EmailQuoteButtonProps> = ({ type }) => {
  const { isEmailQuoteVisible, openEmailQuoteModal } = useEmailQuote();
  const setDidEmailQuoteShow = useQuoteStore(state => state.setDidEmailQuoteShow);

  const handleEmailQuote = useCallback(() => {
    openEmailQuoteModal();
    setDidEmailQuoteShow(true);
    sendAnalyticsEvent('Email a Quote Form Opened', { flow: EMAIL_QUOTE_FLOW_TYPE.FOOTER });
  }, [openEmailQuoteModal, setDidEmailQuoteShow]);

  if (type === 'link' && isEmailQuoteVisible) {
    return (
      <a
        {...testProps('email_quote_button')}
        className="text-mono-100 cursor-pointer block sm:inline"
        onClick={handleEmailQuote}>
        {' '}
        |{' '}
        <Text variant="footnote-12/r" className="inline">
          {i18n.t('checkout.text_one_pager_email_quote_btn')}
        </Text>
      </a>
    );
  }
  if (type === 'button') {
    return (
      <Button
        {...testProps('email_quote_button')}
        variant="primary"
        className="w-full"
        onClick={handleEmailQuote}
        title={i18n.t('checkout.text_one_pager_email_quote_btn')}
      />
    );
  }
  return null;
};
