import { useEffect, useLayoutEffect, useRef } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { isUSRegionSelector, useConfigStore, useRegionStore } from '@packages/stores';
import { createSortKeyByUUID, wait } from '@packages/utils';

import { QuoteFlowContainer, TripDetails, useUsCovidConsentModal } from '@components';
import { useCruiseBoostPopUp } from '@hooks';
import { setTag } from '@sentry/react';
import { useGlobalStore, useModalStore } from '@store';
import '@store/trip/trip.subscriptions';
import { sendAnalyticsMyQuotePageView } from '@utils';

import { AdditionalInformation } from './additionalInformation/additionalInformation.component';
import { Benefits } from './benefits/benefits.component';
import { DailyCoverSection } from './dailyCover/dailyCoverSection.component';
import { ExtraCoverSection } from './extraCover/extraCoverSection.component';

const closeSingleModal = useModalStore.getState().closeSingleModal;
export const MyQuote = () => {
  const isUS = useRegionStore(isUSRegionSelector);
  const isFirstLoad = useRef(true);
  const QUOTE_IMAGE_TAG = useConfigStore(
    state => state.regionSpecificConfig?.MARKETING_IMAGE_TAGS?.quote,
  );
  const isCovidModalOpened = useModalStore(state =>
    state.currentOpenModals.some(modal => modal === 'CovidConsent'),
  );
  const { openCovidConsentModal } = useUsCovidConsentModal();

  const isCovidConsentAgreed = useGlobalStore(state => state.isCovidConsentAgreed);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && isCovidModalOpened,
  );
  useCruiseBoostPopUp();
  useEffect(() => {
    setTag('section', 'boostSelection');
    sendAnalyticsMyQuotePageView();
  }, []);

  useLayoutEffect(() => {
    if (!isUS) {
      return;
    }

    if (!isCovidConsentAgreed && isFirstLoad.current) {
      isFirstLoad.current = false;
      return openCovidConsentModal();
    }

    const onBackPress = async () => {
      if (blocker.state === 'blocked' && !isFirstLoad.current) {
        closeSingleModal('UsCovidConsent');
        await wait(1);
        blocker.proceed();
      }
    };
    onBackPress();
  }, [blocker, isCovidConsentAgreed, openCovidConsentModal, isUS]);

  return (
    <>
      {QUOTE_IMAGE_TAG && (
        <img
          src={QUOTE_IMAGE_TAG?.replace('[OrderID]', createSortKeyByUUID())}
          width="1"
          height="1"
          alt=""
          className="absolute -top-96 -left-96"
        />
      )}
      <QuoteFlowContainer>
        <Benefits />
        <ExtraCoverSection />
        <DailyCoverSection />
        <TripDetails className="relative pt-4 mt-6 border-t lg:hidden" />
        <AdditionalInformation />
      </QuoteFlowContainer>
    </>
  );
};
