import { gql } from 'graphql-tag';

import * as fragments from './fragments';

export const queryHyperMessage = gql`
  query QueryHyperMessage($input: HyperMessageInput!) {
    queryHyperMessage(input: $input) {
      hyperMessages {
        ...HyperMessageFields
      }
      exclusiveStartKey {
        chatId
        createdAt
        messageId
      }
    }
  }
  ${fragments.HyperMessageFields}
`;
