import { ButtonHTMLAttributes, forwardRef, LegacyRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { Text } from '@components';

import { Icon, IconName } from '../icon';
import { Loader } from '../loader';
import { buttonVariants } from './buttonVariants';

export type ButtonVariant = 'feature' | 'primary' | 'secondary' | 'tertiary' | 'destructive';
export type ButtonSize = 'sm' | 'md';

export type ButtonProps = {
  variant: ButtonVariant;
  size?: ButtonSize;
  iconLeft?: IconName;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  iconRight?: IconName;
  icon?: IconName;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

const buttonLoaderVariants = tv({
  base: 'absolute text-icon-disabled',
  variants: {
    size: {
      sm: 'h-s16 left-[calc(50%-0.5rem)]',
      md: 'h-s20 left-[calc(50%-0.625rem)]',
    },
  },
});

export const Button = forwardRef((props: ButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
  const {
    variant = 'primary',
    className,
    iconLeft,
    iconRight,
    disabled,
    isLoading,
    icon,
    title,
    type = 'button',
    size = 'md',
    ...rest
  } = props;

  const loadingClassNames = twMerge(isLoading && 'text-transparent');
  const hasIcon = icon || iconLeft || iconRight;

  const customIconStyle = hasIcon && loadingClassNames;

  const CenteredIcon = icon && <Icon size={size} className={customIconStyle} name={icon} />;

  const IconAndText = !icon && (
    <>
      <Icon size={size} className={customIconStyle} name={iconLeft} />
      <Text className={loadingClassNames} variant={'body-16/sb'}>
        {title}
      </Text>
      <Icon size={size} className={customIconStyle} name={iconRight} />
    </>
  );

  const ButtonLoader = isLoading && <Loader className={buttonLoaderVariants({ size })} />;

  return (
    <button
      disabled={disabled || isLoading}
      className={buttonVariants({ variant, size: icon ? `icon-${size}` : size, className })}
      type={type}
      ref={ref}
      {...rest}>
      {CenteredIcon}
      {IconAndText}
      {ButtonLoader}
    </button>
  );
});

Button.displayName = 'Button';
