import { shallow } from 'zustand/shallow';

import { notifications } from '@packages/constants';
import { TripDestination } from '@packages/types';

import { Notification, NotificationVariant } from '@elements/notification';
import { i18n } from '@i18n';
import { useFeatureFlagsStore } from '@store';
import { sluggify, testProps } from '@utils';
import { Selector } from '@elements/selector';

export interface SelectedDestinationProps {
  selectedDestinations: TripDestination[];
  onRemoveSelectedDestination: (destination: TripDestination) => void;
  hasReachedMaxDestinations: boolean;
}

export const SelectedDestination: React.FC<SelectedDestinationProps> = ({
  selectedDestinations,
  onRemoveSelectedDestination,
  hasReachedMaxDestinations,
}) => {
  const { title, body, variant } = useGetDestinationNotificationProps({
    hasReachedMaxDestinations,
    selectedDestinations,
  });

  if (selectedDestinations.length === 0) return null;

  return (
    <div className="my-4 border-b-2 pb-8">
      <ul role="list" className="flex flex-wrap gap-s12">
        {selectedDestinations.map(destination => (
          <li key={`${destination.countryCode}-${destination.longName}`}>
            <Selector
              title={destination.countryName ?? ''}
              selected
              multiple
              {...testProps(
                `selected-country-destination-button-${sluggify(destination.longName)}`,
              )}
              onClick={() => onRemoveSelectedDestination(destination)}
            />
          </li>
        ))}
      </ul>
      <Notification variant={variant} header={title} body={body} className="mt-s16" />
    </div>
  );
};

function useGetDestinationNotificationProps({
  selectedDestinations,
  hasReachedMaxDestinations,
}: Omit<SelectedDestinationProps, 'onRemoveSelectedDestination'>) {
  const [isFeatureEnabled, featurePayload] = useFeatureFlagsStore(
    state => [
      !!state?.featureFlags?.['cruise-cover-notification']?.isEnabled,
      state?.featureFlags?.['cruise-cover-notification']?.payload,
    ],
    shallow,
  );

  const notificationType = hasReachedMaxDestinations ? 'maxDestination' : 'stoppingOver';
  const props: {
    title: string;
    body: string;
    variant: NotificationVariant;
  } = {
    title: i18n.t(notifications[notificationType].title),
    body: i18n.t(notifications[notificationType].description),
    variant: hasReachedMaxDestinations ? 'warning' : 'info',
  };
  if (!isFeatureEnabled || !featurePayload) {
    return props;
  }

  const hasCruiseNotification = selectedDestinations.some(it =>
    featurePayload?.cruiseCountryCodes.includes(it.countryCode),
  );

  if (hasCruiseNotification && !hasReachedMaxDestinations) {
    props.title = featurePayload.title;
    props.body = featurePayload.subtitle;
    props.variant = 'warning';
    return props;
  }

  return props;
}
