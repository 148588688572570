import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { fetchQuoteById } from 'freely-shared-api';

import { useRegionStore } from '@packages/stores';
import { PrimaryTraveller, Region } from '@packages/types';

import { ErrorScreen, ErrorScreenProps, Loader, Text } from '@components';
import { useErrorText } from '@hooks';
import { ROUTES, ROUTE_PATHS, navigateToFirstRegionView, router } from '@routes/router';
import { captureException, withScope } from '@sentry/react';
import { checkAndEnableCanstarExtraCancellationAddedByDefaultState, useTripStore } from '@store';

const US_DEFAULT_SCREENS = [
  'state-of-residence',
  'trip-destinations',
  'trip-dates',
  'trip-travellers',
  'trip-cost',
  'trip-deposit-date',
] as const;
const AU_DEFAULT_SCREENS = ['trip-destinations', 'trip-dates', 'trip-travellers'] as const;
function getPrevScreens(region?: Region) {
  if (region?.country === 'US') {
    return US_DEFAULT_SCREENS;
  }
  return AU_DEFAULT_SCREENS;
}
export const LoadQuote = () => {
  const getTrip = useTripStore(state => state.getTrip);
  const setTrip = useTripStore(state => state.setTrip);

  const region = useRegionStore(state => state?.region);
  const setRegion = useRegionStore(state => state.setRegion);
  const [searchParams] = useSearchParams();
  const quoteId = searchParams.get('quoteId');
  const [error, setError] = useState<ErrorScreenProps | null>(null);
  const prevScreens = useRegionStore(state => getPrevScreens(state.region), shallow);

  const handleGoHomepage = useCallback(() => {
    router.navigate('/');
  }, []);

  const { getErrorScreenProps } = useErrorText({
    quoteId,
    onGetNewQuote: navigateToFirstRegionView,
    onGoHomepage: handleGoHomepage,
  });

  const handleDone = useCallback(() => {
    if (!region?.country) {
      return;
    }

    /**
     * populate browser history with routes to go back to
     */
    for (const prevScreen of prevScreens) {
      router.navigate(`/${ROUTES.QUOTE}/${region.country.toLowerCase()}/${prevScreen}`);
    }

    router.navigate(ROUTE_PATHS().myQuote);
  }, [prevScreens, region?.country]);

  const showError = useCallback(
    (err?: Error) => {
      setError(getErrorScreenProps(err));
    },
    [getErrorScreenProps],
  );

  /** use a ref as a singleton for the status of initialised */
  const initialised = useRef(false);

  /**
   * fetch quote by id on mount
   */
  useEffect(() => {
    withScope(async scope => {
      if (!quoteId) {
        router.navigate('/');
        return;
      }
      scope.setTag('quoteId', quoteId);

      if (!region?.country || initialised?.current) {
        return;
      }

      initialised.current = true;
      setError(null);

      fetchQuoteById({ quoteId, region, isAuthenticated: false })
        .then(data => {
          if (data.userState) {
            setRegion({ ...region, state: data.userState });
          }
          return;
        })
        .then(getTrip)
        .then(trip => {
          // set primary traveller as resident when fetching from quote because it's not in BE

          checkAndEnableCanstarExtraCancellationAddedByDefaultState(trip);

          if (trip) {
            setTrip({
              ...trip,
              primaryTraveller: {
                ...trip.primaryTraveller,
                isResident: true,
              } as PrimaryTraveller,
            });
          }
          return;
        })
        .then(async () => {
          const trip = await getTrip();
          const cruiseBoost = trip?.boosts?.find(it => it?.code === 'CRSF');
          const isCruiseRequired = !!cruiseBoost?.tags?.includes('REQUIRED');
          const isCruiseAdded = !!cruiseBoost?.isAdded;
          const hasSeenCruiseBoostPopUp = useTripStore.getState().cruiseBoostModalState === 'SEEN';
          if (!hasSeenCruiseBoostPopUp && isCruiseRequired && !isCruiseAdded) {
            useTripStore.getState().setCruiseBoostModalState('DISPLAY');
          }
          return trip;
        })
        .then(handleDone)
        .catch(err => {
          showError(err);
          captureException(err);
        });
    });
  }, [getTrip, handleDone, prevScreens.length, quoteId, region, setRegion, setTrip, showError]);

  return error ? (
    <ErrorScreen {...error} />
  ) : (
    <div className="flex h-screen w-screen items-center justify-center gap-4">
      <Loader />
      <Text variant="h2-36/sb">Loading...</Text>
    </div>
  );
};
