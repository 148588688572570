import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { colors } from '@packages/design';

import { textTypography } from '@components';
import { sendAnalyticsEvent } from '@utils';

type ReferralInterface = {
  firstName?: string;
  lastName?: string;
  isModalOpen: boolean;
};

export const Referral = ({ firstName, lastName, isModalOpen }: ReferralInterface) => {
  const subtitleText = textTypography({ variant: 'subTitle-20', weight: 'r' });
  const bodyText = textTypography({ variant: 'body-16', weight: 'r' });
  const charcoal = colors.fuji[800] ?? '#131E29';
  const black = colors.fuji[800] ?? '#000';
  const white = colors.mono[100] ?? '#fff';

  const buttonStyle = {
    bodyText,
    'border-radius': '32px',
    'font-size': '16px',
    'font-weight': '400',
  };

  const titleText = {
    color: charcoal,
    subtitleText,
    'font-size': '20px',
  };

  const iconStyle = {
    'border-radius': '10px',
  };

  const blackIconStyle = {
    ...iconStyle,
    'background-color': black,
    color: white,
  };

  const iconSharePadding = {
    'margin-bottom': '14px',
  };

  function stringifyAndReplace<T extends object>(data: T) {
    return JSON.stringify(data).replace(/"/g, "'");
  }

  const titleTextStringfy = stringifyAndReplace(titleText);
  const buttonStyleStringfy = stringifyAndReplace(buttonStyle);
  const iconStyleStringfy = stringifyAndReplace(iconStyle);
  const blackIconStringfy = stringifyAndReplace(blackIconStyle);
  const iconShareStringfy = stringifyAndReplace(iconSharePadding);

  // If the user clicks the copy link button, send an analytics event
  useEffect(() => {
    const handleCopyLinkClick = () => {
      sendAnalyticsEvent('Copy link button click', {
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      });
    };
    const copyLinkButton = document
      .getElementById('grsfBlockForm')
      ?.querySelector('.grsf-copy-link-button');

    copyLinkButton?.addEventListener('click', handleCopyLinkClick);

    return () => {
      copyLinkButton?.removeEventListener('click', handleCopyLinkClick);
    };
  }, [firstName, lastName, isModalOpen]);

  // If the user clicks the email button, send an analytics event
  useEffect(() => {
    const handleEmailClick = () => {
      sendAnalyticsEvent('Send invites button click', {
        firstName: firstName ?? '',
        lastName: lastName ?? '',
      });
    };

    const emailButton = document
      .getElementById('grsfBlockInvite')
      ?.querySelector('.grsf-send-invites-inline-button');

    emailButton?.addEventListener('click', handleEmailClick);

    return () => {
      emailButton?.removeEventListener('click', handleEmailClick);
    };
  }, [firstName, lastName, isModalOpen]);

  return (
    <div className={twMerge('bg-cabo-50 rounded-3xl w-full p-6', isModalOpen ? 'block' : 'hidden')}>
      <div
        id="grsfBlockForm"
        data-grsf-block-form
        data-grsf-share-instructions-style={titleTextStringfy}
        data-grsf-button-style={buttonStyleStringfy}
        data-grsf-copy-link-container-style={iconShareStringfy}
        data-grsf-email-button-style={blackIconStringfy}
        data-grsf-sms-button-style={blackIconStringfy}
        data-grsf-facebook-button-style={iconStyleStringfy}
        data-grsf-twitter-button-style={iconStyleStringfy}
        data-grsf-linkedin-button-style={iconStyleStringfy}
        data-grsf-whatsapp-button-style={iconStyleStringfy}
        data-grsf-messenger-button-style={iconStyleStringfy}
      />
      <div
        id="grsfBlockInvite"
        data-grsf-block-invite
        data-grsf-instructions-style={titleTextStringfy}
        data-grsf-button-style={buttonStyleStringfy}
      />
    </div>
  );
};
