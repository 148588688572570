import { useFeatureFlagsStore } from '@store';

import { useMotorcycleUpsell } from './useMotorcycleUpsell';

export const useBoostUpsellFlow = () => {
  const hasMotorcycleBoostUpsellFeatureEnabled = useFeatureFlagsStore(
    state => state?.featureFlags?.['motorcycle-boost-upsell']?.isEnabled,
  );
  const motorcycleUpsell = useMotorcycleUpsell();
  const motorcycleUpsellFlow = (onComplete: () => void) => {
    /* check motorcycle feature toggle */
    if (!hasMotorcycleBoostUpsellFeatureEnabled) {
      return onComplete();
    }
    /* check if motorcycle upsell is eligible to popup*/
    if (motorcycleUpsell?.shouldOpenModal) {
      motorcycleUpsell?.openModal(onComplete);
      return;
    }

    return onComplete();
  };

  const boostUpsellFlow = (onComplete: () => void) => {
    return motorcycleUpsellFlow(onComplete);
  };

  return { boostUpsellFlow };
};
