export const emcSharedPreAssessmentAU = {
  checkbox: {
    label: 'I confirm I have read and understood Freely’s Privacy notice and I understand:',
    error: `Please check the box to confirm that you've read and agree to all the statements above.`,
  },
  button: 'Continue to Assessment',
  summary: `The health information requested in this online assessment is required to assess and price my travel insurance cover. I consent to its collection; in scenarios where I am providing information about the other individuals included on this policy, Freely relies on me to obtain their consent to provide the information, to inform the individuals of the personal information I have provided, and to provide the contents of the Freely Privacy Notice; and the medical information I provide in this assessment will be sent to the email address provided at the time the policy is purchased if I opt to include the existing medical condition cover in the policy.`,
  screeningQuestions: [
    'Has a doctor advised any travellers on this policy not to travel?',
    'Are any travellers on this policy travelling to seek advice, treatment or review, or to participate in a clinical trial?',
    'Are any travellers in this policy travelling to seek or receive (either routine or cosmetic) medical or dental treatment surgery?',
    'Are any travellers on this policy experiencing any signs or symptoms where a medical diagnosis has not been sought? (Examples: chest pain, shortness of breath, a persistent cough, or unexplained bleeding.)',
  ],
  screeningQuestionsErrors: {
    hasSelectedYes: {
      title:
        'Based on your response(s), we are unable to provide you with cover purchased via Freely.me.',
      description:
        'Please contact us if you wish to discuss this outcome and possible cover options. ',
    },
    hasNotConfirmedPrivacyNotice:
      "Please check the box to confirm that you've read and agree to all the statements above.",
  },
};
