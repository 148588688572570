import type { MaintenanceWindow } from '@packages/types';

export const isUnderMaintenance = (window: MaintenanceWindow) => {
  const currentTs = Date.now();
  if (Array.isArray(window?.irregular)) {
    for (const i of window.irregular) {
      if (currentTs >= new Date(i.start).getTime() && currentTs <= new Date(i.end).getTime()) {
        return true;
      }
    }
  }

  if (
    !window?.regular ||
    !window?.regular?.baseTimestamp ||
    !window?.regular?.interval ||
    !window?.regular?.duration
  ) {
    return false;
  }

  return (
    currentTs > window.regular.baseTimestamp &&
    isCurrentTimeWithinWindow({
      baseTimeStamp: window.regular.baseTimestamp,
      windowSize: window.regular.duration,
      interval: window.regular.interval,
    })
  );
};

export function isCurrentTimeWithinWindow({
  baseTimeStamp,
  windowSize,
  interval,
}: {
  baseTimeStamp: number;
  windowSize: number;
  interval: number;
}): boolean {
  const currentTimeStamp = Date.now(); // current time in milliseconds
  const timeDifference = currentTimeStamp - baseTimeStamp;
  const intervalsPassed = Math.floor(timeDifference / interval);
  const windowStart = baseTimeStamp + intervalsPassed * interval;
  const windowEnd = windowStart + windowSize;
  return currentTimeStamp >= windowStart && currentTimeStamp <= windowEnd;
}
