import { tv } from 'tailwind-variants';

export const buttonVariants = tv({
  base: 'relative flex gap-s4 items-center duration-300 transition-all rounded-full focus:ring-0 disabled:text-disabled disabled:bg-surface-disabled justify-center',
  variants: {
    variant: {
      feature:
        'text-action bg-surface-action-feature-default hover:bg-surface-action-feature-hover active:bg-surface-action-feature-active',
      primary:
        'text-action-dark-surface hover:text-action active:text-action bg-surface-action-primary-default hover:bg-surface-action-primary-hover active:bg-surface-action-primary-active',
      secondary:
        'text-action ring-2 ring-border-action-default hover:ring-border-action-hover active:ring-border-action-active focus:ring-2 hover:focus:ring-0 hover:ring-0 focus:ring-border-action-default  ring-inset  bg-transparent hover:bg-surface-action-secondary-hover active:bg-surface-action-secondary-active disabled:bg-transparent disabled:ring-border-disable',
      tertiary:
        'bg-transparent hover:bg-surface-action-tertiary-hover active:bg-surface-action-tertiary-active text-link-default disabled:bg-transparent ',
      destructive:
        'text-error bg-surface-action-destructive-default hover:bg-surface-action-destructive-hover active:bg-surface-action-destructive-active',
    },
    size: {
      sm: 'px-s12 py-s4',
      md: 'px-s24 py-s12',
      'icon-sm': 'p-s4',
      'icon-md': 'p-s12',
    },
  },
});
