import currency from 'currency.js';
import { FC } from 'react';

import { i18n } from '@i18n';
import { useTripStore } from '@store';

import { ListItem, ListItemProps } from '../../listItem';

export type Props = Pick<ListItemProps, 'disabled' | 'onClick' | 'variant'>;

export const TripCostListItem: FC<Props> = ({ disabled, onClick, variant }) => {
  const totalTripCost = useTripStore(state => state.trip?.totalTripCost);
  const tripCostLabel = i18n.t('myQuote.tripDetails.tripCostListItem');

  if (!totalTripCost) {
    return null;
  }

  const formattedTotalPrice = currency(totalTripCost, {
    symbol: '$',
    precision: 0,
    separator: ',',
  }).format();

  return (
    <ListItem
      variant={variant ?? 'edit'}
      titleTextProps={{ children: tripCostLabel }}
      subtitle={formattedTotalPrice}
      className="my-3"
      onClick={onClick}
      disabled={disabled}
    />
  );
};
