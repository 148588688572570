import { fetchUnauthBoostDetailsById } from 'freely-shared-api';

import { GetStore, getRegionState } from '@packages/stores';

import { captureException, withScope } from '@sentry/react';
import { sentryTags } from '@utils';

import { BoostDetailsStore } from './boost.store';

export const fetchBoostDetailsById = async (get: GetStore<BoostDetailsStore>, boostId: string) => {
  return await withScope(async scope => {
    scope.setTag('boostId', boostId);
    sentryTags({ event: 'fetch.boost', source: 'fetchBoostDetailsById' });
    const { setBoostDetails, setError, setPending } = get();
    const region = getRegionState();
    if (!region) {
      return;
    }
    try {
      setPending(true);
      const boostDetails = await fetchUnauthBoostDetailsById({
        boostId,
        region,
      });

      setBoostDetails(boostDetails);
      return boostDetails;
    } catch (error) {
      captureException(error as Error);
      setError(error as Error);
    } finally {
      setPending(false);
    }
  });
};
