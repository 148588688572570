import React from 'react';
import { twMerge } from 'tailwind-merge';

import { Button, ButtonProps } from '@elements/button';

import { Text, TextProps } from '../text';

export type ErrorScreenProps = {
  titleTextProps?: TextProps;
  bodyTextProps?: TextProps;
  Icon?: React.FC;
  actions?: ButtonProps[];
};

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  titleTextProps,
  bodyTextProps,
  Icon,
  actions,
}) => (
  <div className="absolute inset-0 bg-nusa-200 flex flex-col items-center justify-center z-10">
    <div className="space-y-7 px-4 text-center w-full md:w-3/4 lg:w-[596px] flex flex-col items-center justify-center">
      {Icon ? <Icon /> : null}
      <Text variant="h2-36/sb" {...titleTextProps} />
      <Text variant="body-16/m" {...bodyTextProps} className="whitespace-pre-line" />
      {actions?.length ? (
        <div className="w-full flex flex-col justify-center items-center space-y-4 md:flex-row md:space-x-4 md:space-y-0">
          {actions?.map(({ className, ...rest }) => (
            <Button key={rest.title} className={twMerge('w-full md:w-fit', className)} {...rest} />
          ))}
        </div>
      ) : null}
    </div>
  </div>
);
