import { EmcDataType } from '@packages/types';

export const showEmcCoveredCard = (emcData?: EmcDataType) => {
  if (!emcData) {
    return false;
  }
  return emcData?.medicalConditions?.length > 0;
};

export const shouldShowConfirmAssessmentOnNameChange = (
  names: Pick<EmcDataType, 'firstName' | 'lastName'>,
  emcTraveller?: EmcDataType,
) => {
  if (!emcTraveller) return false;

  return (
    !!emcTraveller.isCompleted &&
    (emcTraveller?.firstName !== names.firstName || emcTraveller?.lastName !== names.lastName)
  );
};

export const EmcErrorMessage = {
  NO_DATA: 'emcShared.global.web.errors.noData',
  YES_AND_NO_NOT_SELECTED: 'emcShared.global.errors.yesAndNoNotSelected',
  YES_SELECTED_BUT_REQUIRES_SCREENING: 'emcShared.global.errors.requireScreening',
  REQUIRES_REDECLARATION: 'emcShared.global.redeclare.error',
} as const;
