import { FC, useEffect, useRef, useState } from 'react';

import { useConfigStore } from '@packages/stores';
import { TRIP_STATUS } from '@packages/types';

import { PaymentLoader, Text } from '@components';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { closeModalSelector, useModalStore, useTripStore } from '@store';
import { sendAnalyticsEvent, sendAnalyticsPaymentSuccessful, testProps } from '@utils';

export interface ProcessingPaymentModalProps {
  children?: React.ReactNode;
}

const messages = i18n.t('checkout.payment.modal.titleMessages', {
  returnObjects: true,
});

const REFRESH_AFTER_SECONDS = 60;

export const ProcessingPaymentModal: FC<ProcessingPaymentModalProps> = () => {
  const closeModal = useModalStore(closeModalSelector);
  const trip = useTripStore(state => state.trip);
  const getTrip = useTripStore(state => state.getTrip);
  const timer = useRef<number | null>(null);

  const isPenguinTimeoutEnabled =
    useConfigStore(state => state?.regionSpecificConfig?.RULES.PAYMENT_TIMEOUT_ENABLED) ?? true;

  const [messageIndex, setMessageIndex] = useState(0);
  const processPaymentTimeoutSecs =
    useConfigStore(
      state => state?.regionSpecificConfig?.RULES.PAYMENT_PROCESSING_TIMEOUT_SECONDS,
    ) ?? 15;

  useEffect(() => {
    //If error close modal and stay on the payment screen
    if (trip?.policyStatus === 'ERROR') {
      return closeModal();
    }

    // Payment is a success so proceed to confirmation.
    if (trip?.state === TRIP_STATUS.PAID) {
      closeModal();
      sendAnalyticsPaymentSuccessful();
      router.navigate(ROUTE_PATHS().confirmation);
      return;
    }

    if (isPenguinTimeoutEnabled) {
      // After 15 seconds, close the modal and navigate to confirmation.
      const redirectTimer = setTimeout(
        async () => {
          await getTrip();
          closeModal();
          sendAnalyticsEvent('Processing payment confirmation variant display');
          router.navigate(ROUTE_PATHS().confirmation);
        },
        (processPaymentTimeoutSecs + 1) * 1000,
      );

      return () => clearTimeout(redirectTimer);
    } else {
      timer.current = window.setTimeout(() => {
        getTrip();
      }, REFRESH_AFTER_SECONDS * 1000);

      return () => {
        if (timer.current) {
          window.clearTimeout(timer.current);
        }
      };
    }
  }, [closeModal, getTrip, isPenguinTimeoutEnabled, processPaymentTimeoutSecs, trip]);

  // Since the messages are statically typed we would need to send the analytics event based on the frame numbers
  const sendAnalyticsEventForFrame = (frameNumber: number) => {
    switch (frameNumber) {
      case 1:
        sendAnalyticsEvent('Processing payment frame one open');
        break;
      case 2:
        sendAnalyticsEvent('Processing payment frame two open');
        break;
      case 3:
        sendAnalyticsEvent('Processing payment frame three open');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isPenguinTimeoutEnabled) {
      const interval = setInterval(() => {
        setMessageIndex(index => (index + 1) % messages.length);
      }, 16000 / 3);

      return () => clearInterval(interval);
    }
  }, [isPenguinTimeoutEnabled]);

  useEffect(() => {
    if (isPenguinTimeoutEnabled) {
      sendAnalyticsEventForFrame(messageIndex + 1);
    }
  }, [isPenguinTimeoutEnabled, messageIndex]);

  return (
    <div className="flex flex-col items-center justify-center gap-4 px-4 pb-2 bg-mono-100 rounded-2xl">
      <PaymentLoader />
      <Text
        {...testProps('processing-your-payment')}
        className="mt-2 text-center"
        variant="h2-36/sb">
        {isPenguinTimeoutEnabled ? messages[messageIndex] : i18n.t('checkout.payment.modal.title')}
      </Text>
      <Text className="mt-2 text-center" variant={'subTitle-20/r'}>
        {i18n.t('checkout.payment.modal.subtitle')}
      </Text>
    </div>
  );
};
