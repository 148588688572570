import { FC } from 'react';

import { useRegionStore } from '@packages/stores';

import { DestinationsFooter as AU, Props as AUProps } from './au/destinationsFooter.component';
import { DestinationsFooter as US, Props as USProps } from './us/destinationsFooter.component';

type Props = AUProps & USProps;

export const DestinationsFooter: FC<Props> = props => {
  const createRegionSpecificComponent = useRegionStore(
    state => state.createRegionSpecificComponent,
  );
  const Component = createRegionSpecificComponent<Props>({ AU, US });
  return <Component {...props} />;
};
