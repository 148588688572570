import { Boost } from '@packages/types';
import { isPopularTagPresent } from '@packages/utils';

import { Popover } from '@components';
import { i18n } from '@i18n';

type CoverPopoverProps = {
  className?: string;
  title: string;
  boosts: Boost[];
};

export const CoverPopover = ({ title, className, boosts }: CoverPopoverProps) => {
  const modalTitle = i18n.t('boostShared.tagInfo.heading');
  const description = i18n.t('boostShared.tagInfo.description');

  const showPopover = isPopularTagPresent(boosts);

  if (!showPopover) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex flex-row items-center">
        <Popover
          textLinkTitle={title}
          titleProps={{
            children: modalTitle,
          }}
          contentProps={{
            children: description,
            withMarkDown: true,
          }}
          buttonProps={{
            variant: 'tertiary',
            icon: 'info',
          }}
        />
      </div>
    </div>
  );
};
