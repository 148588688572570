import { InputHTMLAttributes, LegacyRef, forwardRef } from 'react';

import { CheckboxSVGIcon } from '../checkboxSVG';
import { Text } from '../text';
import { testProps } from '@utils';

export type YesNoRadioButtonProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  hasAgreed?: boolean;
  name: string;
};
export const YesNoRadioButton = ({ id, hasAgreed, onChange, name }: YesNoRadioButtonProps) => {
  const hasSelectedNo = hasAgreed === false;
  const hasSelectedYes = hasAgreed === true;
  return (
    <div className="flex">
      <RadioButton
        label="Yes"
        checked={hasSelectedYes}
        id={`${id ?? ''}-yes`}
        onChange={onChange}
        name={name}
        {...testProps('radio-yes')}
      />
      <RadioButton
        name={name}
        label="No"
        checked={hasSelectedNo}
        classnames="ml-4"
        id={`${id ?? ''}-no`}
        onChange={onChange}
        {...testProps('radio-no')}
      />
    </div>
  );
};

type RadioButtonProps = {
  label: string;
  id: string;
  checked?: boolean;
  classnames?: string;
  disabled?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioButton = forwardRef(
  (
    { label, id, checked, classnames, name, ...rest }: RadioButtonProps,
    ref?: LegacyRef<HTMLInputElement>,
  ) => (
    <div className={classnames}>
      <input
        ref={ref}
        type="radio"
        id={id}
        name={name}
        value={label}
        className="hidden peer"
        {...rest}
        checked={checked}
      />
      <label
        htmlFor={id}
        className="w-24 h-14 relative bg-cabo-50 border-2 cursor-pointer rounded-2xl flex justify-center items-center after:content-[''] after:absolute after:rounded-full before:content-[''] before:absolute before:rounded-full before:hover:bg-nusa-100 after:bg-mono-100 after:h-5 after:w-5 after:border after:border-fuji-50 after:top-1-2 after:left-[14px] before:z-[1] before:bg-cabo-50 before:h-3 before:w-3 before:left-[18px] before:top-[calc(50%px)] peer-checked:[&>svg]:block peer-checked:after:hidden peer-checked:before:hidden peer-checked:border-nusa-200 peer-checked:bg-nusa-50 hover:bg-nusa-50 hover:border-nusa-50">
        <CheckboxSVGIcon
          isChecked
          className="h-5 w-5 hidden absolute left-[14px]"
          variant={{ shape: 'circle' }}
        />
        <Text variant="body-16/sb" className="relative left-[8px]">
          {label}
        </Text>
      </label>
    </div>
  ),
);

RadioButton.displayName = 'RadioButton';
