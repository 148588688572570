import { ScreeningQuestionErrorType } from '@packages/types';

import { Text, YesNoRadioButton } from '@components';
import { Notification } from '@elements/notification';
import { i18n } from '@i18n';
import { useEmcStore } from '@store';

const handleRadioButton = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
  useEmcStore.getState().updateScreeningQuestion(index, e.target.value === 'No');
};

export function QuestionList() {
  const screeningQuestions = useEmcStore(state => state.screeningQuestions);

  return (
    <div className="gap-10 grid mt-2">
      {screeningQuestions.map((it, index) => {
        const { question, hasSelectedNo, errorType } = it;

        return (
          <div key={`emc-questions-${index}`}>
            <Text variant="body-16/r" className="mb-2">
              {question}
            </Text>
            <YesNoRadioButton
              id={question}
              onChange={handleRadioButton(index)}
              // Keep the undefined selection
              hasAgreed={hasSelectedNo === undefined ? hasSelectedNo : !hasSelectedNo}
              name={question}
            />
            <QuestionError errorType={errorType} />
          </div>
        );
      })}
    </div>
  );
}
export function QuestionError({ errorType }: { errorType: ScreeningQuestionErrorType }) {
  if (errorType === 'hasSelectedYes') {
    return (
      <Notification
        variant="error"
        className="mt-s12"
        header={i18n.t('emcShared.preAssessment.screeningQuestionsErrors.hasSelectedYes.title')}
        body={i18n.t('emcShared.preAssessment.screeningQuestionsErrors.hasSelectedYes.description')}
      />
    );
  }

  return null;
}
