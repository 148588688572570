import { useMemo, useState } from 'react';

import { convertCentsToDollars } from '@packages/utils';

import { Button } from '@elements/button';
import { useCheckoutFlow } from '@hooks';
import { i18n } from '@i18n';
import { useCheckoutStore, useTripStore } from '@store';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { onPayPress, testProps } from '@utils';

export const TotalPriceButton = () => {
  const { tripCost, isFreeOfCharge } = useTripStore(state => ({
    tripCost: state?.trip?.price,
    isFreeOfCharge: !!state?.trip?.promotionCode?.isFreeOfCharge,
    isTripPending: state.pending,
  }));
  const isTripPending = useTripStore(state => state.pending);
  const [isLoading, setIsLoading] = useState(false);

  const { onCheckoutProgress } = useCheckoutFlow();
  const stripe = useStripe();
  const elements = useElements();

  const { paymentSectionType, openSection, isLegalConsentChecked } = useCheckoutStore(state => ({
    paymentSectionType: state?.paymentSectionType,
    openSection: state?.openSection,
    isLegalConsentChecked: state?.isLegalConsentChecked,
  }));

  const onClick = async () => {
    setIsLoading(true);
    await onCheckoutProgress(undefined, async () => {
      if (
        openSection === 'payment' ||
        (openSection === 'legalStuff' && isLegalConsentChecked && isFreeOfCharge)
      ) {
        const paymentPayload = isFreeOfCharge ? {} : { stripe, elements };
        return await onPayPress(paymentPayload);
      }
    });
    setIsLoading(false);
  };

  const buttonText = useMemo(() => {
    if (openSection === 'payment') {
      if (paymentSectionType === 'applepay') {
        return 'ApplePayButtonLogo';
      } else if (paymentSectionType === 'googlepay') {
        return 'GooglePayButtonLogo';
      } else {
        return i18n.t('checkout.payment.button.pay', {
          amount: convertCentsToDollars(tripCost),
        });
      }
    }

    return i18n.t('checkout.payment.button.continue');
  }, [openSection, paymentSectionType, tripCost]);

  const paymentIcon = useMemo(() => {
    return buttonText === 'ApplePayButtonLogo' || buttonText === 'GooglePayButtonLogo'
      ? buttonText
      : undefined;
  }, [buttonText]);

  return (
    <Button
      {...testProps('text_buy_now_button')}
      variant="primary"
      onClick={onClick}
      isLoading={isLoading || isTripPending}
      className="w-full"
      title={buttonText}
      icon={paymentIcon}
    />
  );
};
