import { updateGuestUser } from 'freely-shared-api';

import { GetStore } from '@packages/stores';
import { GuestDetails } from '@packages/types';

import { captureException, withScope } from '@sentry/react';
import { sentryTags } from '@utils';

import { GuestStore } from './guest.store';

export const updateGuest = async (get: GetStore<GuestStore>) => {
  return await withScope(async () => {
    sentryTags({ event: 'update.guest', source: 'updateGuest' });
    const { setError, setPending, setGuest, guest } = get();
    try {
      setPending(true);
      if (!guest) {
        throw new Error('No guest provided');
      }
      const guestResponse = (await updateGuestUser({
        guest,
      })) as GuestDetails;
      setGuest(guestResponse);
      return guestResponse;
    } catch (error) {
      captureException(error);
      setError(error as Error);
    } finally {
      setPending(false);
    }
  });
};
