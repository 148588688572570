import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { convertCentsToDollars } from '@packages/utils';

import { CheckoutSummaryPopOver, StickyFooter } from '@components';
import { SideBar } from '@components/sideBar/sideBar.component';
import { Button, ButtonProps } from '@elements/button';
import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { getTripSelector, useTripStore } from '@store';
import { testProps } from '@utils';

import { Container } from '../container';
import { ErrorBanner } from '../errorBanner';
import { TripHeader, TripHeaderProps } from '../tripHeader';

export interface QuoteFlowContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  actions?: ButtonProps[];
}

export const QuoteFlowContainer: FC<QuoteFlowContainerProps> = ({ children, actions, ...rest }) => {
  const currentPathname = getCurrentPathname();
  const trip = useTripStore(getTripSelector);
  const { myQuote, checkout } = ROUTE_PATHS();
  const tripHeaderProps: () => TripHeaderProps = () => {
    if (currentPathname === myQuote) {
      return {
        variant: 'QUOTE',
      };
    } else if (currentPathname === checkout) {
      return {
        variant: 'CHECKOUT',
      };
    } else {
      return {
        titleTextProps: { children: 'Your Insurance<br /> Policy Details.' },
        subtitleTextProps: { children: 'Please review and enter your details below.' },
      };
    }
  };

  return (
    <div {...rest}>
      <TripHeader {...tripHeaderProps()} />
      {currentPathname === checkout && (
        <>
          <ErrorBanner />
          <CheckoutSummaryPopOver totalPrice={convertCentsToDollars(trip.price)} />
        </>
      )}
      <Container>
        <div className="grid lg:grid-cols-12 ">
          <div
            className={twMerge('col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-7 lg:mr-4')}>
            {children}
            <div {...testProps('boost-card-continue-button')} className="mt-4">
              {actions?.map((buttonProps, index) => (
                <Button className="mb-4" key={`${index} ${buttonProps?.title}`} {...buttonProps} />
              ))}
            </div>
          </div>
          <div className="relative hidden sm:col-span-12 md:col-span-6 lg:col-span-5 lg:ml-4 lg:block">
            <div className="sticky top-4">
              <SideBar />
            </div>
          </div>
        </div>
      </Container>
      {[checkout, myQuote].includes(currentPathname) ? <StickyFooter /> : null}
    </div>
  );
};
