import { HTMLAttributes, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { Assets } from '@assets';

export type IconVariant = 'feature' | 'primary' | 'secondary' | 'tertiary' | 'destructive';
export type IconSize = 'sm' | 'md' | 'lg';

type IconNameOutlined =
  | 'add'
  | 'add_circle'
  | 'info'
  | 'error'
  | 'check_circle'
  | 'close'
  | 'arrow_back'
  | 'cancel'
  | 'flight_takeoff'
  | 'flight_land'
  | 'delete'
  | 'arrow_drop_down'
  | 'calendar_today'
  | 'thumb_up'
  | 'search'
  | 'star'
  | 'loyalty';

type IconNameFilled = `${IconNameOutlined}_filled`;
export type IconName = IconNameOutlined | IconNameFilled | keyof typeof Assets;

export type IconProps = {
  name?: string;
  variant?: IconVariant;
  size?: IconSize;
} & HTMLAttributes<HTMLSpanElement>;

const iconVariant = tv({
  base: 'text-[1.5rem] [&>svg]:size-[1.5rem] fill-current',
  variants: {
    variant: {
      feature: 'text-action',
      primary: 'text-action-dark-surface',
      secondary: 'text-action',
      tertiary: 'text-link-default',
      destructive: 'text-error',
    },
    size: {
      sm: 'text-[1.25rem] [&>svg]:size-[1.25rem]',
      md: 'text-[1.5rem] [&>svg]:size-[1.5rem]',
      lg: 'text-[2rem] [&>svg]:size-[2rem]',
    },
  },
});

export const Icon = ({ name, className, size, variant, ...rest }: IconProps) => {
  if (!name) return null;
  if (name in Assets) {
    const IconComponent = Assets[name as keyof typeof Assets] as React.FC<SVGProps<SVGElement>>;
    return <IconComponent className={iconVariant({ size, variant, className })} />;
  }
  const isFilled = name?.includes('_filled');
  return (
    <span
      {...rest}
      className={twMerge(
        isFilled && 'filled',
        'material-symbols-outlined',
        iconVariant({ size, variant, className }),
      )}>
      {name?.replace('_filled', '')}
    </span>
  );
};
