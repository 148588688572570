import type { ScreeningResultRequest, ScreeningResultResponse } from '@packages/types';

import { openApiInstances } from './openApis';

export const saveScreeningResult = async ({
  country,
  boostId,
  travellerId,
  isAdded,
  isSelected,
  primaryTravellerId,
  result,
  firstName = null,
  lastName = null,
  isCompleted,
}: ScreeningResultRequest) => {
  try {
    const hasIsCompletedValue = isCompleted !== undefined;
    const { data } = await openApiInstances.Trip.put<ScreeningResultResponse>(
      `/${country.toLowerCase()}/screening-result/${boostId}/${travellerId}`,
      {
        isAdded,
        isSelected,
        data: result,
        userId: primaryTravellerId,
        firstName,
        lastName,
        ...(hasIsCompletedValue ? { isCompleted: isCompleted } : {}),
      },
    );
    return data;
  } catch {
    throw new Error(`Failed to save screening result for traveller ${travellerId}`);
  }
};

export const getDownloadAssessementLetter = (assessmentNumber: string) => {
  return openApiInstances.Trip.get(`/au/emc/${assessmentNumber}`);
};
