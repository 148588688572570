import { Region, RemoteSettings, WebRegionSpecificRemoteSettings } from '@packages/types';

import { CreateStore, createStore } from '../utils/utils';
import { fetchConfig } from './config.api';

export interface ConfigState {
  config: RemoteSettings['web']['APP_CONFIG'] | undefined;
  pending: boolean;
  error?: Error;
  regionSpecificConfig: WebRegionSpecificRemoteSettings | undefined;
}

export interface ConfigActions {
  setPending: (value: boolean) => void;
  setError: (error: Error) => void;
  setConfig: (settings: RemoteSettings) => void;
  getConfig: () => Promise<RemoteSettings | undefined>;
  resetConfigStore: () => void;
  setRegionSpecificConfig: (region?: Region) => void;
}

export type ConfigStore = ConfigState & ConfigActions;

const initialState: ConfigState = {
  pending: false,
  config: undefined,
  regionSpecificConfig: undefined,
};

export const configStore: CreateStore<ConfigStore> = (set, get) => ({
  ...initialState,
  setConfig: (config: RemoteSettings) =>
    set(state => {
      state.config = config['web']['APP_CONFIG'];
    }),
  setPending: (pending: boolean) =>
    set(state => {
      state.pending = pending;
    }),
  setError: (error: Error) =>
    set(state => {
      state.error = error;
    }),
  getConfig: async () => fetchConfig(get),
  resetConfigStore: () => set(initialState, false),
  setRegionSpecificConfig: region => {
    const { config } = get();
    if (region?.country) {
      set(state => {
        state.regionSpecificConfig = config?.[region.country];
      });
    }
  },
});

export const useConfigStore = createStore(configStore, {
  name: 'useConfigStore',
});
