import { CheckoutStore } from '@store';

export const isManualAddressSectionOpenSelector = (state: CheckoutStore) =>
  state.isManualAddressSectionOpen;

export const setIsManualAddressSectionSelector = (state: CheckoutStore) =>
  state.setIsManualAddressSectionOpen;

export const getDisplaySummaryDetailsSelector = (state: CheckoutStore) =>
  state.displaySummaryDetails;
