import { useDestinationsStore } from '@packages/stores';
import { TripDestination } from '@packages/types';
import { isSuggestedDestinationSelected } from '@packages/utils';

import { Loader, Text } from '@components';
import { Selector } from '@elements/selector';
import { i18n } from '@i18n';
import { sluggify, testProps } from '@utils';

export interface TrendingDestinationProps {
  selectedDestinations: TripDestination[];
  onToggleDestination: (destination: TripDestination) => void;
}

export const TrendingDestinations: React.FC<TrendingDestinationProps> = ({
  selectedDestinations,
  onToggleDestination,
}) => {
  const loadingDestinations = useDestinationsStore(state => state.pending);
  const trendingDestinations = useDestinationsStore(state => state.trendingDestinations);

  return (
    <>
      <Text variant="subTitle-20/sb" className="py-6 text-center">
        {i18n.t('selectDestinations.trendingDestinations.title')}
      </Text>
      <div className="my-4 flex-1 pb-4 text-center">
        {loadingDestinations ? (
          <Loader />
        ) : (
          <ul role="list" className="flex flex-wrap gap-x-s12 gap-y-s20">
            {trendingDestinations?.map(destination => {
              const isSelected = isSuggestedDestinationSelected(destination, selectedDestinations);
              return (
                <li key={`${destination.countryCode}-${destination.longName}`}>
                  <Selector
                    title={destination.longName ?? ''}
                    selected={isSelected}
                    multiple
                    {...testProps(`select-country-destination-${sluggify(destination.longName)}`)}
                    onClick={() => onToggleDestination(destination)}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
};
