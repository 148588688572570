import { captureException } from '@sentry/react';

/**
 * Fallback to defined config in env if there is an error
 * @param e
 */
export function fallbackToDefaultConfig(e?: any) {
  e && captureException(e);
  return import.meta.env.VITE_REMOTE_CONFIG['AWS_AMPLIFY'];
}
