import { Fragment } from 'react';

import {
  getCoolingOffPeriodDaysSelector,
  getRegionSelector,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';

import { Assets } from '@assets';
import { Text } from '@components';
import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { sluggify, testProps } from '@utils';

export const AdditionalInformation = () => {
  const region = useRegionStore(getRegionSelector);
  const coolingDaysSelector = useConfigStore(getCoolingOffPeriodDaysSelector);

  const coolOffPeriod = coolingDaysSelector(region?.state ?? '');
  const pdsLink = useBoostDetailsStore(
    state => state?.mandatoryBoostDetails?.template?.pdsLink?.value,
  );
  const additionalInformation = useBoostDetailsStore(
    state => state?.mandatoryBoostDetails?.template?.additionalInformation,
  );

  const onPdsLinkClick = () => {
    if (!pdsLink) return;
    window.open(pdsLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="border-t pt-8 mt-4">
      <div className="flex pb-6">
        <Assets.CalendarCoolOff height={32} width={32} className="text-fuji-800 mr-2" />
        <Text variant="h4-24/sb" {...testProps('cooling-off-title')}>
          {i18n.t('myQuote.additionalInformation.coolingOff', {
            cool_off_period: coolOffPeriod,
          })}
        </Text>
      </div>
      <Text variant="body-16/r" {...testProps('cooling-off-description')}>
        {i18n.t('myQuote.additionalInformation.content', {
          cool_off_period: coolOffPeriod,
        })}
      </Text>
      <div className="border-t my-8" />
      <Text variant="h3-28/sb" className="pb-6" {...testProps('additional-info-heading')}>
        {additionalInformation?.heading}
      </Text>
      {additionalInformation?.items.map(item => (
        <Fragment key={item.title}>
          <Text
            variant="subTitle-20/sb"
            className="mb-3"
            {...testProps(`additional-info-${sluggify(item.title)}`)}>
            {item.title}
          </Text>
          <Text
            variant="body-16/r"
            className="mb-8"
            {...testProps(`additional-info-${sluggify(item.title)}-description`)}>
            {item.description}
          </Text>
        </Fragment>
      ))}
      {pdsLink && (
        <Button
          {...testProps('what-is-cover-button')}
          onClick={onPdsLinkClick}
          variant="secondary"
          title={i18n.t('myQuote.additionalInformation.buttonAction')}
        />
      )}
    </div>
  );
};
