import { i18n } from '@i18n';

import { Text } from '../../text';

export const DestinationsNote: React.FC = () => {
  return (
    <Text variant="footnote-12/m" className="text-center">
      {i18n.t('selectDestinations.footNote.title')}
    </Text>
  );
};
