import React, { useEffect } from 'react';

import { Assets } from '@assets';
import { useChooseRegion } from '@hooks';
import { useModalStore } from '@store';
import { clearSessionStorage } from '@utils';

export const Home: React.FC = () => {
  const { openRegionModal } = useChooseRegion();
  const isModalOpen = useModalStore(state => state.modals.length > 0);

  useEffect(() => {
    clearSessionStorage();
    if (!isModalOpen) {
      openRegionModal({
        blurOverlay: false,
        showCloseButton: false,
        shouldCloseOnOverlayClick: false,
      });
    }
  }, [openRegionModal, isModalOpen]);

  return (
    <main className="relative h-screen w-screen items-center justify-center">
      <img
        src={Assets.HomeBackground}
        className="absolute inset-0 h-full w-full object-cover"
        alt="home background"
      />
    </main>
  );
};
