import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useConfigStore } from '@packages/stores';

import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { useQuoteStore } from '@store';
import { EMAIL_QUOTE_FLOW_TYPE } from '@types';
import { sendAnalyticsEvent } from '@utils';

import { useEmailQuote } from './useEmailQuote/useEmailQuote';

const events = ['mousemove', 'click', 'keypress'];

export const useEmailQuoteReminder = () => {
  const idleEvent = useRef<number>();
  const currentPathname = getCurrentPathname();
  const { myQuote } = ROUTE_PATHS();
  const { isEmailQuoteVisible, openEmailQuoteModal } = useEmailQuote();
  const didEmailQuoteShow = useQuoteStore(state => state.didEmailQuoteShow);
  const setDidEmailQuoteShow = useQuoteStore(state => state.setDidEmailQuoteShow);
  const setEmailQuoteLaunchedFrom = useQuoteStore(state => state.setEmailQuoteLaunchedFrom);
  const [showModal, setShowModal] = useState(false);

  const idleTimeout =
    useConfigStore(state => state?.regionSpecificConfig?.RULES.IDLE_EMAIL_QUOTE_REMINDER_SECONDS) ??
    30;

  const canShowEmailQuote = useMemo(
    () => isEmailQuoteVisible && !didEmailQuoteShow && currentPathname === myQuote,
    [isEmailQuoteVisible, didEmailQuoteShow, currentPathname, myQuote],
  );

  const handleTimeout = useCallback(() => {
    if (idleEvent.current) window.clearTimeout(idleEvent.current);

    idleEvent.current = window.setTimeout(() => {
      setShowModal(true);
    }, idleTimeout * 1000);
  }, [idleTimeout]);

  useEffect(() => {
    if (showModal && canShowEmailQuote) {
      setDidEmailQuoteShow(true);
      setShowModal(false);
      setEmailQuoteLaunchedFrom(EMAIL_QUOTE_FLOW_TYPE.ABANDON_CART);
      openEmailQuoteModal('EmailQuote', false, EMAIL_QUOTE_FLOW_TYPE.ABANDON_CART);
      sendAnalyticsEvent('Email a Quote Form Opened', {
        flow: EMAIL_QUOTE_FLOW_TYPE.ABANDON_CART,
      });
    }
  }, [
    showModal,
    canShowEmailQuote,
    openEmailQuoteModal,
    setEmailQuoteLaunchedFrom,
    setDidEmailQuoteShow,
  ]);

  useEffect(() => {
    if (canShowEmailQuote) {
      for (const e in events) {
        window.addEventListener(events[e], handleTimeout);
      }
    }

    return () => {
      if (canShowEmailQuote) {
        if (idleEvent.current) window.clearTimeout(idleEvent.current);

        for (const e in events) {
          window.removeEventListener(events[e], handleTimeout);
        }
      }
    };
  }, [canShowEmailQuote, handleTimeout]);
};
