import { Text } from '@components';
import { i18n } from '@i18n';
import { useEmcStore } from '@store';
import { EmcErrorMessage } from '@utils';

export const EmcErrorText = ({ sortKey }: { sortKey?: string }) => {
  const errorTravellers = useEmcStore(state => state.errorTravellers);
  const showEmcErrorsOnContinue = useEmcStore(state => state.showEmcErrorsOnContinue);

  if (!sortKey || !showEmcErrorsOnContinue || !errorTravellers) return null;

  const errorTraveller = errorTravellers.find(
    traveller => traveller.travellerId === sortKey && traveller.errorType !== undefined,
  );

  if (!errorTraveller) return null;
  return (
    <Text key={errorTraveller.travellerId} className="text-red-500 mb-4" variant="subHeading-14/r">
      {i18n.t(EmcErrorMessage[errorTraveller?.errorType as keyof typeof EmcErrorMessage])}
    </Text>
  );
};
