import { useEffect } from 'react';

import { cruiseCoverBoostIdSelector, useTripStore } from '@store';

import { useBoostSelectionModal } from './useBoostSelectionModal';

export function useCruiseBoostPopUp() {
  const setCruiseBoostModalState = useTripStore(state => state.setCruiseBoostModalState);
  const shouldShow = useTripStore(state => state.cruiseBoostModalState === 'DISPLAY');
  const boostId = useTripStore(cruiseCoverBoostIdSelector);
  const openBoostSelectionModal = useBoostSelectionModal(boostId);

  useEffect(() => {
    if (!shouldShow) {
      return;
    }
    const turnOffCruiseCoverPopUpFlag = () => {
      setCruiseBoostModalState('SEEN');
    };

    openBoostSelectionModal({
      onSuccess: turnOffCruiseCoverPopUpFlag,
      onModalClose: turnOffCruiseCoverPopUpFlag,
    });
  }, [openBoostSelectionModal, setCruiseBoostModalState, shouldShow]);
}
