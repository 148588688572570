import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { openApiInstances } from 'freely-shared-open-api';

import { REGIONS } from '@packages/constants';
import {
  getRegionSelector,
  isAddTripEnabledSelector,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import { RegionCode } from '@packages/types';

import { Footer, Header, StopSell } from '@components';
import { GetQuoteLoading } from '@components/getQuoteLoading/getQuoteLoading.component';
import { useEmailQuoteReminder, useScrollToTop, useTimeout } from '@hooks';
import { ROUTES, ROUTE_PATHS, navigateToFirstRegionView, router } from '@routes/router';
import { captureException, setTag, withScope } from '@sentry/react';
import {
  getTripSelector,
  useFeatureFlagsStore,
  useGuestStore,
  useModalStore,
  useTripStore,
} from '@store';
import '@store/featureFlags/featureFlags.subscription';
import { Endpoints } from '@types';
import {
  clearSessionStorage,
  fallbackToDefaultConfig,
  getAnonymousId,
  sendAnalyticsECommerce,
  sentryTags,
  setClickIdAnonymousId,
} from '@utils';

import { Emc } from '../checkout';

const getConfig = useConfigStore.getState().getConfig;
const setRegion = useRegionStore.getState().setRegion;
const initFeatureFlags = useFeatureFlagsStore.getState().initFeatureFlags;
const setIsCreatingOrUpdatingTrip = useTripStore.getState().setIsCreatingOrUpdatingTrip;
const resetModalState = useModalStore.getState().resetModalState;
const resetTripState = useTripStore.getState().resetTripState;
const resetGuestState = useGuestStore.getState().resetGuestState;

export const Quote = () => {
  useScrollToTop();
  const trip = useTripStore(getTripSelector);
  const isCreatingOrUpdatingTrip = useTripStore(state => state.isCreatingOrUpdatingTrip);
  const region = useRegionStore(getRegionSelector);
  const isAddTripEnable = useConfigStore(isAddTripEnabledSelector);
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isLoadingQuote = useMemo(
    () => location.pathname.endsWith(`/${ROUTES.LOAD_QUOTE}`) && !!searchParams.get('quoteId'),
    [location.pathname, searchParams],
  );
  const shouldDisableWindowScroll = useModalStore(state =>
    state.currentOpenModals.some(it => 'CovidConsent' === it),
  );

  const onClose = () => {
    clearSessionStorage();
    getConfig().catch(e => captureException(e));
    resetTripState();
    resetModalState();
    resetGuestState();
    window.location.href = `/quote/${params.region}`;
  };
  useTimeout({ onClose });
  useEmailQuoteReminder();

  /**
   * This is to fix the issue with the back button
   * if the user clicks the back button on the browser on to this page it will go back to the start of our app
   */
  useLayoutEffect(() => {
    window.onpopstate = () => {
      if (window.location.pathname === `/${ROUTES.QUOTE}/${region?.country.toLowerCase()}`) {
        router.navigate(-1);
      }
    };
  });

  useLayoutEffect(() => {
    window.onpopstate = e => {
      if (isCreatingOrUpdatingTrip) {
        e.preventDefault();
        router.navigate(1);
        setIsCreatingOrUpdatingTrip(false);
      }
    };
  });

  useEffect(() => {
    setTag('section', 'createQuote');
  }, []);

  useLayoutEffect(() => {
    withScope(() => {
      sentryTags({ event: 'effect.init', source: 'quote.view.tsx' });
      (async () => {
        const paramRegion = params.region?.toUpperCase();
        const config = await getConfig().catch(fallbackToDefaultConfig);
        const QuoteApiConfig = (config?.web?.OPEN_APIS?.endpoints as Endpoints)?.find(
          it => it.name === 'Quote',
        );
        const regionParam = `/${paramRegion?.toLowerCase() ?? 'au'}`;

        openApiInstances.Quote.defaults.headers.common['x-platform'] = 'web';
        openApiInstances.Quote.defaults.headers.common.platform = 'web';
        const anonymousId = await getAnonymousId();

        for (const key in REGIONS) {
          const regionCode = REGIONS[key as RegionCode].code;

          if (paramRegion === regionCode) {
            setRegion({ country: regionCode });

            if (QuoteApiConfig) {
              openApiInstances.Quote.defaults.baseURL = QuoteApiConfig.endpoint + regionParam;
              openApiInstances.Quote.defaults.headers.common['x-api-key'] = regionParam?.includes(
                'au',
              )
                ? import.meta.env.VITE_AU_OPEN_API_KEY
                : import.meta.env.VITE_US_OPEN_API_KEY;
            }

            if (anonymousId) {
              await initFeatureFlags({
                region: regionCode,
                platform: 'web',
                anonymousId,
                apiKey: import.meta.env.VITE_POSTHOG_API_KEY,
                apiURL: import.meta.env.VITE_POSTHOG_API_HOST,
              }).catch(captureException);
            }
          }

          if (!isLoadingQuote) {
            const routePaths = ROUTE_PATHS();
            const baseRoutes = [
              `/${ROUTES.QUOTE}/${regionCode.toLowerCase()}`,
              routePaths.tripDestinations,
              routePaths.tripDates,
              routePaths.tripTravellers,
              routePaths.tripCost,
              routePaths.tripDepositDate,
              routePaths.stateOfResidence,
            ];

            if (baseRoutes.includes(window.location.pathname) || !trip?.sortKey) {
              resetTripState();
              navigateToFirstRegionView();
              clearSessionStorage();
            }
          }
        }
      })();
    });
  }, [isLoadingQuote, params.region, trip?.sortKey]);

  useEffect(() => {
    sendAnalyticsECommerce('Cart Viewed');
    setClickIdAnonymousId();
  }, []);

  if (!isAddTripEnable) {
    return <StopSell />;
  }

  return (
    <div
      className={twMerge(
        'flex min-h-screen flex-col',
        shouldDisableWindowScroll && 'overflow-hidden max-h-screen',
      )}>
      <Header />
      <Outlet />
      <GetQuoteLoading />
      <Emc />
      <Footer />
    </div>
  );
};
