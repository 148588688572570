import { useEffect } from 'react';

import { AutoDiscountCard } from '@components';
import { DiscountCodeInput } from '@components/discountCodeInput';
import { i18n } from '@i18n';
import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { useFeatureFlagsStore } from '@store';

import { TotalPriceAndGroupDiscount } from './totalPriceAndGroupDiscount';
import { TripDetails } from './tripDetails';
import { TripSummary } from './tripSummary';

type Props = {
  onMount?: () => void;
};

export const SideBar: React.FC<Props> = ({ onMount }) => {
  const { checkout, myQuote } = ROUTE_PATHS();
  const BUY_NOW_ROUTES = [checkout, myQuote];
  const TRIP_DETAILS_ROUTES = [checkout, myQuote];
  const PAYMENT_SUMMARY_ROUTES = [checkout];
  const currentPathname = getCurrentPathname();

  const showBuyNow = BUY_NOW_ROUTES.includes(currentPathname);

  const isDiscountCodeEnabled = useFeatureFlagsStore(
    state => state?.featureFlags?.['discount-code-input']?.isEnabled,
  );

  const showDiscountInputOnMyQuote =
    useFeatureFlagsStore(
      state => state?.featureFlags?.['discount-code-input']?.payload?.pages?.['my-quote'],
    ) && currentPathname === myQuote;

  const showDiscountInput =
    isDiscountCodeEnabled && (showDiscountInputOnMyQuote || currentPathname === checkout);

  const showTripDetails = TRIP_DETAILS_ROUTES.includes(currentPathname);

  const showTripDetailsOnTop = myQuote === currentPathname;

  const showPaymentSummary = PAYMENT_SUMMARY_ROUTES.includes(currentPathname);

  useEffect(() => {
    onMount?.();
  }, [onMount]);

  return (
    <div>
      {showDiscountInput ? <DiscountCodeInput /> : null}
      {showTripDetails && showTripDetailsOnTop ? <TripDetails /> : null}
      {showPaymentSummary ? (
        <TripSummary titleProps={{ children: i18n.t('checkout.sideBar.paymentSummary.title') }} />
      ) : null}
      <AutoDiscountCard />
      <TotalPriceAndGroupDiscount showBuyNow={showBuyNow} showAfterPay />
      {showTripDetails && !showTripDetailsOnTop ? <TripDetails className="mt-4" /> : null}
    </div>
  );
};
