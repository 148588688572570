import { useCallback } from 'react';

import { ModalProps, Text } from '@components';
import { i18n } from '@i18n';
import { openModalSelector, useModalStore } from '@store';

export const useChooseRegion = () => {
  const openModal = useModalStore(openModalSelector);

  const openRegionModal = useCallback(
    (props?: ModalProps) => {
      openModal('RegionGridModal', {
        title: (
          <Text variant="h3-28/sb" className="pb-2">
            {i18n.t('global.countryOfResidency.modal.title')}
          </Text>
        ),
        titleClassName: 'text-left',
        bodyContainerClassName: 'mt-4 p-4 sm:p-6',
        ...props,
      });
    },
    [openModal],
  );

  return { openRegionModal };
};
