import { useContext, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { PAYMENT_TYPE } from '@packages/types';

import { Button } from '@elements/button';
import { Icon, IconName } from '@elements/icon';
import { useClickOutsideHandler } from '@hooks';

import {
  CreditCardDropdownContext,
  CreditCardDropdownProps,
  cardIconType,
} from './creditCardForm.component';

const cardOptions = [
  { value: PAYMENT_TYPE.VISA, label: 'Visa' },
  { value: PAYMENT_TYPE.MASTER_CARD, label: 'Mastercard' },
  { value: PAYMENT_TYPE.DISCOVER, label: 'Discover' },
  { value: PAYMENT_TYPE.AMEX, label: 'American Express' },
];

export const CardTypeDropdownComponent = () => {
  const { isCardDropdownOpen, handleCardTypeChange, handleBackdropClick, setIsCardDropdownOpen } =
    useContext<CreditCardDropdownProps>(CreditCardDropdownContext);
  const { nodeRef, openModal, setOpenModal } = useClickOutsideHandler();
  useEffect(() => {
    setOpenModal(isCardDropdownOpen ?? openModal);
  }, [isCardDropdownOpen]);

  useEffect(() => {
    if (isCardDropdownOpen && !openModal) {
      setIsCardDropdownOpen(false);
    }
  }, [openModal]);
  return (
    <div className={twMerge(' relative hidden', openModal && 'block')} ref={nodeRef}>
      <div
        aria-hidden="true"
        className={twMerge(`fixed inset-0 bg-fuji-800 opacity-50 z-20 sm:hidden`)}
        onClick={handleBackdropClick}></div>
      <div
        className={twMerge(
          `fixed bottom-0 left-0 w-full sm:absolute sm:-top-[2.5rem] sm:left-auto sm:h-[13.40rem] sm:right-0 sm:mt-1 sm:max-w-[355px] sm:min-w-[355px] bg-mono-100 border border-nevada-300 rounded-lg shadow-lg z-30`,
        )}>
        <div className="sm:hidden p-s12 flex justify-between items-center border-b">
          <span className="text-lg font-semibold">Select Card Type</span>
          <Button icon="close" variant="tertiary" size="sm" />
        </div>
        <ul className="pb-s16 p-2">
          {cardOptions.map(option => (
            <li
              key={option.value}
              onClick={() => handleCardTypeChange(option.value)}
              className="gap-s16 w-full p-s12 flex flex-row items-center cursor-pointer hover:bg-surface-action-primary-hover">
              <Icon name={cardIconType[option.value as keyof typeof cardIconType] as IconName} />
              {option.value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
