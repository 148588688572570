import { sendFeatureFlagEvents } from '@utils';

import { useFeatureFlagsStore } from './featureFlags.store';

useFeatureFlagsStore.subscribe((newState, oldState) => {
  if (
    oldState?.featureFlags &&
    newState?.featureFlags &&
    Object.values(oldState.featureFlags).some(i => i === undefined) &&
    Object.values(newState.featureFlags).some(i => i !== undefined)
  ) {
    sendFeatureFlagEvents(newState?.featureFlags);
  }
});
