import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import ENV_VARS_CONSTANTS from 'freely-shared-env-vars/envVars.json';

import * as Sentry from '@sentry/react';

import { getAnonymousId } from './analytics/analytics';

const SENTRY_DSN =
  'https://ab1a8e47337647209ed8f22b1ff18478@o347231.ingest.sentry.io/4504099426074624';

export const initSentry = async () => {
  Sentry.init({
    release: `${import.meta.env.VITE_ENV}:${
      ENV_VARS_CONSTANTS.APP_VERSION?.[import.meta.env.VITE_ENV]
    }`,
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: import.meta.env.VITE_ENV,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/rest\.gofreely\.xyz/],
    enabled: checkSentryEnabled(),
  });

  Sentry.setTags({
    anonymousId: (await getAnonymousId()) ?? '',
    STAGE: import.meta.env.VITE_ENV,
    version: ENV_VARS_CONSTANTS.APP_VERSION?.[import.meta.env.VITE_ENV],
  });
};

const isProd = process.env.MODE?.toLowerCase().startsWith('prod');
export const checkSentryEnabled = () => {
  if (isProd) {
    return true;
  }

  try {
    const isSentryEnabled = localStorage.getItem('IS_SENTRY_ENABLED');
    return isSentryEnabled === 'true';
  } catch {
    // do nothing
  }

  return false;
};

export const toggleSentryEnabled = () => {
  try {
    const isEnabled = checkSentryEnabled();
    // eslint-disable-next-line
    // @ts-ignore
    localStorage.setItem('IS_SENTRY_ENABLED', isEnabled ? 'false' : 'true');
  } catch {
    // do nothing
  }
};

type DomainType =
  | 'destinations'
  | 'featureFlags'
  | 'remoteConfig'
  | 'boost'
  | 'trip'
  | 'quote'
  | 'emcLetter'
  | 'promoCode'
  | 'guest'
  | 'emailQuote'
  | 'emcScreeningResults'
  | 'paymentIntent';

type EffectEvent = 'init' | 'subscribe';

type UserAction =
  | 'fetch'
  | 'create'
  | 'delete'
  | 'purchase'
  | 'submit'
  | 'add'
  | 'remove'
  | 'toggle'
  | 'update'
  | 'refresh'
  | 'cancel'
  | 'revert'
  | 'confirm';

type WebEvent = `${UserAction}.${DomainType}` | `effect.${EffectEvent}` | 'missing.translation';

export const sentryTags = ({ source, event }: { source: string; event: WebEvent }) => {
  Sentry.getCurrentScope()?.setTags({
    source,
    event,
  });
};
