import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';
import { Text } from '@components';
import { SideBar } from '@components/sideBar/sideBar.component';
import { Popover, Transition } from '@headlessui/react';
import { i18n } from '@i18n';
import { sendAnalyticsEvent } from '@utils';

type Props = {
  totalPrice: string;
};

export const CheckoutSummaryPopOver: React.FC<Props> = ({ totalPrice }) => {
  const handleSidebarExpanded = useCallback(() => {
    sendAnalyticsEvent('Traveller Details Payment Summary Expanded');
  }, []);

  return (
    <Popover className="sticky top-0 z-[9] lg:hidden">
      {({ open }) => (
        <div
          className={twMerge(
            'transition',
            open && 'fixed top-0 bottom-0 h-full w-full bg-mono-100 z-40',
          )}>
          <Popover.Button
            className={twMerge(
              'flex items-center justify-between w-full bg-fuji-800 text-mono-100 px-6 py-5 lg:hidden',
              !open && 'rounded-b-3xl',
            )}>
            <div className="flex items-center">
              <Text variant="body-16/r">{i18n.t('checkout.paymentUplift.title')}</Text>
              <Text variant="body-16/sb">
                {i18n.t('checkout.paymentUplift.price', { amount: totalPrice?.replace('$', '') })}
              </Text>
            </div>
            {open ? (
              <Assets.ChevronUp className="h-6 w-6 text-mono-100" />
            ) : (
              <Assets.ChevronDown className="h-6 w-6 text-mono-100" />
            )}
          </Popover.Button>
          <Transition
            as={React.Fragment}
            enter="transition-all"
            enterFrom="max-h-0 opacity-0"
            enterTo="max-h-full opacity-100"
            leave="transition-all"
            leaveFrom="max-h-full opacity-100"
            leaveTo="max-h-0 opacity-0">
            <Popover.Panel className="flex w-full h-[calc(100%_-_88px)] bg-mono-100">
              <div className="w-full overflow-y-auto">
                <div className="bg-fuji-800 px-1 h-screen">
                  <div className="w-full flex-auto rounded bg-mono-100 p-6 h-screen overflow-y-auto">
                    <SideBar onMount={handleSidebarExpanded} />
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
          {open && (
            <div className={twMerge('fixed bottom-0 w-full')}>
              <Popover.Button
                className={twMerge(
                  ' justify-center items-center bg-fuji-800 text-mono-100 w-full flex',
                )}>
                <Assets.ChevronUp className="h-6 w-6 text-mono-100" />
              </Popover.Button>
            </div>
          )}
        </div>
      )}
    </Popover>
  );
};
