import { closeModalSelector, openModalSelector, useModalStore } from '@store';

export const useEditDestinationsModal = () => {
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);

  const onNext = async () => {
    closeModal();
  };

  const openEditDestinationsModal = () =>
    openModal('Modal', {
      body: {
        type: 'SelectDestinations',
        isModal: true,
        onNext: onNext,
        onBack: closeModal,
      },
      size: 'lg',
      sendExitEvent: true,
    });

  return { openEditDestinationsModal };
};
