export const PaymentLoader: React.FC = () => (
  <div className="flex justify-center items-center">
    <svg
      className="animate-spin"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M65.1182 10.8162C70.2641 12.8015 74.9685 15.7809 78.9628 19.5843C82.9572 23.3877 86.1634 27.9406 88.3982 32.983C90.633 38.0255 91.8528 43.4588 91.9879 48.9726C92.1229 54.4865 91.1706 59.973 89.1853 65.1188C87.2 70.2646 84.2206 74.969 80.4172 78.9634C76.6138 82.9578 72.0609 86.164 67.0185 88.3988C61.976 90.6336 56.5427 91.8534 51.0289 91.9884C45.515 92.1235 40.0285 91.1712 34.8827 89.1859C29.7369 87.2006 25.0325 84.2212 21.0381 80.4178C17.0437 76.6144 13.8376 72.0615 11.6027 67.019C9.36788 61.9766 8.14811 56.5433 8.01306 51.0294C7.87801 45.5156 8.83032 40.0291 10.8156 34.8833C12.8009 29.7374 15.7803 25.033 19.5837 21.0386C23.3871 17.0443 27.94 13.8381 32.9825 11.6033C38.0249 9.36845 43.4582 8.14869 48.9721 8.01364C54.4859 7.87858 59.9724 8.83089 65.1182 10.8162L65.1182 10.8162Z"
        stroke="#D3DCE4"
        strokeWidth="16"
      />
      <path
        d="M65.1182 10.8162C70.2641 12.8015 74.9685 15.7809 78.9628 19.5843C82.9572 23.3877 86.1634 27.9406 88.3982 32.983C90.633 38.0255 91.8528 43.4588 91.9879 48.9726C92.1229 54.4865 91.1706 59.973 89.1853 65.1188C87.2 70.2646 84.2206 74.969 80.4172 78.9634C76.6138 82.9578 72.0609 86.164 67.0185 88.3988C61.976 90.6336 56.5427 91.8534 51.0289 91.9884C45.515 92.1235 40.0285 91.1712 34.8827 89.1859"
        stroke="#AAFFDE"
        strokeWidth="16"
        strokeLinecap="round"
      />
    </svg>
  </div>
);
