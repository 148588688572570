import React from 'react';

import { useRegionStore } from '@packages/stores';

import { useChooseRegion } from '@hooks';
import { i18n } from '@i18n';
import { testProps } from '@utils';

import { Text } from '../text';

export const RegionResidencyLabel: React.FC = () => {
  const { openRegionModal } = useChooseRegion();
  const country = useRegionStore(state => state?.region?.country);
  const regionResidencyLabel = i18n.t(
    country === 'AU' ? 'selectDestinations.residencyStatus' : 'stateOfResidence.residencyStatus',
  );
  return (
    <Text variant="body-16/r" className="pt-10 text-center">
      {regionResidencyLabel}
      <span
        {...testProps(`change-region-residency-modal-button-${regionResidencyLabel}`)}
        className="underline hover:cursor-pointer"
        onClick={() => openRegionModal()}>
        Change
      </span>
    </Text>
  );
};
