const DEFAULT_VERISK_USER_CONFIG = {
  $G_LeadTime: 0,
  $G_IsWinterSport: 0,
  $G_IsAnnual: 1,
  $G_DirectlyLinked: true,
  $G_IndirectlyLinked: false,
  $G_ShowClasses: '3,4,6',
  $G_ResultFormat: 'json',
  $G_RequestedExclusions: 'D66 G12.2 G82.5 G12.9 G12.2 G47.0 F44.9 G47.9 G47.1 T09.3',
} as const;

// BE will need same userConfig for redeclare without age to avoid duplications for different travellers
export const getVeriskScreeningParametersForRedeclare = (args: {
  tripDuration?: number;
  regionIds?: string;
  env?: string;
}) => {
  const veriskUsername =
    args.env === 'prod' ? 'RRT3FreelymeTravelProd' : ('RRT3FreelymeTravelUAT' as const);

  return {
    $G_TripDuration: args.tripDuration,
    $G_Regions: args.regionIds,
    $G_Username: veriskUsername,
    ...DEFAULT_VERISK_USER_CONFIG,
  } as const;
};

export const getVeriskUserConfig = (args: {
  age?: number;
  tripDuration?: number;
  regionIds?: string;
  env?: string;
}) => {
  return {
    $G_Age: args.age,
    ...getVeriskScreeningParametersForRedeclare(args),
  } as const;
};
