import { BOOST_CATEGORY } from '@packages/types';

import { BoostListItem, Text } from '@components';
import { i18n } from '@i18n';
import { getFilteredBoostsByCategorySelector, useTripStore } from '@store';

import { CoverPopover } from '../coverPopover/coverPopover.component';

export const DailyCoverSection = () => {
  const myActivitiesBoosts = useTripStore(
    getFilteredBoostsByCategorySelector(BOOST_CATEGORY.MY_ACTIVITIES),
  );
  const info = i18n.t('boostShared.info');

  const dailyCoverageText = i18n.t('myQuote.dailyCover.title');
  const items = dailyCoverageText?.split(/([()])/);
  const title = items?.[0].trim();
  const bracketText = items?.slice(1).join('');
  return (
    <>
      <div className="w-full my-6 border-t" />
      <div className="flex flex-row flex-wrap justify-between mb-2">
        <div>
          <Text
            variant={{
              sm: 'h4-24/sb',
              lg: 'h3-28/sb',
            }}
            className="pr-1 md:pr-2">
            {title}
          </Text>
          <Text
            variant={{
              sm: 'h4-24/m',
              lg: 'h4-24/r',
            }}>
            {bracketText}
          </Text>
        </div>
        <CoverPopover
          title={info}
          className="hidden md:block"
          boosts={myActivitiesBoosts}
        />
      </div>
      <Text variant={{ sm: 'body-16/r', lg: 'body-16/r' }} className="mb-6">
        {i18n.t('myQuote.dailyCover.subtitle')}
        <CoverPopover
          title={info}
          className="block md:hidden"
          boosts={myActivitiesBoosts}
        />
      </Text>
      {myActivitiesBoosts?.map(it => <BoostListItem boost={it} key={it.boostId} />)}
    </>
  );
};
