import { tv } from 'tailwind-variants';

import { Text, TextProps } from '@components';

import { Icon, IconName } from '../icon';

export type BadgeVariant =
  | 'info'
  | 'active'
  | 'warning'
  | 'error'
  | 'successful'
  | 'disabled'
  | 'merchandising'
  | 'recommended';

type BadgeProps = {
  variant: BadgeVariant;
  title: string;
  icon?: IconName;
} & Omit<TextProps, 'variant' | 'onClick'>;

const badgeStyle = tv({
  slots: {
    base: 'flex-row flex-wrap items-center px-s12 py-s2 flex rounded-full uppercase w-auto justify-start inline-flex gap-s4',
    iconStyle: 'text-base',
  },
  variants: {
    variant: {
      info: 'bg-surface-informative text-subtle',
      successful: 'bg-surface-success text-success',
      warning: 'bg-surface-warning text-warning',
      error: 'bg-surface-error text-error',
      active: 'bg-surface-brand-1 text-body',
      disabled: 'bg-surface-disabled text-body',
      merchandising: 'bg-surface-brand-5 text-dark-surface',
      recommended: 'bg-surface-brand-6 text-dark-surface',
    },
  },
});

export const Badge = ({ variant = 'info', title, icon, className, ...rest }: BadgeProps) => {
  const { base, iconStyle } = badgeStyle({ variant });
  return (
    <div className={base({ className })} {...rest}>
      {icon && <Icon name={icon} className={iconStyle()} />}
      <Text variant="subHeading-14/sb">{title}</Text>
    </div>
  );
};
