import { FormProvider, ValidationMode } from 'react-hook-form';

import { useGetQuoteForm } from '@packages/hooks';
import { GetQuoteForm } from '@packages/types';

type Props = {
  children: React.ReactNode;
  defaultFormValues?: GetQuoteForm;
  mode?: keyof ValidationMode;
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit' | undefined;
};
/**
 * The provider for the get quote form. This provider should be used in the get quote flow and wrapped the form input components.
 * @param children
 * @param defaultValues
 * @constructor
 */
export function GetQuoteProvider({ children, defaultFormValues, mode, reValidateMode }: Props) {
  const { methods } = useGetQuoteForm({ defaultFormValues, mode, reValidateMode });
  return <FormProvider {...methods}>{children}</FormProvider>;
}
