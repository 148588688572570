import { ButtonHTMLAttributes, forwardRef } from 'react';

import { Text } from '@elements/text';

type TextLinkProps = {
  title: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

export const TextLink = forwardRef<HTMLButtonElement, TextLinkProps>(
  ({ title, className, ...rest }, ref) => {
    return (
      <button ref={ref} className={className} {...rest}>
        <Text className="text-link-default hover:text-link-hover active:text-link-active underline">
          {title}
        </Text>
      </button>
    );
  },
);

TextLink.displayName = 'TextLink';
