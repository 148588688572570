import React, { useCallback, useMemo } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

import { isAURegionSelector, useRegionStore } from '@packages/stores';
import { AddressComponent, GuestDetailsForm, Region } from '@packages/types';
import {
  getNeighborhoodAddressComponent,
  hasAddressLine1,
  hasLocality,
  hasNeighborHoodOrLocality,
  hasPostalCode,
  hasState,
} from '@packages/utils';

import { ManualAddress, Text } from '@components';
import { Dropdown } from '@elements/dropdown';
import { i18n } from '@i18n';
import {
  isManualAddressSectionOpenSelector,
  setIsManualAddressSectionSelector,
  useCheckoutStore,
} from '@store';
import { sendAnalyticsEvent, testProps, webEnv } from '@utils';

import { formAnalyticEvent } from './primaryTravellerForm.component';

type Props = {
  region: Region;
};

export const AddressInput: React.FC<Props> = ({ region }) => {
  const isManualAddressSectionOpen = useCheckoutStore(isManualAddressSectionOpenSelector);
  const setIsManualAddressSectionOpen = useCheckoutStore(setIsManualAddressSectionSelector);
  const isAU = useRegionStore(isAURegionSelector);
  const { setValue, control, clearErrors, watch } = useFormContext<GuestDetailsForm>();

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: webEnv.VITE_GOOGLE_MAPS_API_KEY,
      options: {
        types: ['address'],
        componentRestrictions: { country: region.country },
      },
    });

  const placePredictionOptions = useMemo(
    () =>
      placePredictions
        ?.filter(p => {
          if (region?.state) {
            return p.structured_formatting?.secondary_text?.includes(region?.state);
          }
          return true;
        })
        .map(it => {
          return {
            id: it.place_id,
            label: it.description,
            value: it.place_id,
          };
        }),
    [placePredictions, region?.state],
  );

  const openManualAddressSection = useCallback(() => {
    sendAnalyticsEvent('Manual Street Address Modal Opened');
    setIsManualAddressSectionOpen(true);
  }, [setIsManualAddressSectionOpen]);

  const onAddressSelect = useCallback(
    (item: unknown) => {
      if (!item) {
        return;
      }
      placesService?.getDetails(
        {
          placeId: item,
        },
        (place: { address_components?: AddressComponent[]; formatted_address?: string }) => {
          const { address_components, formatted_address } = place;
          if (!address_components || !formatted_address) {
            return openManualAddressSection();
          }
          const includesNeighborhood = hasNeighborHoodOrLocality(address_components);
          const includesLocality = hasLocality(address_components);

          //Use neighborhood if available as locality when locality is not available
          if (includesNeighborhood && !includesLocality) {
            const neighborhood = getNeighborhoodAddressComponent(address_components);
            address_components.push({
              long_name: neighborhood?.long_name ?? '',
              short_name: neighborhood?.short_name ?? '',
              types: ['locality'],
            });
          }
          setValue(
            'addressData',
            {
              data: { description: formatted_address },
              detail: {
                address_components: address_components,
              },
            },
            { shouldValidate: false },
          );
          clearErrors('addressData');
          /**
           * If the address line 1 or postal code or city is not found, set the partial address
           * and show manual address form pre filled except postal code.
           */
          if (
            (region?.country === 'US' && !hasAddressLine1(address_components)) ||
            !hasState(address_components) ||
            !hasPostalCode(address_components) ||
            !(includesLocality || includesNeighborhood)
          ) {
            const partialAddress = {
              data: { description: formatted_address },
              detail: {
                address_components: address_components,
              },
            };
            const addressLine1 = partialAddress?.data?.description?.split(',')?.[0];
            const addressLine2 = partialAddress?.data?.description?.split(',')?.[1];
            const addressCity =
              partialAddress?.detail?.address_components?.find(i => i.types.includes('locality'))
                ?.long_name ?? '';
            const addressPostCode =
              partialAddress?.detail?.address_components?.find(i => i.types.includes('postal_code'))
                ?.long_name ?? '';
            const addressState =
              region?.state ??
              partialAddress?.detail?.address_components?.find(i =>
                i.types.includes('administrative_area_level_1'),
              )?.short_name ??
              '';
            setValue('addressLine1', addressLine1);
            setValue('addressLine2', addressLine2);
            setValue('addressCity', addressCity);
            setValue('addressPostCode', addressPostCode);
            setValue('addressState', addressState, { shouldValidate: false });
            setValue('addressData', undefined);
            return openManualAddressSection();
          }
        },
      );
    },
    [
      clearErrors,
      openManualAddressSection,
      placesService,
      region?.country,
      region?.state,
      setValue,
    ],
  );

  const selectPlaceholder = i18n.t('checkout.addressInput.select.placeholder');
  return (
    <>
      {!isManualAddressSectionOpen && (
        <Controller
          control={control}
          name="addressData"
          rules={{
            validate: {
              validateStreetAddress: value =>
                (!isManualAddressSectionOpen && !!value?.data?.description) ||
                'Street address is required.',
              validatePostalCode: value => {
                return (
                  (!isManualAddressSectionOpen &&
                    hasPostalCode(value?.detail?.address_components)) ||
                  'Postal Code is required.'
                );
              },
              validateLocality: value =>
                (!isManualAddressSectionOpen &&
                  hasNeighborHoodOrLocality(value?.detail?.address_components)) ||
                'City is required.',
              validateState: value =>
                (!isManualAddressSectionOpen && hasState(value?.detail?.address_components)) ||
                'State is required.',
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Dropdown
                optionsHeader={
                  <Text
                    variant="body-16/r"
                    onClick={() => {
                      setValue('addressLine1', '', { shouldValidate: false });
                      setValue('addressLine2', '', { shouldValidate: false });
                      setValue('addressCity', '', { shouldValidate: false });
                      setValue('addressPostCode', '', { shouldValidate: false });
                      isAU && setValue('addressState', '', { shouldValidate: false });
                      setValue('addressData', undefined, { shouldValidate: false });
                      sendAnalyticsEvent('Manual Street Address Modal Opened');
                      setIsManualAddressSectionOpen(true);
                    }}
                    className="underline cursor-pointer mb-s12"
                    {...testProps('link-add-mannual')}>
                    {i18n.t('checkout.addressInput.description')}
                  </Text>
                }
                onBlur={() => {
                  if (error && !!watch('addressData')) {
                    formAnalyticEvent('addressData');
                  }
                }}
                initialValue={{
                  id: v4(),
                  label: watch('addressData.data.description') ?? '',
                  value: value,
                }}
                className="mb-4"
                items={placePredictionOptions}
                onSelect={onAddressSelect}
                inputProps={{
                  label: i18n.t('checkout.addressInput.select.label'),
                  errorMessage: error?.message,
                  placeholder: selectPlaceholder,
                  ...testProps('street-address'),
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event);
                    getPlacePredictions({ input: event.target?.value });
                  },
                }}
                isLoading={isPlacePredictionsLoading}
                {...testProps('street-address')}
              />
            );
          }}
        />
      )}
      {isManualAddressSectionOpen && <ManualAddress />}
    </>
  );
};
