import { DateTime, Duration, DurationLikeObject } from 'luxon';

import { AGE_BAND_RANGES, REGIONS } from '@packages/constants';
import { MarkedDates, Region, RegionCode } from '@packages/types';

/**
 * date format DD/MM/YYYY or MM/DD/YYYY to YYYY-MM-DD
 * @param date - DD/MM/YYYY or MM/DD/YYYY
 * @param region - e.g. AU | US
 */
export const dateToUTCFormat = (date: string, region: RegionCode = 'AU') => {
  let DD;
  let MM;
  let YYYY;
  if (region === 'US') {
    [MM, DD, YYYY] = date.split('/');
  } else {
    [DD, MM, YYYY] = date.split('/');
  }
  return [YYYY, MM, DD].join('-');
};

/**
 * Convert a UTC date string into DD/MM/YYYY format
 * @param utcDate - YYYY-MM-DD
 * @param region - e.g. AU | US
 */
export const utcToDateFormat = (utcDate: string, region: RegionCode = 'AU') => {
  // invalid format
  if (!utcDate) {
    return;
  }
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (utcDate.match(regex) === null) {
    return;
  }
  const [YYYY, MM, DD] = utcDate.split('-');
  if (region === 'US') {
    return [MM, DD, YYYY].join('/');
  }
  return [DD, MM, YYYY].join('/');
};

export const getAgeBand = (age: number) => {
  const ageBand = AGE_BAND_RANGES.find(range => age >= range.min && age <= range.max);
  return ageBand ? ageBand.band : '';
};

export const ZONE = {
  AU: 'Australia/Sydney',
  US: 'America/Chicago',
};

/**
 * Returns the current date and time in the specified region.
 * @param regionCode e.g. AU, US
 * @returns the current date in the region
 */
export const getRegionDateTime = (regionCode: RegionCode | undefined) => {
  if (regionCode) {
    return DateTime.local().setZone(ZONE[regionCode], { keepLocalTime: false });
  }

  return DateTime.local();
};

/**
 * Parse ISO date string to Date object
 * @param date ISO string e.g. 2020-12-19T01:00:00.000Z
 * @param regionCode Region code e.g. AU | US
 * @returns Date object
 */
export const parseISO = (date: string, regionCode: RegionCode | undefined) => {
  const regionDateTime = getRegionDateTime(regionCode);
  return DateTime.fromISO(date, { zone: regionDateTime.zone, setZone: true });
};

/**
 * @param date Date object
 * @returns formatted date string e.g. 2020-12-19
 */
export const getUTCDate = (date: DateTime) => {
  return date.toFormat('yyyy-MM-dd');
};

/**
 *
 * @param date Date object
 * @param regionCode e.g. AU, US
 * @returns formatted date string e.g. 2020-12-19
 */
export const getUTCDateFromJSDate = (date: Date, regionCode: RegionCode | undefined) => {
  const regionDateTime = getRegionDateTime(regionCode);
  return DateTime.fromObject(
    { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() },
    { zone: regionDateTime.zone },
  ).toFormat('yyyy-MM-dd');
};

/**
 *
 * @param date Date object
 * @param regionCode e.g. AU, US
 * @returns
 */
export const getJSDate = (date: string | DateTime, regionCode: RegionCode | undefined) => {
  if (typeof date === 'string') {
    date = parseISO(date, regionCode);
  }

  const dateObject = date.toObject();
  return new Date(dateObject.year, dateObject.month - 1, dateObject.day);
};

/**
 *
 * @param isoDate ISO string e.g. 2020-12-19T01:00:00.000Z or Date object
 * @param regionCode
 * @returns Month of date
 */
export const getMonthName = (isoDate: string | DateTime, regionCode: RegionCode | undefined) => {
  if (typeof isoDate === 'string') {
    isoDate = parseISO(isoDate, regionCode);
    if (!isoDate.isValid) {
      return 'Invalid Date';
    }
  }
  return isoDate.toFormat('MMMM');
};

export const isInPast = (dateTime: DateTime | string, regionCode: RegionCode | undefined) => {
  if (!dateTime) {
    return false;
  }

  if (typeof dateTime === 'string') {
    dateTime = parseISO(dateTime, regionCode);
  }
  return dateTime.startOf('day') < getRegionDateTime(regionCode).startOf('day');
};

export const isInPastOrToday = (
  dateTime: DateTime | string,
  regionCode: RegionCode | undefined,
) => {
  if (!regionCode || !dateTime) {
    return false;
  }
  if (typeof dateTime === 'string') {
    dateTime = parseISO(dateTime, regionCode);
  }
  return dateTime.startOf('day') <= getRegionDateTime(regionCode).startOf('day');
};

export const getFullDateStr = (
  dateTime: string | DateTime,
  regionCode?: RegionCode,
  options?: { ordinal?: boolean; fullMonth?: boolean; dayString?: string },
) => {
  if (typeof dateTime === 'string') {
    dateTime = parseISO(dateTime, regionCode);
    if (!dateTime.isValid) {
      console.warn('Invalid date', { dateTime, reason: dateTime.invalidReason });
      return 'Invalid Date';
    }
  }
  const dayString = options?.dayString ?? 'd';
  const monthString = options?.fullMonth ? 'MMMM' : 'MMM';
  if (regionCode === REGIONS.US.code) {
    return dateTime.toFormat(`${monthString} ${dayString} yyyy`);
  }
  return dateTime.toFormat(`${dayString} ${monthString} yyyy`);
};

export const getFullDateStrFromMilliSeconds = (
  milliSeconds: number,
  regionCode?: RegionCode,
  options?: { ordinal?: boolean; fullMonth?: boolean; dayString?: string },
) => getFullDateStr(DateTime.fromMillis(milliSeconds), regionCode, options);

export const getAge = (dateTime: DateTime | string, regionCode: RegionCode | undefined) => {
  if (!dateTime || !regionCode) {
    console.warn('getAge() => Invalid date', { dateTime, reason: 'undefined or null' });
    return 0;
  }
  if (typeof dateTime === 'string') {
    dateTime = parseISO(dateTime, regionCode);
  }
  if (!dateTime.isValid) {
    console.warn('getAge() => Invalid date', { dateTime, reason: dateTime.invalidReason });
    return 0;
  }
  const end = getRegionDateTime(regionCode);
  return Math.floor(end.diff(dateTime, 'years').years);
};

/**
 * Returns the difference in days between two dates
 * e.g Same day will return 0
 * Monday, Tuesday after will return 1
 */
export const getDateDifferenceInDays = (
  start: DateTime | string,
  end: DateTime | string,
  country: RegionCode | undefined,
) => {
  if (start && end && country) {
    const startDate = typeof start === 'string' ? parseISO(start, country).startOf('day') : start;
    const endDate = typeof end === 'string' ? parseISO(end, country).startOf('day') : end;
    return Math.floor(endDate.diff(startDate, 'days').days);
  }
  return 0;
};

export const getDateDifferenceInWeeks = (
  start: DateTime | string,
  end: DateTime | string,
  country: RegionCode | undefined,
) => {
  if (start && end && country) {
    const startDate = typeof start === 'string' ? parseISO(start, country).startOf('day') : start;
    const endDate = typeof end === 'string' ? parseISO(end, country).startOf('day') : end;
    return endDate.diff(startDate, 'week').weeks;
  }
  return 0;
};

/**
 * Returns the duration of dates in days
 * e.g. Same day will return 1
 * Monday, Tuesday after will return 2
 */
export const getDurationOfDatesInDays = (
  start: DateTime | string,
  end: DateTime | string,
  country: RegionCode | undefined,
) => getDateDifferenceInDays(start, end, country) + 1;

export const getDurationOfRangesInDays = (
  ranges: { startDate: DateTime | string; endDate: DateTime | string }[],
  country: RegionCode | undefined,
): number => {
  const _ranges = ranges.filter(range => !!range);

  if (!_ranges || _ranges.length === 0 || Array.isArray(_ranges) === false) {
    return 0; // Return 0 if the array is empty
  }

  let totalDuration = 0;

  for (const range of _ranges) {
    totalDuration += getDateDifferenceInDays(range.startDate, range.endDate, country) + 1;
  }

  return totalDuration;
};

export const getRestrictedMarkDates = (
  numberOfRestrictiveDays: number,
  country: RegionCode = REGIONS.AU.code,
  bgColor: string,
  textColor: string,
): MarkedDates => {
  const markedDates: MarkedDates = {};

  if (numberOfRestrictiveDays === 0) {
    return {};
  }
  const startDate = getRegionDateTime(country);
  const calendarStartDate = startDate;
  const numberOfRestrictiveDaysDuration = Duration.fromObject({ days: numberOfRestrictiveDays });
  const oneDayDuration = Duration.fromObject({ days: 1 });
  const endDate = startDate.plus(numberOfRestrictiveDaysDuration);
  const endingDayDate = startDate.plus(numberOfRestrictiveDaysDuration).minus(oneDayDuration);

  for (let dt: DateTime = calendarStartDate; dt < endDate; dt = dt.plus(oneDayDuration)) {
    const formattedDate = getUTCDate(dt);
    if (!markedDates[formattedDate]) {
      markedDates[formattedDate] = {
        color: bgColor,
        textColor: textColor,
        startingDay: getUTCDate(startDate) === getUTCDate(dt),
        endingDay: getUTCDate(dt) === getUTCDate(endingDayDate),
        selected: false,
      };
    }
  }
  return markedDates;
};

export const getHighlightedDates = (
  startDateStr: string,
  endDateStr: string,
  country: RegionCode = REGIONS.AU.code,
  markedColor: string,
): MarkedDates => {
  const markedDates: MarkedDates = {};
  const startDate = parseISO(startDateStr, country);
  const endDate = parseISO(endDateStr, country);
  const endingDayDate = parseISO(endDateStr, country);
  const oneDayDuration = Duration.fromObject({ days: 1 });

  for (
    let dt: DateTime = parseISO(startDateStr, country);
    dt <= endDate;
    dt = dt.plus(oneDayDuration)
  ) {
    const formattedDate = getUTCDate(dt);
    if (!markedDates[formattedDate]) {
      markedDates[formattedDate] = {
        color: markedColor,
        startingDay: getUTCDate(startDate) === getUTCDate(dt),
        endingDay: getUTCDate(endingDayDate) === getUTCDate(dt),
        selected: false,
      };
    }
  }
  return markedDates;
};

export const sortDateString = (dateString1: string, dateString2: string) => {
  // Convert string dates into `Date` objects
  const date1 = DateTime.fromISO(dateString1).startOf('day').valueOf();
  const date2 = DateTime.fromISO(dateString2).startOf('day').valueOf();

  return date1 - date2;
};

export const isTripStarted = (startDate: string, country: RegionCode | undefined) => {
  const _startDate = parseISO(startDate, country).startOf('day');
  const now = getRegionDateTime(country).startOf('day');
  if (!_startDate.isValid) {
    return false;
  }
  return _startDate <= now;
};

export const isInFutureOrTodayOrYesterday = (
  isoDateStr: string,
  country: RegionCode | undefined,
) => {
  const dt = parseISO(isoDateStr, country).startOf('day');
  if (!dt.isValid) {
    return false;
  }
  const dayBeforeYesterdayFromToday = getRegionDateTime(country).minus({ days: 2 }).startOf('day');
  return dt >= dayBeforeYesterdayFromToday;
};

export const getDateStr = (isoDateStr: string, country: RegionCode | undefined) => {
  if (!isoDateStr) {
    return '';
  }
  const dt = parseISO(isoDateStr, country);
  if (!dt.isValid) {
    return 'Invalid Date';
  }
  return getUTCDate(dt);
};

export const consolidateDateRanges = (
  dateRanges: { startDate: string; endDate: string }[],
  region: Region | undefined | null,
) => {
  const _ranges = dateRanges.filter(dateRanges => !!dateRanges);

  if (!_ranges || _ranges.length === 0 || Array.isArray(_ranges) === false) {
    return []; // Return [] if the array is empty
  }

  const sortedRanges = [...dateRanges].sort((a, b) =>
    parseISO(a.startDate, region?.country) > parseISO(b.startDate, region?.country) ? 1 : -1,
  );

  const consolidatedRanges = [];
  const defaultRange = { startDate: '', endDate: '' };

  let currentRange = sortedRanges[0] ?? defaultRange;

  for (let i = 1; i < sortedRanges.length; i++) {
    const nextRange = sortedRanges[i] ?? defaultRange;
    const currentEndDate = parseISO(currentRange.endDate, region?.country);
    const nextStartDate = parseISO(nextRange.startDate, region?.country);
    const nextEndDate = parseISO(nextRange.endDate, region?.country);

    if (currentEndDate >= nextStartDate || currentEndDate >= nextEndDate) {
      // If the ranges overlap or current range engulfs the next range, update the end date of the current range
      if (currentEndDate < nextEndDate) {
        currentRange.endDate = nextEndDate.toISODate();
      }
    } else if (currentEndDate.plus({ days: 1 }).equals(nextStartDate)) {
      // If the next range starts exactly one day after the end of the current range, update the end date of the current range
      const newEndDate = nextEndDate.toISODate();
      const temp = { ...currentRange };
      temp.endDate = newEndDate;
      currentRange = temp;
    } else {
      // If the ranges do not overlap or form a consecutive range, add the current range to the consolidated list and update the current range to the next range
      consolidatedRanges.push(currentRange);
      currentRange = nextRange;
    }
  }

  // Add the last range to the consolidated list
  consolidatedRanges.push(currentRange);

  return consolidatedRanges;
};

export const deconsolidateDateRanges = (
  dateRanges: { startDate: string; endDate: string }[],
  country: RegionCode | undefined,
): { startDate: string; endDate: string }[] => {
  if (!dateRanges || dateRanges.length === 0) {
    return [];
  }

  const deconsolidateRanges: { startDate: string; endDate: string }[] = [];

  const today = getRegionDateTime(country);

  for (const range of dateRanges) {
    const startDate = parseISO(range.startDate, country);
    const endDate = parseISO(range.endDate, country);

    if (startDate <= today && endDate >= today) {
      // If the range contains today's date, split it into two separate ranges
      const firstRange: { startDate: string; endDate: string } = {
        startDate: startDate.toISODate(),
        endDate: today.toISODate(),
      };
      const secondRange: { startDate: string; endDate: string }[] = [
        {
          startDate: today.plus({ days: 1 }).toISODate(),
          endDate: endDate.toISODate(),
        },
      ];
      deconsolidateRanges.push(firstRange, ...secondRange);
    } else {
      // If the range is entirely in the past, add it as it is
      deconsolidateRanges.push(range);
    }
  }

  return deconsolidateRanges;
};

export const sortDateRangesInAscendingOrder = (
  dateRange: { startDate: string; endDate: string }[],
) => {
  // Create a copy of the startEndDates array and sort it in ascending order based on the startDate
  const sortStartEndDates = [...dateRange].sort(
    (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime(),
  );
  return sortStartEndDates;
};

/**
 * converts a unit of time into months
 * @param durationObject
 */
export const getDurationInMonths = (durationObject: DurationLikeObject) =>
  Math.floor(Duration.fromObject(durationObject).as('months'));

/**
 * Constructs the users selected dates
 * @param startDate
 * @param endDate
 * @param region
 */
export function constructMarkedDates({
  startDate,
  endDate,
  region,
}: {
  startDate: string;
  endDate: string;
  region: Region | undefined;
}) {
  const markedDates = {} as MarkedDates;

  markedDates[startDate] = { startingDay: true, color: 'bg-mint-100' };
  const range = getDateDifferenceInDays(startDate, endDate, region?.country);

  for (let i = 1; i <= range; i++) {
    const dayDuration = Duration.fromObject({ days: i });
    const date = parseISO(startDate, region?.country).plus(dayDuration);
    const dayToIncrement = getUTCDate(date);
    if (i < range) {
      markedDates[dayToIncrement] = { color: 'bg-mint-100' };
    } else {
      markedDates[dayToIncrement] = { endingDay: true, color: 'bg-mint-100' };
    }
  }
  return markedDates;
}

export function getDateByTimeStamp({
  timeStamp,
  regionCode = 'AU',
}: {
  timeStamp: number;
  regionCode?: 'AU' | 'US';
}) {
  const format = regionCode === 'AU' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  return DateTime.fromMillis(timeStamp).toFormat(format);
}

export function formatRelativeDate(postDateTimestamp: number, regionCode: RegionCode) {
  const postDate = DateTime.fromMillis(postDateTimestamp, { zone: ZONE[regionCode] });
  return postDate.toRelative();
}
