import { TripCostEditor } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';

export const TripCost = () => {
  const handleNext = () => router.navigate(ROUTE_PATHS().tripDepositDate);
  return (
    <main>
      <TripCostEditor onNext={handleNext} onBack={() => router.navigate(-1)} />
    </main>
  );
};
