import React from 'react';

import { Button } from '@elements/button';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { router } from '@routes/router';
import { testProps } from '@utils';

const BoostTopHeader: React.FC = () => {
  const { openRedirectToFreelyModal } = useNavigateToFreely();

  return (
    <header className="bg-cabo-50">
      <div className="mx-auto max-w-screen-xl flex items-center justify-between py-2 sm:px-4 md:px-8">
        <Button
          {...testProps('back-to-trip-extras')}
          variant="tertiary"
          title={i18n.t('global.actions.back')}
          iconLeft="arrow_back"
          onClick={() => router.navigate(-1)}
        />

        <Button
          className="[&>svg]:h-10 [&>svg]:w-20 lg:[&>svg]:h-12 lg:[&>svg]:w-24 mr-4 [&>svg]:text-fuji-800"
          icon="FreelyPrime"
          variant="tertiary"
          onClick={openRedirectToFreelyModal}
        />
      </div>
    </header>
  );
};

export default BoostTopHeader;
