import { getImageUrl, isUnderMaintenance } from '@packages/utils';

import { ConfigState } from './config.store';

export const getMaintenanceStatus = (state: ConfigState): boolean =>
  (!!state.regionSpecificConfig?.FREELY_OUTAGE_WINDOW &&
    isUnderMaintenance(state.regionSpecificConfig.FREELY_OUTAGE_WINDOW)) ||
  (!!state.regionSpecificConfig?.OUTAGE_WINDOW &&
    isUnderMaintenance(state.regionSpecificConfig.OUTAGE_WINDOW));

export const isAddTripEnabledSelector = (state: ConfigState) => {
  const addTripConfig = state?.regionSpecificConfig?.FEATURE_TOGGLE?.addTrip;
  if (addTripConfig && 'enabled' in addTripConfig) {
    // Check for the feature flag once config has loaded
    return !!addTripConfig.enabled;
  }
  // Return true by default if the config hasn't loaded yet
  // This is to prevent the stop sell screen from flickering on browser refresh
  return true;
};

export const protectionRestrictionDaysSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES?.PROTECTION_RESTRICTION_DAYS;

export const minDateOffsetDaysSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES?.MIN_DATE_OFFSET_DAYS;

export const maxTripCalendarDaysSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES?.MAX_TRIP_CALENDAR_DAYS;

export const maxTripCalendarMonthsSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES?.MAX_TRIP_CALENDAR_MONTHS;

export const getIconUrlSelector = (state: ConfigState) => (imagePath: string) => {
  if (!state?.config?.S3_ASSETS_BUCKET) return;
  return getImageUrl(state?.config?.S3_ASSETS_BUCKET, imagePath);
};

export const preCancellationBenefitsSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES?.PRE_CANCELLATION_BENEFITS;

export const departureDaysOffsetSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES?.PRE_CANCELLATION_BENEFITS
    ?.MAX_DEPARTURE_DAYS_OFFSET_FROM_TODAY;

export const getCoolingOffPeriodDaysSelector = (state: ConfigState) => (stateCode: string) => {
  return (
    state?.regionSpecificConfig?.RULES?.EXCEPTIONS?.[stateCode]?.COOL_OFF_PERIOD_DAYS ??
    state?.regionSpecificConfig?.RULES?.COOL_OFF_PERIOD_DAYS ??
    21
  );
};
