import { useEffect, useRef, useState } from 'react';

export const useClickOutsideHandler = () => {
  const [openModal, setOpenModal] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (nodeRef && nodeRef.current && !nodeRef.current.contains(event.target as Node)) {
        setOpenModal(false);
      }
    };

    if (openModal) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [openModal, setOpenModal]);
  return { openModal, setOpenModal, nodeRef };
};
