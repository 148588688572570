export type ModalBodyContainerProps = {
  icon?: React.ReactNode;
  header?: React.ReactNode;
  description?: React.ReactNode;
  buttons?: React.ReactNode;
};

export const ModalBodyContainer: React.FC<ModalBodyContainerProps> = ({
  icon,
  header,
  description,
  buttons,
}) => {
  return (
    <div className="flex flex-col gap-5 items-center max-w-lg text-center">
      {icon}
      {header}
      {description}
      {buttons}
    </div>
  );
};
