import React from 'react';

import { FullScreenModal } from '@components';
import { Transition } from '@headlessui/react';
import { _ModalConfig, useModalStore } from '@store/modal';

import { Modal } from '../modal';
import { RegionGrid } from '../regionGrid';

export const ModalNavigator: React.FC = () => {
  const modals = useModalStore(state => state.modals);

  //recursive render modals function top open multiple modal at once
  const renderModals = (modals: _ModalConfig[], currentIndex: number, lastIndex: number) => {
    switch (modals[currentIndex].modalName) {
      case 'Modal':
        return (
          <Modal
            key={`${modals[currentIndex].modalName}_${currentIndex}`}
            isOpen
            {...modals[currentIndex].params}>
            {currentIndex < lastIndex && renderModals(modals, currentIndex + 1, lastIndex)}
          </Modal>
        );
      case 'RegionGridModal':
        return (
          <Modal
            key={`${modals[currentIndex].modalName}`}
            isOpen
            body={<RegionGrid />}
            {...modals[currentIndex].params}></Modal>
        );
      case 'FullScreenModal':
        return (
          <FullScreenModal
            key={`${modals[currentIndex].modalName}_${currentIndex}`}
            isOpen
            {...modals[currentIndex].params}>
            {currentIndex < lastIndex && renderModals(modals, currentIndex + 1, lastIndex)}
          </FullScreenModal>
        );
    }
    return null;
  };

  if (modals.length === 0) {
    return null;
  }

  return (
    <Transition.Root show={modals.length > 0}>
      {renderModals(modals, 0, modals.length - 1)}
    </Transition.Root>
  );
};
