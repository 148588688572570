import { mountStoreDevtool } from 'simple-zustand-devtools';
import { StateCreator, StoreApi, create } from 'zustand';
import { createJSONStorage, persist, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { logger } from './logger';
import { storeEnv } from './storeSchema';

const __DEV__ = process.env.NODE_ENV === 'development';

type StoreOptions = {
  name: string;
  shouldPersist?: boolean;
  persistType?: 'localStorage' | 'sessionStorage';
};

export type Middleware<T> = (
  config: CreateStore<T>,
) => (set: StoreApi<T>['setState'], get: StoreApi<T>['getState'], api: StoreApi<T>) => T;

export type CreateStore<T> = StateCreator<
  T,
  [['zustand/immer', never], ['zustand/persist', unknown]]
>;

export type SetStore<T> = (
  nextStateOrUpdater: T | Partial<T> | ((state: T) => void),
  shouldReplace?: boolean | undefined,
) => void;

export type GetStore<T> = () => T;

export const createStore = <T>(
  store: CreateStore<T>,
  { name, shouldPersist, persistType }: StoreOptions,
) => {
  const storeWithPersist =
    (storeEnv.VITE_ZUSTAND_PERSIST_STATE === 'true' && shouldPersist !== false) || persistType
      ? persist(store as StateCreator<T, [['zustand/persist', unknown]], []>, {
          name,
          storage: createJSONStorage(() =>
            persistType === 'localStorage' ? localStorage : sessionStorage,
          ),
          onRehydrateStorage: () => {
            return (_, error) => {
              if (error) {
                // if the migration fails, we will automatically reset the state
                // no need to run any further logic
                return;
              }
            };
          },
        })
      : store;

  const shouldEnableLoggerAndPersist =
    storeEnv.VITE_ZUSTAND_PERSIST_STATE === 'true' && storeEnv.VITE_ZUSTAND_LOGGER === 'true';

  const storeWithLoggerAndPersist = shouldEnableLoggerAndPersist
    ? logger(storeWithPersist as unknown as StateCreator<T, [['zustand/immer', never]]>, name)
    : storeWithPersist;

  const createdStore = create(
    subscribeWithSelector(
      immer(storeWithLoggerAndPersist as unknown as StateCreator<T, [['zustand/immer', never]]>),
    ),
  );
  if (shouldEnableLoggerAndPersist || __DEV__) {
    mountStoreDevtool(name, createdStore);
  }
  return createdStore;
};
