import { twMerge } from 'tailwind-merge';

import { Icon } from '@elements/icon';
import { i18n } from '@i18n';

import { Text } from '../text';

type DateSelectedProps = {
  variant: 'departing' | 'returning';
  dateText: string | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

export const DateSelected = ({ variant, dateText, className, ...rest }: DateSelectedProps) => {
  const renderIcon =
    variant === 'departing' ? (
      <Icon size="lg" name="flight_takeoff" className="m-2 hidden sm:flex" variant="secondary" />
    ) : (
      <Icon size="lg" name="flight_land" className="m-2 hidden sm:flex" variant="secondary" />
    );
  const label =
    variant === 'departing'
      ? i18n.t('travelDates.dateSelected.departure')
      : i18n.t('travelDates.dateSelected.return');
  const subTitleText = dateText ? dateText : i18n.t('travelDates.dateSelected.notSelected');
  const subTitleStyle = dateText ? 'text-fuji-800' : 'text-fuji-300';

  return (
    <div
      {...rest}
      className={twMerge('flex p-2 flex-row items-center rounded-lg border', className)}>
      {renderIcon}
      <div>
        <Text variant="footnote-12/r">{label}</Text>
        <Text variant={{ sm: 'subHeading-14/sb', lg: 'body-16/sb' }} className={subTitleStyle}>
          {subTitleText}
        </Text>
      </div>
    </div>
  );
};
