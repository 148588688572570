import { tv } from 'tailwind-variants';

import { testProps } from '@utils';

import { Icon, IconName } from '../icon/icon.component';
// TYPES
import { Text } from '../text/text.component';
import { TextWithMdProps } from '../text/textWithMd/textWithMd.component';

export type NotificationVariant = 'success' | 'error' | 'warning' | 'info';

type NotificationProps = {
  variant?: NotificationVariant;
  header: string | ((props: Partial<TextWithMdProps>) => React.ReactNode);
  body?: string | ((props: Partial<TextWithMdProps>) => React.ReactNode);
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  iconName?: IconName;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

interface NotificationIconProps {
  variant: NotificationVariant;
  iconName?: IconName;
}

// STYLES

const notificationStyles = tv({
  slots: {
    base: 'p-s12 flex items-start rounded-xl text-start',
    iconWrapper: 'pr-s8',
    title: 'text-heading',
    description: 'pt-s4 text-body',
  },
  variants: {
    color: {
      info: 'bg-surface-informative',
      success: 'bg-surface-success',
      error: 'bg-surface-error',
      warning: 'bg-surface-warning',
    },
  },
  defaultVariants: {
    color: 'info',
  },
});

const notificationIconStyles = tv({
  base: 'text-[1.25rem]',
  variants: {
    icon: {
      info: 'text-icon-brand-2',
      success: 'text-icon-success',
      error: 'text-icon-error',
      warning: 'text-icon-warning',
    },
  },
  defaultVariants: {
    icon: 'info',
  },
});

// COMPONENT

const iconNameMap: Record<NotificationVariant, IconName> = {
  info: 'info_filled',
  success: 'check_circle_filled',
  error: 'error_filled',
  warning: 'error_filled',
};

const NotificationIcon = ({ variant, iconName }: NotificationIconProps) => {
  const iconClassName = notificationIconStyles({ icon: variant });
  const icon = iconName ?? iconNameMap[variant];

  return <Icon name={icon} className={iconClassName} />;
};

export const Notification = ({
  variant = 'info',
  header,
  body,
  className,
  iconName,
  ...rest
}: NotificationProps) => {
  const { base, description, title, iconWrapper } = notificationStyles({
    color: variant,
  });

  const titleText = (
    <>
      {typeof header === 'string' ? (
        <Text className={title()} variant="subHeading-14/sb" {...testProps('notification-title')}>
          {header}
        </Text>
      ) : null}
      {typeof header === 'function'
        ? header({
            variant: 'subHeading-14/sb',
            className: title(),
          })
        : null}
    </>
  );

  const bodyText = (
    <>
      {typeof body === 'string' ? (
        <Text
          className={description()}
          variant="subHeading-14/r"
          {...testProps('notification-body')}>
          {body}
        </Text>
      ) : null}
      {typeof body === 'function'
        ? body({
            variant: 'subHeading-14/r',
            className: description(),
          })
        : null}
    </>
  );

  return (
    <div className={base({ className })} {...rest}>
      <div className={iconWrapper()}>
        <NotificationIcon variant={variant} iconName={iconName} />
      </div>
      <div>
        {titleText}
        {bodyText}
      </div>
    </div>
  );
};
