import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  getCoolingOffPeriodDaysSelector,
  getRegionSelector,
  isAURegionSelector,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import { getDestinationsLabel, getFullDateStr } from '@packages/utils';

import { Assets } from '@assets';
import { Container } from '@components/container';
import { Badge, BadgeVariant } from '@elements/badge';
import { i18n } from '@i18n';
import { getDestinationBackgroundImagesSelector, getTripSelector, useTripStore } from '@store';
import { testProps } from '@utils';

import { AppStoreRating } from '../appStoreRating';
import { PaymentMethodIcons } from '../paymentMethodIcons';
import { Text, TextProps } from '../text';

export type TripHeaderProps = {
  titleTextProps?: TextProps;
  subtitleTextProps?: TextProps;
  tag?: string;
  fullDestinations?: string;
  price?: string;
  variant?: 'QUOTE' | 'CONFIRMATION' | 'CHECKOUT';
  className?: string;
  statusVariant?: BadgeVariant;
};

/**
 * Trip Header component (Banner)
 * variants:
 * DEFAULT: content is left aligned
 * QUOTE: used in quote flow, centered items and larger banner height
 * CONFIRMATION: left aligned when medium to large, centered when small, and larger banner height
 */
export const TripHeader: FC<TripHeaderProps> = ({
  titleTextProps,
  subtitleTextProps,
  tag,
  fullDestinations,
  price,
  variant,
  className = 'md:h-80 h-96',
  statusVariant = 'active',
}) => {
  const isQuote = variant === 'QUOTE';
  const isCheckOut = variant === 'CHECKOUT';
  const isQuoteOrCheckOut = isQuote || isCheckOut;
  const isConfirmation = variant === 'CONFIRMATION';
  const isDefault = !isQuoteOrCheckOut && !isConfirmation;
  return (
    <header
      className={twMerge(
        isQuoteOrCheckOut && '!md:h-[370px] !h-fit',
        isConfirmation && 'h-fit',
        className,
        'relative flex w-full items-center',
      )}>
      <Container // content row container
        className={twMerge(
          isQuoteOrCheckOut && 'w-full',
          isConfirmation && 'items-center lg:items-start',
        )}>
        <div // content column container
          className={twMerge(
            'flex w-full flex-col py-8 ',
            isDefault && 'items-start',
            isQuoteOrCheckOut && ' w-full ',
            isConfirmation && 'items-center lg:items-start lg:pr-[40%]',
          )}>
          {tag && <Badge variant={statusVariant} title={tag} className="mb-4" />}
          {titleTextProps && (
            <Text
              variant={{ lg: 'h1-44/freely', sm: 'h2-36/freely' }}
              className={twMerge(
                'text-mono-100',
                subtitleTextProps && 'pb-4',
                isConfirmation && 'text-center lg:text-left',
              )}
              withMarkDown
              {...titleTextProps}
              {...testProps('trip-header-title')}
            />
          )}
          {subtitleTextProps && (
            <Text
              variant={{ lg: 'h4-24/r', sm: 'subTitle-20/r' }}
              className={twMerge(
                'text-mono-100',
                fullDestinations && 'pb-4',
                isConfirmation && 'text-center lg:text-left w-full break-words',
              )}
              {...subtitleTextProps}
              {...testProps('trip-header-subtitle')}
            />
          )}
          {fullDestinations && (
            <Text
              variant="h4-24/sb"
              className="line-clamp-3 text-ellipsis text-mono-100"
              {...testProps('trip-header-destinations')}>
              {fullDestinations}
            </Text>
          )}
          {price && <PriceSection price={price} />}
          {isQuoteOrCheckOut && <QuoteBanner variant={variant} />}
        </div>
      </Container>
      <BannerImage />
    </header>
  );
};

const PriceSection = ({ price }: { price: string }) => {
  const isAU = useRegionStore(isAURegionSelector);

  return (
    <div className="flex w-full flex-col lg:flex-row lg:justify-between">
      <Text
        variant={{ lg: 'h1-44/sb', sm: 'h2-36/sb' }}
        className="text-mono-100"
        {...testProps('trip-header-total-price')}>
        {price} <span className="text-[28px]">Total</span>
      </Text>
      {/* app store rating on the same row as price */}
      {isAU && <AppStoreRating />}
    </div>
  );
};

const BannerImage = () => {
  const imageSrcSet = useTripStore(getDestinationBackgroundImagesSelector);
  const hasImage = imageSrcSet && Object.values(imageSrcSet).length > 0;

  if (hasImage) {
    return (
      <img
        srcSet={`${imageSrcSet.desktop} 1024pxw, ${imageSrcSet.tablet} 768pxw, ${imageSrcSet.mobile} 640pxw`}
        src={imageSrcSet.desktop}
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
    );
  }

  // default steal background
  return <div className="bg-fuji-300 absolute inset-0 -z-10 h-full w-full object-cover" />;
};

type QuoteBannerProps = {
  variant: 'QUOTE' | 'CHECKOUT';
};
const QuoteBanner: React.FC<QuoteBannerProps> = ({ variant }) => {
  const region = useRegionStore(getRegionSelector);
  const coolingDaysSelector = useConfigStore(getCoolingOffPeriodDaysSelector);
  const trip = useTripStore(getTripSelector);
  const destinationsList = getDestinationsLabel(trip);
  const startDate = getFullDateStr(trip.startDate);
  const endDate = getFullDateStr(trip.endDate);
  const tripDateStr = `On ${startDate} to ${endDate}`;

  const quoteTitleText = variant === 'CHECKOUT' ? 'checkout.header.title' : 'myQuote.header.title';
  const coolOffPeriod = coolingDaysSelector(region?.state ?? '');

  return (
    <div className="gap-2 md:gap-4 lg:gap-10 flex flex-col justify-between md:mt-4  space-y-7 md:space-y-0 ">
      <div className="justify-start">
        <Assets.Zurich className="w-22" />
      </div>
      <div className="flex flex-col md:flex-row justify-between md:items-center">
        <div className="space-y-3 flex-1">
          <Text
            variant={{ lg: 'h4-24/r', md: 'body-16/m', sm: 'body-16/m' }}
            className="text-mono-100">
            {i18n.t(quoteTitleText)}
          </Text>
          <div className="flex flex-row justify-between w-full items-center">
            <Text
              variant={{ lg: 'h1-44/freely', sm: 'h2-36/freely', md: 'h2-36/freely' }}
              className="text-mono-100">
              <div className="sm:line-clamp-1">
                {destinationsList.split(',').length >= 9 ? (
                  <>
                    {destinationsList.split(',')[0]} <span className="text-[28px]"> + 9 more</span>
                  </>
                ) : (
                  destinationsList
                )}
              </div>
            </Text>
          </div>
          <div className="flex flex-row justify-start items-center w-full">
            <Text
              variant={{ lg: 'h4-24/r', md: 'body-16/m', sm: 'body-16/m' }}
              className="text-mono-100 ">
              {tripDateStr}
            </Text>
            <Badge
              variant="disabled"
              title={i18n.t('checkout.header.timeZoneLabel')}
              className="ml-2"
            />
          </div>
        </div>
        <div className="w-full border-b-[0.12rem] border-mono-100 border-opacity-40 md:hidden my-6"></div>
        <div className="space-y-6">
          <div className="flex md:justify-end items-end">
            <TotalPrice />
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full md:items-center">
        <div>
          <AppStoreRating />
        </div>
        <div className="hidden lg:flex space-x-12">
          <div className="flex items-center">
            <Assets.Support height={22} width={22} />
            <Text variant="subHeading-14/r" className="px-1 text-mono-100" withMarkDown>
              {i18n.t('myQuote.header.travelSupport')}
            </Text>
          </div>
          <div className="flex items-center">
            <Assets.CalendarCoolOff height={22} width={22} className="text-mono-100" />
            <Text variant="subHeading-14/r" className="px-1 text-mono-100" withMarkDown>
              {i18n.t('myQuote.header.coolingPeriod', { coolOffPeriod })}
            </Text>
          </div>
        </div>
        <div className="flex items-center flex-wrap mt-2 md:mt-0">
          <div className="flex">
            <Assets.Verified height={22} width={22} />
            <Text variant="subHeading-14/r" className="text-mono-100 pr-2 inline" withMarkDown>
              {i18n.t('myQuote.header.securePayment')}
            </Text>
          </div>
          <div className="flex-auto lg:flex-none">
            <PaymentMethodIcons className="ml-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

const TotalPrice = () => {
  const trip = useTripStore(getTripSelector);
  const tripPrice = trip.price / 100;
  return (
    <>
      <Text
        variant={{ lg: 'h2-36/sb', md: 'h2-36/sb', sm: 'h2-36/sb' }}
        className="text-mono-100 pb-[0.4rem] pr-1">
        $
      </Text>
      <Text
        variant={{ lg: 'h1-44/sb', md: 'h1-44/sb', sm: 'h2-36/sb' }}
        className="text-mono-100 pr-2">
        {tripPrice}
      </Text>
      <Text
        variant={{ lg: 'h4-24/sb', md: 'body-16/sb', sm: 'body-16/sb' }}
        className="text-mono-100 pb-[0.3rem]">
        {i18n.t('myQuote.header.total')}
      </Text>
    </>
  );
};
