import { useFeatureFlagsStore } from '@store';

const FEATURE_FLAG_KEY = 'email-quote-modal-cs-number';

export const useEmailQuoteModalCSNumberExperiment = () => {
  const featureFlags = useFeatureFlagsStore(state => state.featureFlags);

  const variant = featureFlags?.[FEATURE_FLAG_KEY]?.value;
  const payload = featureFlags?.[FEATURE_FLAG_KEY]?.payload;
  const analyticsKeyValue = { [`$feature/${FEATURE_FLAG_KEY}`]: variant };

  return { analyticsKeyValue, payload, variant };
};
