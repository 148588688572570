export const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  return /android/i.test(userAgent);
};

export const isIOS = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform,
  ) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const isIOSPhone = () =>
  ['iPhone Simulator', 'iPhone'].includes(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
