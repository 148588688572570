import omit from 'lodash/omit';
import { DateTime } from 'luxon';

import { getPaymentIntent, notifyAuthorization } from 'freely-shared-api';

import { useConfigStore } from '@packages/stores';
import {
  GuestDetails,
  PrimaryTraveller,
  SecondaryTraveller,
  TRIP_STATUS,
  TRIP_TYPE,
  Trip,
} from '@packages/types';
import {
  getStripeAddress,
  isTravellerAgeChanged,
  isTripPaid,
  shouldShowBlankDob,
} from '@packages/utils';

import { captureException, setUser, withScope } from '@sentry/react';
import {
  PaymentSectionType,
  useCheckoutStore,
  useGuestStore,
  useModalStore,
  useTripStore,
} from '@store';
import { Stripe, StripeElements, StripeError } from '@stripe/stripe-js';

import { sendAnalyticsEvent, sendAnalyticsUserId } from './analytics/analytics';
import { sentryTags } from './sentry';

const STRIPE_CANCEL_PAYMENT_MESSAGE = 'Please fill in your card details.';
const paymentMethods: PaymentSectionType[] = ['applepay', 'googlepay'];

function hasCancelledAppleOrGooglePayment(error?: StripeError) {
  const paymentMethod = useCheckoutStore.getState()?.paymentSectionType;
  return (
    !!paymentMethod &&
    paymentMethods.includes(paymentMethod) &&
    error?.message === STRIPE_CANCEL_PAYMENT_MESSAGE
  );
}

export type ShouldUpdateTrip = (args: {
  guestDetails?: Pick<GuestDetails, 'dob' | 'firstName' | 'lastName' | 'secondaryTravellers'>;
  trip?: Trip;
}) => Partial<Trip>;

export const shouldUpdateTrip: ShouldUpdateTrip = ({ guestDetails, trip }) => {
  if (!guestDetails || !trip) {
    return {};
  }

  const changes: {
    primaryTraveller?: Pick<PrimaryTraveller, 'firstName' | 'lastName' | 'dob'> | null;
    secondaryTravellers?: SecondaryTraveller[];
  } = {};

  if (guestDetails.dob !== trip.primaryTraveller?.dob) {
    changes.primaryTraveller = {
      dob: guestDetails.dob ?? null,
      firstName: guestDetails.firstName ?? '',
      lastName: guestDetails.lastName ?? '',
    };
  }

  if (guestDetails.secondaryTravellers?.length !== trip.secondaryTravellers?.length) {
    changes.secondaryTravellers = guestDetails.secondaryTravellers;
  } else if (
    guestDetails.secondaryTravellers?.some(secondaryTraveller => {
      const tripSecondaryTraveller = trip.secondaryTravellers.find(
        t => t?.sortKey === secondaryTraveller?.sortKey,
      );
      return (
        secondaryTraveller.dob !== tripSecondaryTraveller?.dob ||
        tripSecondaryTraveller.isDependant !== secondaryTraveller.isDependant
      );
    })
  ) {
    changes.secondaryTravellers = guestDetails.secondaryTravellers;
  }

  return changes;
};

export function willPriceChange(
  diffs: {
    primaryTraveller?: Pick<PrimaryTraveller, 'firstName' | 'lastName' | 'dob'> | null;
    secondaryTravellers?: SecondaryTraveller[];
  },
  trip: Trip,
) {
  if (!shouldShowBlankDob(trip.primaryTraveller)) {
    return Object.keys(diffs).length > 0;
  }

  if (
    diffs.primaryTraveller?.dob &&
    isTravellerAgeChanged(diffs.primaryTraveller?.dob, trip.primaryTraveller?.dob)
  ) {
    return true;
  }

  if ((diffs.secondaryTravellers ?? []).length !== trip.secondaryTravellers.length) {
    return true;
  }

  if (
    Array.isArray(diffs.secondaryTravellers) &&
    diffs.secondaryTravellers?.some(secondaryTraveller => {
      const tripSecondaryTraveller = trip.secondaryTravellers.find(
        t => t?.sortKey === secondaryTraveller?.sortKey,
      );

      if (!tripSecondaryTraveller) {
        return true;
      }

      return (
        isTravellerAgeChanged(secondaryTraveller.dob, tripSecondaryTraveller.dob) ||
        tripSecondaryTraveller.isDependant !== secondaryTraveller.isDependant
      );
    })
  ) {
    return true;
  }

  return false;
}

export function getAUCovidConsentType(tripType?: TRIP_TYPE) {
  return tripType === TRIP_TYPE.INTERNATIONAL ? 'international' : 'domestic';
}

export async function fetchPaymentIntent() {
  return await withScope(async scope => {
    sentryTags({ event: 'fetch.paymentIntent', source: 'fetchPaymentIntent' });
    const updateTripPolicyStatus = useTripStore.getState().updateTripPolicyStatus;
    const stripeApiVersion = useConfigStore.getState().config?.STRIPE_API_VERSION;
    const isAU = useConfigStore.getState().regionSpecificConfig?.CODE === 'AU';
    const trip = useTripStore.getState().trip;
    scope.setTag('tripId', trip?.sortKey);
    const isFreeOfCharge = !!trip?.promotionCode?.isFreeOfCharge;

    try {
      if (!isAU) {
        return { error: 'ERROR_CAN_ONLY_MAKE_STRIPE_PAYMENT_IN_AUSTRALIA' } as const;
      }

      if (!stripeApiVersion) {
        return { error: 'ERROR_STRIPE_API_VERSION_NOT_FOUND' } as const;
      }

      if (!trip?.sortKey) {
        return { error: 'ERROR_TRIP_SORT_KEY_NOT_FOUND' } as const;
      }

      if (isFreeOfCharge) {
        return { error: 'ERROR_CAN_NOT_PAY_FOR_FREE_TRIP' } as const;
      }

      if (isTripPaid(trip)) {
        return { error: 'ERROR_TRIP_IS_ALREADY_PAID' } as const;
      }

      if (trip.state === TRIP_STATUS.WAITING_PAYMENT) {
        return { error: 'ERROR_PAYMENT_ALREADY_IN_PROGRESS' } as const;
      }
      updateTripPolicyStatus({ status: TRIP_STATUS.WAITING_PAYMENT });
      const paymentIntent = await getPaymentIntent({
        tripId: trip.sortKey,
        apiVersion: stripeApiVersion,
      });

      const clientSecret = paymentIntent.paymentIntent.clientSecret;

      if (!clientSecret) {
        return { error: 'ERROR_CLIENT_SECRET_NOT_FOUND' } as const;
      }

      return { clientSecret } as const;
    } catch (e) {
      captureException(e);
      return { error: 'ERROR_FAILED_TO_FETCH_PAYMENT_INTENT' } as const;
    }
  });
}

export function onDiscountInvalid() {
  const openModal = useModalStore.getState().openModal;
  const closeModal = useModalStore.getState().closeModal;
  openModal('Modal', {
    title: "you're Promocode has expired",
    titleClassName: 'text-left',
    size: 'md',
    body: 'Unfortunately the Promocode you have entered has recently expired, please remove it and try again.',
    actions: [
      {
        title: 'Clear code',
        variant: 'primary',
        onClick: () => {
          closeModal();
        },
      },
    ],
  });
}

export function onProcessingPaymentModal() {
  const openModal = useModalStore.getState().openModal;
  sendAnalyticsEvent('Processing Payment Modal Viewed');
  openModal('Modal', {
    body: {
      type: 'ProcessingPaymentModal',
    },
    showCloseButton: false,
    bodyContainerClassName: 'p-4 sm:p-6',
    size: 'md',
    shouldCloseOnOverlayClick: false,
  } as any);
}

export async function validatePromoCode() {
  return await withScope(async scope => {
    sentryTags({ event: 'add.promoCode', source: 'validatePromoCode' });
    const trip = useTripStore.getState().trip;
    scope.setTag('tripId', trip?.sortKey);
    const regionSpecificConfig = useConfigStore.getState().regionSpecificConfig;
    const isAU = regionSpecificConfig?.CODE === 'AU';
    const partnerIdValidityDurationInMinutes =
      regionSpecificConfig?.RULES?.PARTNER_ID_VALIDITY_DURATION_IN_MINUTES ?? 30;
    if (!trip?.sortKey) {
      return { error: 'ERROR_UNDEFINED_TRIP_SORT_KEY' } as const;
    }

    if (!isAU) {
      return { result: 'PROCEED_TO_USE_NON_AU_PAYMENT' } as const;
    }

    /**
     *If Australian customer has provided discount code, the app must check if the purchase
     *is within half an hour of the discount code timestamp.
     *If is beyond half an hour, the app must now re-apply code if the reservation has expired.
     *This is done before initializing the payment intent.
     */
    const guest = useGuestStore.getState().guest;
    const applyDiscountCode = useTripStore.getState().applyDiscountCode;
    if (
      !isTripPaid(trip) &&
      trip?.promotionCode?.discountCode &&
      trip?.promotionCode?.reservedUntil &&
      guest?.email
    ) {
      // BE is giving us reservedUntil in UTC, we are comparing the difference only in minutes
      const timeStampAtPurchase = DateTime.now().toUTC();
      const reservedUntil = DateTime.fromMillis(trip.promotionCode.reservedUntil);
      if (
        reservedUntil.diff(timeStampAtPurchase).as('minutes') > partnerIdValidityDurationInMinutes
      ) {
        try {
          await applyDiscountCode(trip?.promotionCode?.discountCode, guest.email);
          return { result: 'SUCCESS_DISCOUNT_CODE_REAPPLIED' } as const;
        } catch (error) {
          onDiscountInvalid();
          captureException(error);
          return { error: 'ERROR_APPLYING_DISCOUNT_CODE' } as const;
        }
      }
    }
    return { result: 'DISCOUNT_CODE_REAPPLY_NOT_NEEDED' } as const;
  });
}

export async function processFreeOfChargeTrip() {
  const trip = useTripStore.getState().trip;
  const processFreeOfChargePromo = useTripStore.getState().processFreeOfChargePromotion;
  if (!trip?.promotionCode?.isFreeOfCharge) {
    return { error: 'ERROR_NOT_VALID_FREE_TRIP' } as const;
  }
  try {
    await processFreeOfChargePromo();
    onProcessingPaymentModal();
    return { result: 'SUCCESS_FREE_OF_CHARGED_PROCESSED' } as const;
  } catch (error) {
    onDiscountInvalid();
    captureException(error);
    return { error: 'ERROR_PROCESSING_FREE_OF_CHARGE' } as const;
  }
}

export async function onPayPress({
  stripe,
  elements,
}: {
  stripe?: Stripe | null;
  elements?: StripeElements | null;
}) {
  return await withScope(async scope => {
    sentryTags({ event: 'purchase.trip', source: 'onPayPress' });
    const setPaymentErrorMessage = useCheckoutStore.getState().setPaymentErrorMessage;
    const guest = useGuestStore.getState().guest;
    if (guest?.userId)
      setUser({
        id: guest.userId,
      });
    const currency = useConfigStore.getState().regionSpecificConfig?.CURRENCY;
    const closeModal = useModalStore.getState().closeModal;
    const updateGuest = useGuestStore.getState().updateGuest;
    const updateTripPolicyStatus = useTripStore.getState().updateTripPolicyStatus;
    const trip = useTripStore.getState().trip;
    scope.setTag('tripId', trip?.sortKey);
    const isFreeOfCharge = !!trip?.promotionCode?.isFreeOfCharge;
    const openSection = useCheckoutStore.getState()?.openSection;
    const isStripeFormComplete = !!useCheckoutStore.getState()?.isStripeFormComplete;
    const isLegalConsentChecked = !!useCheckoutStore.getState()?.isLegalConsentChecked;
    const price = trip?.price;
    if (
      (openSection !== 'payment' || !isStripeFormComplete) &&
      (!isLegalConsentChecked || !isFreeOfCharge)
    ) {
      return;
    }

    try {
      updateTripPolicyStatus({ policyStatus: 'NORMAL' });
      if (isFreeOfCharge) {
        const { error: validatePromoCodeResponseError } = await validatePromoCode();
        if (validatePromoCodeResponseError) {
          throw new Error(validatePromoCodeResponseError);
        }

        const guestDetails = await updateGuest();
        sendAnalyticsUserId(guestDetails?.userId ?? null, {
          email: guestDetails?.email?.toLowerCase(),
        });

        const { error: processFreeOfChargeError } = await processFreeOfChargeTrip();
        if (processFreeOfChargeError) {
          throw new Error(processFreeOfChargeError);
        }
        return;
      }

      if (!stripe || !elements) {
        throw new Error('ERROR_STRIPE_NOT_INITIALIZED');
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        setPaymentErrorMessage(submitError.message);
        throw new Error(submitError.message);
      }

      const guestDetails = await updateGuest();
      sendAnalyticsUserId(guestDetails?.userId ?? null, {
        email: guestDetails?.email?.toLowerCase(),
      });

      const { error: validatePaidTripPromoCodeError } = await validatePromoCode();
      if (validatePaidTripPromoCodeError) {
        throw new Error(validatePaidTripPromoCodeError);
      }
      const { clientSecret, error: clientSecretError } = await fetchPaymentIntent();

      if (clientSecretError) {
        throw new Error(clientSecretError);
      }

      onProcessingPaymentModal();

      const stripeAddress = getStripeAddress(guest?.addressData?.detail?.address_components);
      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: guest?.firstName + ' ' + guest?.lastName,
              email: guest?.email,
              address: {
                ...omit(stripeAddress, ['postalCode']),
                country: 'AU',
                postal_code: stripeAddress?.postalCode,
              },
            },
          },
          return_url: window.location.href.split('?')[0],
          shipping: {
            name: guest?.firstName + ' ' + guest?.lastName,
            address: {
              ...omit(stripeAddress, ['postalCode']),
              postal_code: stripeAddress?.postalCode,
              country: 'AU',
            },
          },
        },
        redirect: 'if_required',
      });

      await notifyAuthorization({ clientSecret });
      if (error && hasCancelledAppleOrGooglePayment(error)) {
        closeModal();
        throw new Error(error.message);
      }

      if (error) {
        sendAnalyticsEvent('Payment Failure', {
          revenue: price ? price / 100 : 0,
          currency,
        });
        updateTripPolicyStatus({ policyStatus: 'ERROR' });
        throw new Error(error.message);
      }
    } catch (e) {
      updateTripPolicyStatus({ status: TRIP_STATUS.NO_BOOST });
      captureException(e);
    }
  });
}
