import { FC, useEffect } from 'react';

import { Text } from '@components';
import { useAfterPayStripePayment } from '@hooks';
import { useCheckoutStore, useTripStore } from '@store';
import { PaymentElement as StripePaymentElement, useStripe } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { sendAnalyticsEvent } from '@utils';

interface Props {
  options?: StripeElementsOptions;
}

export const PaymentElement: FC<Props> = () => {
  const stripe = useStripe();

  const { onAfterPayRedirect } = useAfterPayStripePayment();

  const isFreeOfCharge = useTripStore(state => state.trip?.promotionCode?.isFreeOfCharge);

  const setPaymentSectionType = useCheckoutStore(state => state.setPaymentSectionType);
  const setIsStripeFormComplete = useCheckoutStore(state => state.setIsStripeFormComplete);

  const openSection = useCheckoutStore(state => state.openSection);
  const paymentErrorMessage = useCheckoutStore(state => state.paymentErrorMessage);

  useEffect(() => {
    onAfterPayRedirect(stripe);
  }, [onAfterPayRedirect, stripe]);

  return (
    <>
      {paymentErrorMessage && (
        <Text className="text-red-500" variant={'subHeading-14/r'}>
          {paymentErrorMessage}
        </Text>
      )}
      <div className="mt-6">
        <form>
          {/* if discount applied and is free for charge, hide stripe payment */}
          {!isFreeOfCharge && openSection === 'payment' && (
            <StripePaymentElement
              options={{
                fields: {
                  billingDetails: {
                    email: 'never',
                    name: 'never',
                    address: {
                      country: 'never',
                    },
                  },
                },
              }}
              onChange={e => {
                if (e.complete) {
                  setIsStripeFormComplete(true);
                } else {
                  setIsStripeFormComplete(false);
                }
                if (e.value?.type === 'apple_pay') {
                  setPaymentSectionType('applepay');
                  sendAnalyticsEvent('Apple Pay Button Clicked');
                } else if (e.value?.type === 'google_pay') {
                  setPaymentSectionType('googlepay');
                  sendAnalyticsEvent('Google Pay Button Clicked');
                } else if (e.value?.type === 'afterpay_clearpay') {
                  sendAnalyticsEvent('AfterPay Button Clicked');
                  setPaymentSectionType('afterpay');
                } else {
                  setPaymentSectionType('card');
                }
              }}
              onReady={element => {
                element.focus();
              }}
            />
          )}
        </form>
      </div>
    </>
  );
};
