import { tv } from 'tailwind-variants';

export type CoveredCardProps = {
  children: React.ReactNode;
  variant?: 'mint' | 'cherry';
  className?: string;
};

const coveredCardContainer = tv({
  base: 'px-4 py-4 border-2 rounded-lg',
  variants: {
    variant: {
      mint: 'bg-nusa-50 border-nusa-200' as const,
      cherry: 'bg-red-50 border-red-500' as const,
    },
  },
});

export const CoveredCard = ({ children, variant, className }: CoveredCardProps) => {
  return <div className={coveredCardContainer({ className, variant })}>{children}</div>;
};
