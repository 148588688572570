import { Controller, useFormContext } from 'react-hook-form';

import { TravellersForm } from '@packages/types';

import { Checkbox, Text } from '@components';
import { useTravellersInfoModal } from '@hooks';
import { i18n } from '@i18n';
import { sendAnalyticsEvent, testProps } from '@utils';

type Props = {
  index: number;
};

export function IsDependantCheckBox({ index }: Props) {
  const { control, register } = useFormContext<TravellersForm>();
  const isDependantId = `secondaryTravellers.${index}.isDependant` as never;
  const { openMoreInfoModal } = useTravellersInfoModal();

  return (
    <div className="flex">
      <Controller
        control={control}
        name={isDependantId}
        render={({ field }) => (
          <Checkbox
            isChecked={field.value}
            {...testProps(`checkbox-dependant-${index}`)}
            {...register(isDependantId)}
            labelProps={{ children: i18n.t('travellers.dependent.checkbox.label') }}
            onClick={() => {
              sendAnalyticsEvent('Dependant Checkbox Toggled', {
                isDependant: !field.value,
              });
              field.onChange(!field.value);
            }}
          />
        )}
      />
      <button className="ml-2" type="button" onClick={() => openMoreInfoModal('dependant')}>
        <Text className="text-left underline cursor-pointer px">
          {i18n.t('travellers.dependent.actions.info')}
        </Text>
      </button>
    </div>
  );
}
