import { ParticipantPayload } from '@packages/types';

export const growsurfApi = {
  getReferrerId() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      const referralCode = growSurf.getReferrerId();
      return referralCode?.includes('-') ? referralCode.slice(-6) : referralCode;
    }
    return null;
  },
  async init() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      return await growSurf.init();
    }
  },
  async getReferralData() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      const referralId = this.getReferrerId();
      let referralPayload: ParticipantPayload | null;
      if (referralId) {
        referralPayload = await growSurf.getParticipantById(referralId);
        return referralPayload;
      }
    }
    return null;
  },
  async getCampaignId() {
    const growSurf = window?.growsurf;
    if (growSurf) {
      const { id } = await growSurf.getCampaign();
      return id;
    }
    return null;
  },
  async addParticipant(data: { email: string; firstName?: string; lastName?: string }) {
    const growSurf = window?.growsurf;
    if (growSurf) {
      await growSurf.addParticipant(data);
    }
  },
};

declare global {
  interface Window {
    growsurf?: {
      init: () => Promise<void>;
      getReferrerId: () => string;
      getParticipantById: (id: string) => Promise<ParticipantPayload>;
      getCampaign: () => Promise<{ id: string }>;
      addParticipant: (data: {
        email: string;
        firstName?: string;
        lastName?: string;
      }) => Promise<void>;
    };
  }
}
