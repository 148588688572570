import { useEffect } from 'react';

import { Container, Text } from '@components/index';
import { Button } from '@elements/button';
import { Notification } from '@elements/notification';
import { TextWithMd } from '@elements/text/textWithMd';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { closeModalSelector, useBoostDetailsStore, useGlobalStore, useModalStore } from '@store';
import { sendAnalyticsEvent, sendAnalyticsPage, testProps } from '@utils';

import { ListItems } from './listItems.component';

export type UsCovidConsentProps = {
  isModal?: boolean;
};
export const UsCovidConsent = ({ isModal = false }: UsCovidConsentProps) => {
  const setIsCovidConsentAgreed = useGlobalStore(state => state.setIsCovidConsentAgreed);
  const closeModal = useModalStore(closeModalSelector);
  const isBoostDetailsPending = useBoostDetailsStore(state => state.pending);

  useEffect(() => {
    sendAnalyticsPage('DFAT Page Viewed');
  }, []);

  const onAgreePress = async () => {
    sendAnalyticsEvent('Covid Consent Agree Clicked');
    setIsCovidConsentAgreed(true);
    router.navigate(ROUTE_PATHS().myQuote);
    if (isModal) {
      closeModal();
    }
  };

  const onBackPress = () => {
    sendAnalyticsEvent('Covid Consent Back Clicked');
    router.navigate(-1);
    if (isModal) {
      closeModal();
    }
  };

  return (
    <Container
      className="md:px-10"
      titleText={i18n.t('covidConsent.title')}
      titleVariant="h2-36/sb"
      subtitleVariant="h4-24/m"
      subtitleText={i18n.t('covidConsent.subtitle')}
      subtitleClassName="">
      <div className="grid gap-8 pt-8 lg:grid-flow-col">
        <div>
          <Text variant="subTitle-20/sb" className="mb-5">
            {i18n.t('covidConsent.content.title.covered')}
          </Text>
          <ListItems type="covered" />
        </div>
        <div>
          <Text variant="subTitle-20/sb" className="mb-5">
            {i18n.t('covidConsent.content.title.notCovered')}
          </Text>
          <ListItems type="notCovered" />
        </div>
      </div>
      <Notification
        variant="info"
        header={props => (
          <TextWithMd {...props}>{i18n.t('covidConsent.notification.title')}</TextWithMd>
        )}
      />
      <div className="w-full mt-6 border-t border-cabo-200">
        <div className="px-6 pt-8">
          <Text className="mb-1" variant="body-16/sb">
            {i18n.t('covidConsent.footer.title')}
          </Text>
          <Text variant="footnote-12/r">{i18n.t('covidConsent.footer.description')}</Text>
        </div>
      </div>
      <div className="flex flex-col md:flex-row-reverse items-center w-full gap-4 py-6">
        <Button
          className="w-full"
          {...testProps('covid-consent-agree-and-continue-button')}
          onClick={onAgreePress}
          isLoading={isBoostDetailsPending}
          variant="primary"
          title={i18n.t('covidConsent.buttonActions.agreeAndContinue')}
        />
        <Button
          className="w-full"
          {...testProps('covid-consent-back-button')}
          variant="secondary"
          onClick={onBackPress}
          title={i18n.t('covidConsent.buttonActions.back')}
        />
      </div>
    </Container>
  );
};
