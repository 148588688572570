import { SyntheticEvent, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonVariant } from '@packages/types';

import { Loader } from '../loader';
import { Text, TextProps } from '../text';

type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  fontVariant?: TextProps['variant'];
  withFixedWith?: boolean;
  withEllipsis?: boolean;
  withWordWrap?: boolean;
  isJumboSize?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  IconLeft?: React.FC<React.SVGProps<SVGElement>>;
  IconRight?: React.FC<React.SVGProps<SVGElement>>;
  IconRightProps?: React.SVGProps<SVGElement>;
  IconLeftProps?: React.SVGProps<SVGElement>;
  disabled?: boolean;
  isLoading?: boolean;
  textAlign?: 'left' | 'right' | 'center';
  onClick?: () => Promise<unknown> | void;
  textProps?: TextProps;
  type?: 'button' | 'submit' | 'reset';
}

export const getVariantClasses = (variant: ButtonVariant = 'mint') => {
  switch (variant) {
    case 'mint':
      return 'bg-nusa-200 disabled:text-mono-100 disabled:bg-mono-300 hover:bg-nusa-100';
    case 'charcoal':
      return 'bg-fuji-800 text-mono-100 disabled:text-mono-100 disabled:bg-fuji-800/60 hover:bg-fuji-800/80';
    case 'snow':
      return 'bg-cabo-50 disabled:text-fuji-800/50 disabled:bg-cabo-50/60 hover:bg-cabo-50/80';
    case 'white':
      return 'bg-mono-100 disabled:text-fuji-800/50 disabled:bg-mono-100/60 hover:bg-mono-100/80';
    case 'cherry':
      return 'bg-red-500 text-mono-100 disabled:text-mono-100 disabled:bg-red-500 focus:bg-red-500';
    case 'outline':
      return 'bg-mono-100 ring-1 ring-inset ring-fuji-800 disabled:text-fuji-800/50 disabled:bg-mono-100/60 hover:bg-mono-100/80';
  }
};

const getSizeClasses = (size: ButtonSize = 'md') => {
  switch (size) {
    case 'xl':
    case 'lg':
      return 'px-8 py-1 h-16';
    case 'md':
      return 'px-5 py-1 h-12';
    case 'sm':
      return 'px-3 py-1 h-8';
  }
};

const getFontVariant: (size?: ButtonSize) => TextProps['variant'] = size => {
  switch (size) {
    case 'xl':
      return 'h4-24/sb';
    case 'lg':
      return 'subTitle-20/sb';
    case 'md':
      return 'body-16/sb';
    case 'sm':
      return 'subHeading-14/sb';
  }
};

const getIconSizeClasses = (size: ButtonSize = 'md') => {
  switch (size) {
    case 'xl':
    case 'lg':
      return 'max-w-[2rem]';
    case 'md':
      return 'max-w-[1.25rem]';
    case 'sm':
      return 'max-w-[1rem]';
  }
};

const getTextAlignClass = (textAlign: 'left' | 'right' | 'center') => {
  return {
    left: 'text-left',
    right: 'text-right',
    center: 'text-center',
  }[textAlign];
};

export const Button = forwardRef(
  (
    {
      children,
      className,
      textAlign = 'center',
      size = 'md',
      fontVariant,
      variant,
      IconLeft,
      IconRight,
      IconLeftProps,
      IconRightProps,
      disabled = false,
      isLoading = false,
      withFixedWith,
      withWordWrap = false,
      isJumboSize,
      withEllipsis,
      onClick,
      textProps,
      type = 'button',
      ...rest
    }: ButtonProps,
    ref?: React.LegacyRef<HTMLButtonElement>,
  ) => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const isLoadingSpinnerDisplayed = isPending || isLoading;

    const onButtonClick = async (e: SyntheticEvent) => {
      type === 'submit' && e.preventDefault();
      e.stopPropagation();
      setIsPending(true);
      await onClick?.();
      setIsPending(false);
    };

    return (
      <button
        type={type}
        ref={ref}
        onClick={onButtonClick}
        disabled={disabled || isLoadingSpinnerDisplayed}
        className={twMerge(
          'duration-400 relative rounded-full transition active:scale-105',
          getVariantClasses(variant),
          getSizeClasses(size),
          withFixedWith && 'w-full md:w-1/2  lg:w-64',
          isJumboSize && 'h-20 w-full rounded-[1.5rem]',
          className,
        )}
        {...rest}>
        {IconLeft && (
          <IconLeft
            {...IconLeftProps}
            fill={isLoadingSpinnerDisplayed ? 'transparent' : IconLeftProps?.fill}
            className={twMerge(
              getIconSizeClasses(size),
              'absolute top-1/2 left-3.5 translate-y-[-50%]',
              IconLeftProps?.className,
            )}
          />
        )}
        {isLoadingSpinnerDisplayed && (
          <div
            className={twMerge(
              'absolute top-1/2 left-1/2 z-10 translate-y-[-50%] translate-x-[-50%]',
              getIconSizeClasses(size),
            )}>
            <Loader color={variant === 'charcoal' ? 'white' : 'charcoal'} />
          </div>
        )}
        <Text
          className={twMerge(
            !withWordWrap && 'whitespace-nowrap',
            isLoadingSpinnerDisplayed && 'text-transparent invisible',
            textAlign && getTextAlignClass(textAlign),
            IconLeft && 'ml-5 md:ml-2.5',
            IconRight && 'mr-2.5',
            withEllipsis && 'overflow-hidden text-ellipsis',
          )}
          variant={fontVariant ?? getFontVariant(size)}
          {...textProps}>
          {children}
        </Text>
        {IconRight && (
          <IconRight
            {...IconRightProps}
            fill={isLoadingSpinnerDisplayed ? 'transparent' : IconRightProps?.fill}
            className={twMerge(
              getIconSizeClasses(size),
              'absolute top-1/2 right-2.5 translate-y-[-50%]',
              IconRightProps?.className,
            )}
          />
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
