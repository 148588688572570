import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { useConfigStore } from '@packages/stores';
import { TripCostForm } from '@packages/types';
import { getCurrencySymbol } from '@packages/utils';

import { FooterButtonGroup } from '@components/footerButtonGroup';
import { Button } from '@elements/button';
import { usePriceChangeModal } from '@hooks';
import { i18n } from '@i18n';
import { closeModalSelector, openModalSelector, useModalStore, useTripStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { Container } from '../container';
import { GetQuoteProvider } from '../getQuoteProvider';
import { Text } from '../text';
import { TripCostInput } from '../tripCostInput';

export type TripCostEditorProps = {
  isModal?: boolean;
  onNext: () => void;
  onBack: () => void;
};

export const TripCostEditor = (props: TripCostEditorProps) => {
  const totalTripCost = useTripStore(state => {
    const tripCost = state?.trip?.totalTripCost;
    return tripCost ? tripCost.toString() : '';
  });
  return (
    <GetQuoteProvider defaultFormValues={{ totalTripCost }} mode="all" reValidateMode="onChange">
      {<TripCostEditorContent {...props} />}
    </GetQuoteProvider>
  );
};

export const TripCostEditorContent = ({ isModal, onNext, onBack }: TripCostEditorProps) => {
  const {
    formState: { isValid },
    handleSubmit,
  } = useFormContext<TripCostForm>();
  const { openPriceChangeModal } = usePriceChangeModal();
  const setTotalTripCost = useTripStore(state => state.setTotalTripCost);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const syncTrip = useTripStore(state => state.syncTrip);
  const closeModal = useModalStore(closeModalSelector);
  const VALUE_PREFIX: string | undefined = useMemo(
    () => getCurrencySymbol(regionSpecificConfig),
    [regionSpecificConfig],
  );

  const isButtonDisabled = !isValid || isLoading;

  useEffect(() => {
    if (isModal) {
      sendAnalyticsEvent('Modal viewed', { modalTitle: i18n.t('tripCost.modal.title') });
    }
  }, [isModal]);

  const handleNext = async (values: TripCostForm) => {
    const totalTripCost = parseInt(
      values.totalTripCost.replace(VALUE_PREFIX ?? '', '').replace(/,/g, ''),
      10,
    );

    if (!totalTripCost) {
      return;
    }

    setTotalTripCost(totalTripCost);

    if (isModal) {
      return openPriceChangeModal({
        onNext: async () => {
          setIsLoading(true);
          setTotalTripCost(totalTripCost);
          onNext();
          await syncTrip();
          closeModal();
          setIsLoading(false);
        },
      });
    }

    onNext();
  };

  const openModal = useModalStore(openModalSelector);
  const handleInfoButtonClick = () => {
    return openModal('Modal', {
      title: i18n.t('tripCost.modal.title'),
      bodyTextProps: { children: i18n.t('tripCost.modal.content') },
      actions: [
        {
          title: i18n.t('tripCost.modal.actions.ok'),
          variant: 'primary',
        },
      ],
    });
  };

  return (
    <Container
      size="xl"
      titleText={i18n.t('tripCost.title')}
      subtitleText={i18n.t('tripCost.subtitle')}
      className="flex flex-1 flex-col">
      <form>
        <TripCostInput prefixValue={VALUE_PREFIX} isModal={isModal} />

        <div className="flex flex-col py-6 text-center">
          <Text variant="body-16/r">{i18n.t('tripCost.description')}</Text>
          <div className="flex justify-center items-center pt-16 pb-2">
            <Button variant="tertiary" icon="info" size="sm" onClick={handleInfoButtonClick} />
            <Text variant="body-16/r">{i18n.t('tripCost.modal.title')}</Text>
          </div>
          <hr className="border-cabo-200 my-6" />
          <div
            className={twMerge('flex flex-col gap-4', isModal && 'flex-col md:flex-row-reverse')}>
            <FooterButtonGroup
              nextButtonProps={{
                ...testProps('trip-cost-submit-button-save-change-or-get-quote'),
                type: 'submit',
                variant: 'primary',
                title: isModal ? i18n.t('tripCost.actions.save') : i18n.t('global.actions.next'),
                onClick: handleSubmit(handleNext),
                disabled: isButtonDisabled,
                className: 'w-full',
              }}
              backButtonProps={{
                ...testProps('trip-cost-cancel-or-back-button'),
                variant: 'secondary',
                title: isModal ? i18n.t('global.actions.cancel') : i18n.t('global.actions.back'),
                onClick: onBack,
                className: 'w-full',
              }}
            />
          </div>
        </div>
      </form>
    </Container>
  );
};
