import gql from 'graphql-tag';

export const HyperMessageFields = gql`
  fragment HyperMessageFields on HyperMessage {
    ... on TextMessage {
      messageType: __typename
      chatId
      ticketId
      content
      createdAt
      hyperMessageType
      messageId
      updateAt
      author {
        id
        firstName
        lastName
        avatarUrl
      }
    }
    ... on AlertMessageObject {
      messageType: __typename
      chatId
      ticketId
      alertLevel
      backgroundUrl
      contentCTAText
      contentCTAUrl
      contentCategory
      contentSummary
      contentText
      contentTitle
      createTs
      createdAt
      header
      hyperMessageType
      messageId
      updateAt
    }
    ... on FileObjectMessage {
      messageType: __typename
      chatId
      ticketId
      content
      createdAt
      deleted
      deletedBy
      deletedTs
      fileExtension
      fileName
      fileSize
      hyperMessageType
      messageId
      s3Path
      updateAt
      author {
        id
        firstName
        lastName
        avatarUrl
      }
    }
    ... on RatingMessage {
      messageType: __typename
      chatId
      ticketId
      createdAt
      hyperMessageType
      messageId
      tags
      addTags
      updateAt
      author {
        id
        firstName
        lastName
        avatarUrl
      }
    }
    ... on SystemMessage {
      messageType: __typename
      chatId
      ticketId
      createdAt
      hyperMessageType
      messageId
      createdAt
      updateAt
      content
      status
      title
    }

    ... on RiskAlertMessageObject {
      messageType: __typename
      messageId
      chatId
      ticketId
      hyperMessageType
      createdAt
      header
      riskAlertMessageId
      backgroundUrl
      riskLevel
      title
      text
      countryName
      countryCode
      category
    }
  }
`;
