import { RegionCode } from '@packages/types';

type FeatureFlagResponse = {
  config: {
    enable_collect_everything: boolean;
  };
  errorComputingFlags: boolean;
  isAuthenticated: boolean;
  supportedCompression: string[];
  featureFlags: {
    [key: string]: boolean | string;
  };
  featureFlagPayloads: {
    [key: string]: string;
  };
};

export type FeatureFlagsArgs = {
  apiKey: string;
  anonymousId: string;
  region: RegionCode;
  platform: 'mobile' | 'web' | 'qw';
  apiURL: string;
};
export const getFeatureFlags = async (args: FeatureFlagsArgs) => {
  try {
    const res = await fetch(`${args?.apiURL}/decide?v=3`, {
      method: 'POST',
      body: JSON.stringify({
        api_key: args.apiKey,
        distinct_id: args.anonymousId,
        person_properties: {
          region: args.region,
          platform: args.platform,
        },
      }),
      headers: { 'Content-Type': 'application/json' },
    });

    return (await res.json()) as FeatureFlagResponse;
  } catch (e: any) {
    throw new Error(e);
  }
};
