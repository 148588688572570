import { useEffect } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { useEmcTraveller } from '@hooks';
import { useEmcStore } from '@store';

import { EmcSection } from './emcSection.component';
import { EmcEmailAssessmentSection } from './steps/emcEmailAssessment';

export const Emc = () => {
  const resetScreeningQuestions = useEmcStore(state => state.resetScreeningQuestions);
  const { emcCurrentTraveller } = useEmcTraveller();

  const openEmcSection = useEmcStore(state => state.openEmcSection);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);

  const handleClose = () => {
    setOpenEmcSection(undefined);
  };

  useEffect(() => {
    if (!openEmcSection) {
      resetScreeningQuestions();
    }
  }, [openEmcSection, resetScreeningQuestions]);

  useEffect(() => {
    if (openEmcSection) {
      window.localStorage.setItem(
        'screeningData',
        emcCurrentTraveller?.decryptedScreeningResult?.[0]?.ScreeningPath?.[0]?.SystemData
          ?.ScreeningData ?? '',
      );
    }
  }, [openEmcSection, emcCurrentTraveller?.decryptedScreeningResult]);

  return (
    <Transition.Root show={!!openEmcSection}>
      <Dialog onClose={handleClose} as="div" className="relative z-10">
        {openEmcSection !== 'emc-email-assessment' ? <EmcSection /> : <EmcEmailAssessmentSection />}
      </Dialog>
    </Transition.Root>
  );
};
