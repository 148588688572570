import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { REGIONS } from '@packages/constants';
import {
  getRegionSelector,
  isUSRegionSelector,
  useConfigStore,
  useRegionStore,
} from '@packages/stores';
import { GuestDetailsForm, RegionValidationRules } from '@packages/types';
import { validate, validatePostCode, validateState } from '@packages/utils';

import { Button, ButtonProps } from '@elements/button';
import { Dropdown } from '@elements/dropdown';
import { Input } from '@elements/input';
import { i18n } from '@i18n';
import { useCheckoutStore } from '@store';
import { setIsManualAddressSectionSelector } from '@store/checkout/checkout.selectors';

import { Text } from '../text';

//https://stackoverflow.com/questions/71296535/how-to-remove-arrow-on-input-type-number-with-tailwind-css
const removeNativeArrowClass =
  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ';

export const ManualAddress: React.FC = () => {
  const setIsManualAddressSectionOpen = useCheckoutStore(setIsManualAddressSectionSelector);
  const isUsRegion = useRegionStore(isUSRegionSelector);
  const region = useRegionStore(getRegionSelector);
  const states = region?.country && REGIONS[region?.country]['states']?.map(it => it.code);
  const countryCode = useConfigStore(state => state?.regionSpecificConfig?.CODE) ?? '';
  const regionSpecificValidation =
    useConfigStore(state => state?.regionSpecificConfig?.VALIDATION) ??
    ({} as RegionValidationRules);

  const addressOnePlaceholder = i18n.t('checkout.address.input.line1.placeholder');
  const addressTwoPlaceholder = i18n.t('checkout.address.input.line2.placeholder');
  const addressThreePlaceholder = i18n.t('checkout.address.input.line3.placeholder');
  const addressFourPlaceholder = i18n.t('checkout.address.input.line4.placeholder');
  const addressFivePlaceholder = i18n.t('checkout.address.select.placeholder');

  const stateOptions = (states || []).map((state, index) => ({
    id: state + '_' + index,
    label: state,
    value: state,
  }));

  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<GuestDetailsForm>();
  const userSelectedState = stateOptions?.find(it => {
    const userState = region?.state ?? getValues('addressState') ?? '';
    return it.value === userState;
  });

  const undoManualEntry = () => {
    setValue('addressData', undefined);
    setValue('addressCity', '');
    setValue('addressPostCode', '');
    setValue('addressLine1', '');
    setValue('addressLine2', '');
    !isUsRegion && setValue('addressState', '');
    setIsManualAddressSectionOpen(false);
  };

  return (
    <div>
      <Text className="mb-3" variant="h4-24/sb">
        {i18n.t('checkout.address.subtitle')}
      </Text>
      <Text
        onClick={undoManualEntry}
        className="underline cursor-pointer !text-sm mb-3"
        variant="body-16/r">
        {i18n.t('checkout.address.description')}
      </Text>
      <div className="flex flex-col gap-y-s16 mb-s16">
        <Input
          label={i18n.t('checkout.text_address')}
          placeholder={addressOnePlaceholder}
          {...register('addressLine1', {
            validate: v => validate('addressLine', v ?? '') ?? undefined,
          })}
          errorMessage={errors?.addressLine1?.message}
        />
        <Input
          {...register('addressLine2', {
            validate: v => validate('addressLine2', v ?? '') ?? undefined,
          })}
          placeholder={addressTwoPlaceholder}
          errorMessage={errors?.addressLine2?.message}
        />
        <Input
          placeholder={addressThreePlaceholder}
          {...register('addressCity', {
            required: 'City/Suburb is required',
            validate: v => {
              if (!v?.trim()) {
                return 'City/Suburb is required';
              }
            },
          })}
          errorMessage={errors?.addressCity?.message}
        />
        <Input
          placeholder={addressFourPlaceholder}
          type="number"
          label="Postcode"
          className={twMerge(removeNativeArrowClass)}
          {...register('addressPostCode', {
            validate: v => validatePostCode(regionSpecificValidation, v),
          })}
          errorMessage={errors?.addressPostCode?.message}
        />
        <div>
          <Controller
            control={control}
            name="addressState"
            rules={{
              validate: v => validateState(countryCode, v),
            }}
            render={({ field: { onChange } }) => (
              <Dropdown
                initialValue={userSelectedState}
                disabled={isUsRegion}
                items={stateOptions}
                onSelect={onChange}
                inputProps={{
                  disabled: isUsRegion,
                  placeholder: addressFivePlaceholder,
                  label: i18n.t('checkout.address.select.label'),
                  errorMessage: errors?.addressState?.message,
                  rightIcon: (props: ButtonProps) => <Dropdown.Button as={Button} {...props} />,
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
