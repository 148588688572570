import { REGIONS } from '@packages/constants';

import { useRegionStore } from '../region/region.store';
import { getRegionState } from '../region/region.util';
import { GetStore } from '../utils/utils';
import { ConfigStore, useConfigStore } from './config.store';

export const getRegionSpecificConfig = (get: GetStore<ConfigStore>) => {
  const region = getRegionState();
  const { config } = get();
  if (config && region) {
    return config[region.country];
  }
  return;
};

export const getRegionSpecificTripDurationRule = () => {
  const maxTripDuration = useConfigStore.getState().regionSpecificConfig?.RULES.MAX_TRIP_DAYS;
  const countryCode = useRegionStore.getState().region?.country;
  const stateCode = useRegionStore.getState().region?.state;

  // If US region. We must check to see if there are rule exceptions
  if (countryCode === REGIONS.US.code && stateCode) {
    //Extract any state exception rule for max trip duration rule.
    const exceptionDurationRules =
      useConfigStore.getState().regionSpecificConfig?.RULES?.EXCEPTIONS?.[stateCode]?.MAX_TRIP_DAYS;
    //Extract the default max trip duration rule.
    // Return exception rule or default back to max trip duration default.
    return exceptionDurationRules ?? maxTripDuration;
  }

  // Otherwise, if not US return the default rule.
  return maxTripDuration;
};
