import { getRegionSelector, useRegionStore } from '@packages/stores';
import { BOOST_CODE } from '@packages/types';
import {
  getBoostUpsellMotorCycleModalText,
  shouldOpenMotorCycleBoostUpsellModal,
} from '@packages/utils';

import { getTripSelector, useFeatureFlagsStore, useTripStore } from '@store';
import { hasBoostUpsellDisplayed } from '@utils';

import { ModalBody } from '../../types/constants';
import { useBoostUpsellModel } from '../useBoostUpsellModel';

export const useMotorcycleUpsell = () => {
  const trip = useTripStore(getTripSelector);
  const displayBoostUpsellCodes = useTripStore(state => state.displayBoostUpsellCodes);
  const region = useRegionStore(getRegionSelector);
  const motorCycleUpsellRules = useFeatureFlagsStore(
    state => state?.featureFlags?.['motorcycle-boost-upsell']?.payload,
  );

  const motorCycleUpsellDestinations = motorCycleUpsellRules?.destinations ?? [];
  const addBoostUpsellCode = useTripStore(state => state.addBoostUpsellCode);

  const maxAgeThreshold = useFeatureFlagsStore(
    state => state?.featureFlags?.['motorcycle-boost-upsell']?.payload?.primaryTravellerAge?.max,
  );
  const minAgeThreshold = useFeatureFlagsStore(
    state => state?.featureFlags?.['motorcycle-boost-upsell']?.payload?.primaryTravellerAge?.min,
  );

  const { openBoostUpsellModel } = useBoostUpsellModel();

  //get the motorcycle boost
  const boost = trip?.boosts?.find(boost => boost.code === BOOST_CODE.MOTORCYCLE);

  const isMotorcycleBoostUpsellEligible = shouldOpenMotorCycleBoostUpsellModal(
    trip,
    maxAgeThreshold,
    minAgeThreshold,
    region?.country,
    motorCycleUpsellDestinations,
  );

  const { title: modalTitle, description: modalDescription } = getBoostUpsellMotorCycleModalText(
    trip?.destinations ?? [],
    motorCycleUpsellRules,
  );

  const shouldOpenModal =
    !hasBoostUpsellDisplayed(boost?.code ?? '', displayBoostUpsellCodes) &&
    isMotorcycleBoostUpsellEligible;

  const openModal = (onComplete: () => void) => {
    if (!boost) {
      return;
    }
    //check if boost upsell is displayed if not then set in store
    addBoostUpsellCode(boost?.code);
    const modalBody: ModalBody = {
      type: 'UpSell',
      boost: boost,
      title: modalTitle,
      description: modalDescription,
      boostUpsellType: 'motorcycle',
      onComplete: onComplete,
    };
    openBoostUpsellModel(modalBody);
  };

  return { shouldOpenModal, openModal };
};
