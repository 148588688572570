import { regionDateUtils } from '@packages/stores';
import { utcToDateFormat } from '@packages/utils';

import { GuestStore } from '@store';

export const getFullNameSelector = (state: GuestStore) =>
  state.guest?.firstName + ' ' + state.guest?.lastName;

export const getPrimaryDOBSelector = (state: GuestStore) =>
  utcToDateFormat(state.guest?.dob ?? '', 'AU');

export const getEmailSelector = (state: GuestStore) => state.guest?.email;
export const getAddressSelector = (state: GuestStore) =>
  state.guest?.addressData?.data?.description;

export const getSecondaryTravellers = (state: GuestStore) => state.guest?.secondaryTravellers;

export const getTravellerAge = (state: GuestStore) =>
  regionDateUtils().getAge(state.guest?.dob ?? '');
