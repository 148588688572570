import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Range } from 'react-range';

import {
  getExistingSpecifiedItems,
  isBoostAdded,
  validate,
  validateEmojis,
} from '@packages/utils';

import { Boost, SelectOption, SpecifiedBoostItem } from '@packages/types';

import { SpecifiedItemsList } from '@components/specifiedItemsList';
import { Button, ButtonProps } from '@elements/button';
import { Dropdown } from '@elements/dropdown';
import { Input } from '@elements/input';
import { i18n } from '@i18n';
import { closeModalSelector, getSelectedBoostById, useModalStore, useTripStore } from '@store';
import { constructTravellersAsSelectOptions, sendAnalyticsEvent, testProps } from '@utils';

import { BoostModalContainer } from '../boostModalContainer';
import { Text } from '../text';

export type AddSpecifiedItemModalProps = {
  boostId: string;
};

export const AddSpecifiedItemsModal: FC<AddSpecifiedItemModalProps> = ({ boostId }) => {
  const closeModal = useModalStore(closeModalSelector);
  const addSpecifiedItem = useTripStore(state => state.addSpecifiedItem);
  const boost = useTripStore(getSelectedBoostById(boostId));
  const isEditMode = isBoostAdded(boost);
  const priceValues = useMemo(
    () => boost?.boostProperty?.coverAmountValues?.slice()?.sort((a, b) => a - b),
    [boost?.boostProperty?.coverAmountValues],
  );
  const basePrice = boost?.toUpdate?.boostProperty?.basePrice ?? boost?.boostProperty?.basePrice;
  const travellers = boost?.travellers;
  const travellerOptions = useMemo<SelectOption[]>(
    () => constructTravellersAsSelectOptions(travellers),
    [travellers],
  );
  const specifiedItemsList = getExistingSpecifiedItems(boost);
  const itemsToDisplayInDropdown = specifiedItemsList?.length < 1 ? 2 : 5; //if there are no add items, show 2 items in the dropdown else max 5 items
  const minValue = 0;
  const maxValue = priceValues ? priceValues?.length - 1 : 0;
  const [thumbPosition, setThumbPosition] = useState<number[]>([0]);
  const [isAddingLoading, setIsAddingLoading] = useState<boolean>(false);
  const startingPrice = priceValues?.[0] ?? 0;
  const primaryTravellerOption = travellerOptions?.[0] ?? null;
  const [boostWithModifiedPrice, setBoostWithModifiedPrice] = useState<Boost | undefined>(
    boost
      ? {
          ...boost,
          price: `${basePrice ?? 0}`,
        }
      : undefined,
  );

  useEffect(() => {
    sendAnalyticsEvent('Modal Viewed', {
      modalTitle: i18n.t('boostModals.specifiedItems.title'),
    });
  }, []);

  useEffect(() => {
    setBoostWithModifiedPrice(prevState => {
      if (prevState) {
        return { ...prevState, isAdded: isEditMode };
      }
    });
  }, [isEditMode]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
    watch,
  } = useForm<SpecifiedBoostItem>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      itemName: '',
      selectedValue: startingPrice,
      travellerId: primaryTravellerOption?.id ?? '',
    },
  });

  const onTravellerSelect = useCallback(
    (sortKey: unknown, isValidating = true) => {
      setValue('travellerId', sortKey as string);
      // revalidate item when secondary traveller changes
      isValidating && trigger('itemName');
    },
    [setValue, trigger],
  );

  const onPriceRangeChange = useCallback(
    (values: number[]) => {
      setThumbPosition(values);
      const indexToUse = values[0];
      const selectedValue = priceValues?.[indexToUse] ?? 0;
      setValue('selectedValue', selectedValue);
      const basePriceRange = priceValues?.[0];

      if (basePrice && basePriceRange) {
        const newPrice = `${(selectedValue / basePriceRange) * basePrice}`;
        setBoostWithModifiedPrice(prevState =>
          prevState ? { ...prevState, price: newPrice } : undefined,
        );
      }
    },
    [priceValues, setValue, basePrice],
  );

  const onSubmit = (
    buttonType: 'ADD_ITEM_EDIT_MODAL' | 'ADD_ITEM_ADD_MODAL' | 'SAVE_EDIT_MODAL',
  ) => {
    if (buttonType === 'SAVE_EDIT_MODAL') {
      return closeModal();
    }

    return handleSubmit(async (data: SpecifiedBoostItem) => {
      if (!boost?.boostId) {
        return;
      }

      setIsAddingLoading(true);
      await addSpecifiedItem({
        boostId: boost?.boostId,
        specifiedBoostItemToAdd: data,
      });

      if (buttonType === 'ADD_ITEM_ADD_MODAL') {
        sendAnalyticsEvent('Specified item added');
        closeModal();
      }

      if (buttonType === 'ADD_ITEM_EDIT_MODAL') {
        // Reset the ui
        onTravellerSelect(primaryTravellerOption?.id, false);
        onPriceRangeChange([0]);
        // reset the form values
        reset({
          itemName: '',
          selectedValue: startingPrice,
          travellerId: primaryTravellerOption?.id ?? '',
        });
      }
      setIsAddingLoading(false);
    })();
  };

  return (
    <BoostModalContainer
      isBoostModalFooterDisabled={isAddingLoading}
      boost={boostWithModifiedPrice}
      onSubmit={async () => await onSubmit(isEditMode ? 'SAVE_EDIT_MODAL' : 'ADD_ITEM_ADD_MODAL')}
      titleProps={{
        children: i18n.t('boostModals.specifiedItems.title'),
      }}>
      <Input
        errorMessage={errors?.itemName?.message}
        label={i18n.t('boostModals.specifiedItems.itemInputLabel')}
        placeholder={i18n.t('boostModals.specifiedItems.itemInputPlaceholder')}
        {...testProps('input-item-name')}
        {...register('itemName', {
          required: 'This is required.',
          minLength: { value: 4, message: 'Item needs to be more than 4 characters' },
          validate: {
            default: value => {
              let result = validate('name', value);
              if (result) return result;
              result = validateEmojis('itemName', value);
              if (result) return result;
            },
            unique: value => {
              const travellerId = watch('travellerId');
              const isUnique = boost?.boostProperty?.specifiedItems
                ?.filter(it => it.travellerId === travellerId)
                ?.every(
                  item => item.itemName?.toLowerCase()?.trim() !== value?.toLowerCase()?.trim(),
                );

              if (!isUnique)
                return 'It looks like you already have a similar item with the same name. Please provide a unique name for this item to avoid any confusion.';
            },
          },
          onBlur: e => setValue('itemName', e.target.value.trim()),
        })}
      />
      {travellerOptions && primaryTravellerOption && (
        <Dropdown
          numberOfItems={itemsToDisplayInDropdown}
          items={travellerOptions}
          inputProps={{
            placeholder: i18n.t('boostModals.specifiedItems.travellerInputPlaceholder'),
            rightIcon: (props: ButtonProps) => (
              <Dropdown.Button as={Button} {...testProps('dropdown-btn')} {...props} />
            ),
          }}
          onSelect={onTravellerSelect}
          initialValue={primaryTravellerOption}
          className="mt-3 mb-6"
        />
      )}
      {priceValues && (
        <div>
          <Text className="mb-6" variant="subTitle-20/m">
            {i18n.t('boostModals.specifiedItems.estimateItemValue')}
          </Text>
        </div>
      )}
      <div className="mb-5">
        <div className="ml-4 my-6">
          {priceValues && priceValues.length > 0 && (
            <Range
              step={1}
              min={minValue}
              max={maxValue}
              values={thumbPosition}
              onChange={onPriceRangeChange}
              renderTrack={({ props, children }) => (
                <div {...props} className="h-1 bg-fuji-300 opacity-40">
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    boxShadow: '1px 2px 9px #667382',
                  }}
                  className="focus-within:outline-nusa-200 cursor-pointer focus-within:outline-offset-1 rounded-full h-7 w-7 bg-mono-100"
                />
              )}
            />
          )}
        </div>
        <Text variant="body-16/sb">${priceValues?.[thumbPosition?.[0]] ?? 0}</Text>
      </div>
      {isEditMode ? (
        <Button
          isLoading={isAddingLoading}
          variant="feature"
          onClick={async () => await onSubmit('ADD_ITEM_EDIT_MODAL')}
          className="mb-6"
          title={i18n.t('boostDetails.specifiedItems.primaryButton.add')}
        />
      ) : null}
      <SpecifiedItemsList boostId={boost?.boostId} showButton={false} />
    </BoostModalContainer>
  );
};
