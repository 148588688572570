import { i18n } from '@i18n';
import { openModalSelector, useModalStore } from '@store';

export function useTravellersInfoModal() {
  const openModal = useModalStore(openModalSelector);
  return {
    openMoreInfoModal(modalType?: string) {
      const moreInfoModalI18Text = modalType
        ? i18n.t('travellers.dependent.modal.title')
        : i18n.t('travellers.residentStatus.modal.title');
      return openModal('Modal', {
        titleText: moreInfoModalI18Text,
        body: {
          type: 'MoreInfoModalBody',
          modalType,
        },
        actions: [
          {
            title: i18n.t('travellers.residentStatus.modal.actions.ok'),
            variant: 'primary',
          },
        ],
      });
    },
  };
}
