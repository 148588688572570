import { AU_STATES, REGIONS, US_STATES } from '@packages/constants';
import {
  AddressComponent,
  GooglePlaceAddressData,
  ManualAddressFormInterface,
  RegionCode,
} from '@packages/types';

/**
 * Extracts the state code from an array of address components
 * @param addressComponents
 */
export function getStateFromAddressComponent(
  addressComponents: AddressComponent[] | undefined,
): string | undefined {
  return addressComponents?.find(it => it.types.includes('administrative_area_level_1'))
    ?.short_name;
}

export function constructGoogleAddressData(
  userAddress: string | null | undefined,
  addressDetails: AddressComponent[] | null | undefined,
): null | GooglePlaceAddressData {
  if (!userAddress || !addressDetails) {
    return null;
  }

  return {
    data: {
      description: userAddress,
    },
    detail: {
      address_components: addressDetails,
    },
  };
}

export function hasLocality(addressComponents: AddressComponent[]): boolean {
  return addressComponents.some(c => c.types.includes('locality'));
}
export function hasState(addressComponents?: AddressComponent[]): boolean {
  const state = addressComponents?.find(c => c.types.includes('administrative_area_level_1'));
  return !!state?.long_name || !!state?.short_name;
}
export function hasAddressLine1(addressComponents?: AddressComponent[]): boolean {
  const addressLine1 = addressComponents?.find(
    c => c.types.includes('street_number') || c.types.includes('route'),
  );
  return !!addressLine1?.long_name || !!addressLine1?.short_name;
}

export function hasPostalCode(addressComponent?: AddressComponent[]) {
  const postalCode = addressComponent?.find(c => c?.types?.includes?.('postal_code'));
  return !!postalCode?.long_name;
}

export function hasNeighborHoodOrLocality(addressComponent?: AddressComponent[]) {
  return addressComponent?.some(
    c => c.types.includes('locality') || c.types.includes('neighborhood'),
  );
}

export function getNeighborhoodAddressComponent(addressComponent?: AddressComponent[]) {
  return addressComponent?.find(c => c.types.includes('neighborhood'));
}

export function constructAddressData({
  formData,
  countryCode,
  countryName,
  country,
}: {
  formData: ManualAddressFormInterface;
  countryCode: string;
  countryName: string;
  country?: RegionCode | undefined;
}): GooglePlaceAddressData | null {
  const { addressLine1, addressLine2, addressCity, addressPostCode, addressState } = formData;
  if (
    !addressLine1 ||
    !addressCity ||
    !addressState ||
    !addressPostCode ||
    !countryCode ||
    !countryName
  ) {
    return null;
  }
  let addressComponents = [
    { long_name: countryName, short_name: countryCode, types: ['country', 'political'] },
    { long_name: addressPostCode, short_name: addressPostCode, types: ['postal_code'] },
    { long_name: addressCity, short_name: addressCity, types: ['locality', 'political'] },
  ];

  const STATES = country === REGIONS.AU.code ? AU_STATES : US_STATES;

  const { code: stateCode, name: stateName } = STATES?.find(
    state => state.code === addressState,
  ) ?? {
    code: '',
    name: '',
  };

  addressComponents = [
    ...addressComponents,
    {
      long_name: addressLine1,
      short_name: addressLine1,
      types: ['street_number'],
    },
    {
      long_name: stateName,
      short_name: stateCode,
      types: ['administrative_area_level_1', 'political'],
    },
  ];

  if (addressLine2) {
    addressComponents = [
      ...addressComponents,
      {
        long_name: addressLine2,
        short_name: addressLine2,
        types: ['route'],
      },
    ];
  }

  return {
    data: {
      description: `${addressLine1}, ${
        addressLine2 ? addressLine2 + ',' : ''
      } ${addressCity}, ${addressState}, ${addressPostCode}, ${countryName}`,
    },
    detail: {
      address_components: addressComponents,
    },
  };
}

export const getStripeAddress = (
  addressComponents?: AddressComponent[],
): {
  city?: string;
  country?: string;
  line1: string;
  line2?: string;
  postalCode?: string;
  state?: string;
} => {
  const line1 = addressComponents?.find(c => c.types.includes('street_number'))?.long_name;
  const line2 = addressComponents?.find(c => c.types.includes('route'))?.long_name;
  const city = addressComponents?.find(c => c.types.includes('locality'))?.long_name ?? '';
  const state = addressComponents?.find(c =>
    c.types.includes('administrative_area_level_1'),
  )?.short_name;
  const country = 'AU';
  const postalCode = addressComponents?.find(c => c.types.includes('postal_code'))?.long_name ?? '';
  return {
    line1: (line1?.length ? line1 + ' ' + line2 : line2) ?? '',
    city,
    state,
    country,
    postalCode,
  };
};
