import { twMerge } from 'tailwind-merge';

import { Text } from '@components/text';
import { i18n } from '@i18n';
import {
  allBoostExcludingEMCCountSelector,
  selectedBoostsExcludingEMCCountSelector,
  useTripStore,
} from '@store';

type BoostSelectionType = 'boostNoneSelected' | 'boostSomeSelected' | 'boostAllSelected';

const determineActiveLegalType = (selectedCount: number, allCount: number): BoostSelectionType => {
  if (selectedCount < allCount && selectedCount > 0) {
    return 'boostSomeSelected';
  }
  if (selectedCount === 0) {
    return 'boostNoneSelected';
  }
  return 'boostAllSelected';
};

const BoostSelectionLegalItem = () => {
  const selectedBoostsCount = useTripStore(selectedBoostsExcludingEMCCountSelector);
  const allBoostsCount = useTripStore(allBoostExcludingEMCCountSelector);
  const activeLegalType = determineActiveLegalType(selectedBoostsCount, allBoostsCount);
  const legalListItems = i18n.t('checkout.legal', { returnObjects: true });

  const item = legalListItems.find(i => i?.type === activeLegalType);
  if (!item) {
    return null;
  }
  return (
    <li className="mb-8 first:mt-6 last:mb-6">
      <Text
        className={twMerge('mb-2', item?.type === 'boostNoneSelected' && 'text-red-500')}
        variant={'body-16/sb'}>
        {item?.title}
      </Text>
      <Text className="mb-2" variant={'body-16/m'}>
        {item?.description}
      </Text>
    </li>
  );
};

const StaticLegalItems = () => {
  const legalListItems = i18n.t('checkout.legal', { returnObjects: true }).filter(i => !i?.type);

  return (
    <>
      {legalListItems.map(item => (
        <li key={item?.title} className="mb-8 first:mt-6 last:mb-6">
          <Text className="mb-2" variant={'body-16/sb'}>
            {item?.title}
          </Text>
          <div className="[&_a]:text-fuji-600">
            <Text className="mb-2" variant={'body-16/m'} withMarkDown>
              {item?.description}
            </Text>
          </div>
          {item?.footer && (
            <Text
              className={twMerge(
                'bg-cabo-50 mt-2 inline-block rounded-full px-6 py-2 cursor-pointer [&>a]:no-underline',
              )}
              variant={'body-16/sb'}
              withMarkDown>
              {item?.footer}
            </Text>
          )}
        </li>
      ))}
    </>
  );
};

export const LegalConsentListItems = () => {
  return (
    <ul className="flex-1">
      <BoostSelectionLegalItem />
      <StaticLegalItems />
    </ul>
  );
};
