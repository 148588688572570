import { useEffect } from 'react';

import { BOOST_CATEGORY } from '@packages/types';
import { filterIneligiblePreCancellation } from '@packages/utils';

import { BoostListItem, Text } from '@components';
import { i18n } from '@i18n';
import { getFilteredBoostsByCategorySelector, useTripStore } from '@store';

import { CoverPopover } from '../coverPopover/coverPopover.component';

export const ExtraCoverSection = () => {
  const myStuffBoost = useTripStore(getFilteredBoostsByCategorySelector(BOOST_CATEGORY.MY_STUFF));
  const boostInfoExtra = i18n.t('boostShared.info');
  // handle scroll to i18Text
  useEffect(() => {
    const hash = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash === 'extras') {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const extraCoverageText = i18n.t('myQuote.extraCoverage.title');
  // extract text from (here)
  const items = extraCoverageText?.split(/([()])/);
  const title = items?.[0].trim();
  const bracketText = items?.slice(1).join('');

  return (
    <>
      <a id="extras" />
      <div className="w-full my-6 border-t" />

      <div className="flex flex-row flex-wrap mb-2 justify-between">
        <div>
          <Text
            variant={{
              sm: 'h4-24/sb',
              lg: 'h3-28/sb',
            }}
            className="pr-1 md:pr-2">
            {title}
          </Text>
          <Text
            variant={{
              sm: 'h4-24/m',
              lg: 'h4-24/r',
            }}>
            {bracketText}
          </Text>
        </div>
        <CoverPopover title={boostInfoExtra} className="hidden md:block" boosts={myStuffBoost} />
      </div>
      <Text variant={{ sm: 'body-16/r', lg: 'body-16/r' }} className="mb-6">
        {i18n.t('myQuote.extraCoverage.subtitle')}
        <CoverPopover title={boostInfoExtra} className="block md:hidden" boosts={myStuffBoost} />
      </Text>

      {myStuffBoost
        ?.filter(filterIneligiblePreCancellation)
        ?.map(it => <BoostListItem boost={it} key={it.boostId} />)}
    </>
  );
};
