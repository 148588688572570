import { useState } from 'react';

import { wait } from '@packages/utils';

import { Button } from '@elements/button';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import {
  closeModalSelector,
  getTrip,
  openModalSelector,
  saveScreeningResultAction as saveScreeningResult,
  useBoostDetailsStore,
  useEmcStore,
  useModalStore,
} from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

export const EmcScreeningResultsFooterButtons = () => {
  const { emcCurrentTraveller } = useEmcTraveller();
  const { isCovered, isAdded } = emcCurrentTraveller ?? {};
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const removeNameChangedTraveller = useEmcStore(state => state.removeNameChangedTraveller);
  const [isEmcIncludedPending, setIsEmcIncludedPending] = useState(false);

  const handleContinue = async () => {
    removeNameChangedTraveller(emcDeclaring?.travellerId ?? '');
    setOpenEmcSection('emc-email-assessment');
  };

  const handleDeclineCover = () => {
    openModal('FullScreenModal', {
      body: {
        type: 'EMCDecline',
        primaryAction: async () => {
          closeModal();
          await wait(1);
          setOpenEmcSection(undefined);
          if (isAdded) {
            await saveScreeningResult({
              isAdded: false,
              isSelected: true,
            });
            await getTrip();
          }
        },
        secondaryAction: () => {
          closeModal();
        },
      },
    });
  };

  const handleIncludeCover = async (isAdded = true) => {
    if (!emcCurrentTraveller?.isAdded) {
      setIsEmcIncludedPending(true);
      await saveScreeningResult({
        isAdded,
        isSelected: true,
      });
      await getTrip();
      sendAnalyticsEvent('Cover Added', {
        TravellerID: emcCurrentTraveller?.travellerId ?? '',
        Premium: emcCurrentTraveller?.price ?? '',
      });
      setIsEmcIncludedPending(false);
    }
    return setOpenEmcSection(undefined);
  };

  if (!isCovered) {
    return (
      <Button
        className="mt-10 w-full md:w-auto md:min-w-[18rem] mb-10"
        variant="primary"
        onClick={handleContinue}
        title={i18n.t('emcShared.screeningResult.button.rejected.primary')}
        {...testProps('btn-emc-rejected-primary')}
      />
    );
  }
  if (emcDeclaring?.requireReDeclare) {
    return (
      <Button
        className="mt-10 w-full md:w-auto md:min-w-[18rem] mb-10"
        variant="primary"
        onClick={() => handleIncludeCover(!!isAdded)}
        title={i18n.t('emcShared.screeningResult.button.redeclaring')}
        isLoading={isEmcIncludedPending}
        {...testProps('btn-emc-redeclaring')}
      />
    );
  }
  return (
    <div className="flex flex-col flex-col-reverse w-full md:flex-row md:justify-end md:space-x-4">
      <Button
        className="mb-4 md:w-full lg:w-auto"
        variant="secondary"
        onClick={handleDeclineCover}
        title={i18n.t('emcShared.screeningResult.button.covered.secondary')}
        {...testProps('btn-covered-secondary')}
      />
      <Button
        className="mb-4 md:w-full lg:w-auto"
        variant="primary"
        onClick={() => handleIncludeCover()}
        title={i18n.t('emcShared.screeningResult.button.covered.primary')}
        isLoading={isEmcIncludedPending}
        {...testProps('btn-covered-primary')}
      />
    </div>
  );
};
