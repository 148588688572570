import { RouterProvider } from 'react-router-dom';

import { Loader, Maintenance, ModalNavigator, Text, ToastsBoard } from '@components';
import ErrorBoundary from '@components/errorBoundary/errorBoundary.component';
import { useAppInit, useGuestSubscription } from '@hooks';
import { router } from '@routes/router';
import { initSentry } from '@utils';

initSentry();

export const App = () => {
  useGuestSubscription();

  const { isInitialised, isUnderMaintenance } = useAppInit();

  if (!isInitialised) {
    return (
      <div className="flex h-screen w-screen  items-center justify-center gap-4">
        <Loader />
        <Text variant="h2-36/sb">Loading...</Text>
      </div>
    );
  }

  let content = (
    <>
      <RouterProvider router={router} />
      <ModalNavigator />
      <ToastsBoard />
    </>
  );

  if (isUnderMaintenance) {
    content = <Maintenance />;
  }

  return <ErrorBoundary>{content}</ErrorBoundary>;
};
