import currency from 'currency.js';
import { CountryCode, validatePhoneNumberLength } from 'libphonenumber-js';
import lowerCase from 'lodash/lowerCase';
import type { DateTime } from 'luxon';
import validation from 'validate.js';

import { REGIONS } from '@packages/constants';
import {
  PhoneNumberValidationRule,
  RegionValidationRules,
  SecondaryTraveller,
  TripDestination,
} from '@packages/types';

import { getAge as getAgeForWeb, isInPast as isInPastForWeb } from '../datetime/datetime';
import { CurrencyCodeISOList } from '../i18n/i18n';
import { deepCompareTripDestinations } from '../trip/common';

/**
 * constraint type for validate.js since there are no types for it
 * @see https://validatejs.org/#constraints
 * TODO: depreciate in favor of zod
 * */

type FieldName =
  | 'firstName'
  | 'lastName'
  | 'name'
  | 'cardHolderName'
  | 'email'
  | 'password'
  | 'onboardingPassword'
  | 'verificationCode'
  | 'signInPassword'
  | 'addressLine'
  | 'addressLine2'
  | 'claimCountry'
  | 'boostSpecifiedItemDescription'
  | 'profileDOB';

type ConstraintsType = {
  [K in FieldName]: {
    presence: {
      allowEmpty: boolean;
      message: string;
    };
    length?: {
      minimum?: number;
      maximum?: number;
      message: string;
    };
    format?: {
      pattern: string;
      flags: 'i';
      message: string;
    };
    email?: {
      message: string;
    };
  };
};

const countries = Object.values(REGIONS);
export const constraints: ConstraintsType = {
  firstName: {
    presence: {
      message: '^Please enter a valid first name.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^A minimum length of one character is required.',
    },
    format: {
      pattern: "^(?!.*--)[a-zA-Z_’'\\s-]+$",
      flags: 'i',
      message: '^Please enter a valid first name.',
    },
  },
  lastName: {
    presence: {
      message: '^Please enter a valid last name.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^A minimum length of one character is required.',
    },
    format: {
      pattern: "^(?!.*--)[a-zA-Z_’'\\s-]+$",
      flags: 'i',
      message: '^Please enter a valid last name.',
    },
  },
  name: {
    presence: {
      message: '^Please enter a valid name.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^A minimum length of one character is required.',
    },
    format: {
      pattern: "^(?!.*--)[a-zA-Z_’'\\s-]+$",
      flags: 'i',
      message: '^Please enter a valid name.',
    },
  },
  cardHolderName: {
    presence: {
      message: '^Please provide the name as it appears on your card.',
      allowEmpty: false,
    },
    length: {
      minimum: 2,
      message: '^Minimum of 2 characters for cardholder name.',
    },
    format: {
      pattern: "^(?!.*--)[a-zA-Z_’'\\s-]+$",
      flags: 'i',
      message: '^Please provide the name as it appears on your card.',
    },
  },
  email: {
    presence: {
      message: '^Please enter your email.',
      allowEmpty: false,
    },
    email: {
      message: '^Please enter a valid email address.',
    },
  },
  password: {
    presence: {
      message: '^Please enter your password.',
      allowEmpty: false,
    },
  },
  onboardingPassword: {
    presence: {
      message: '^Please enter a password.',
      allowEmpty: false,
    },
    length: {
      minimum: 8,
      maximum: 64,
      message: '^Password must be at least 8 characters.',
    },
    format: {
      pattern: '^(?=.*[a-z])(?=.*\\d)(?=.*[!@#$%^&*]).{8,64}$',
      flags: 'i',
      message: '^Password must contain at least one letter and number and one symbol from !@#$% .',
    },
  },
  verificationCode: {
    presence: {
      message: '^Please enter the verification code.',
      allowEmpty: false,
    },
    length: {
      minimum: 6,
      message: '^Verification code must be a 6 digit number.',
    },
    format: {
      pattern: '^[0-9]+$',
      flags: 'i',
      message: '^Please enter a valid verification code.',
    },
  },
  signInPassword: {
    presence: {
      message: '^Please enter your password.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^Please enter your password.',
    },
  },
  addressLine: {
    presence: {
      message: '^Address is required.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^Address is required!',
    },
  },
  addressLine2: {
    presence: {
      message: '^Address line 2 is invalid.',
      allowEmpty: true,
    },
    format: {
      pattern: '^(?:\\s*\\S.*|)$',
      flags: 'i',
      message: '^Address line 2 is invalid.',
    },
  },
  claimCountry: {
    presence: {
      message: '^Please enter the country.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^Please enter a valid country.',
    },
    format: {
      pattern:
        '^[^0-9\\u00a9\\u00ae\\u2000-\\u3300\\ud83c\\ud000-\\udfff\\ud83d\\ud000-\\udfff\\ud83e\\ud000-\\udfff]*$',
      flags: 'i',
      message: '^Please enter a valid country.',
    },
  },
  boostSpecifiedItemDescription: {
    presence: {
      message: '^Please enter item name or description.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^Please enter item name or description.',
    },
  },
  profileDOB: {
    presence: {
      message: '^Please enter date of birth.',
      allowEmpty: false,
    },
    length: {
      minimum: 1,
      message: '^Please enter date of birth.',
    },
  },
};

export const validate = (fieldName: FieldName, value?: string) => {
  // Validate.js validates your values as an object
  // e.g. var form = {email: 'email@example.com'}
  // Line 8-9 creates an object based on the field name and field value
  const formValues = { [fieldName]: value };

  // Line 13-14 creates an temporary form with the validation fields
  // e.g. var formFields = {
  //                        email: {
  //                         presence: {
  //                          message: 'Email is blank'
  //                         }
  //                       }
  const formFields = { [fieldName]: constraints[fieldName] };

  // The formValues and validated against the formFields
  // the variable result hold the error messages of the field
  const result: undefined | { [K in FieldName]: string[] } = validation(formValues, formFields);

  // If there is an error message, return it!
  if (result) {
    // Return only the field error message if there are multiple
    return result[fieldName][0];
  }

  return;
};

/**
 * checks if state is from in country
 * @param countryCode e.g. 'AU' or 'US'
 * @param state e.g. 'NSW' or 'CA'
 * @returns undefined | error message (string)
 */
export const validateState = (countryCode: string, state?: string) => {
  const countryInfo = countries.find(it => countryCode === it.code);

  if (countryInfo) {
    const result = countryInfo.states.some(it => it.code === state?.toUpperCase());
    if (result) {
      return;
    } else {
      return `Valid state are ${countryInfo.states.map(item => ` ${item.code}`)}`;
    }
  }
  return;
};

/**
 * validates postcode rules from remote configuration
 * @param rules rules from remote configuration
 * @param postCode postcode to validate
 * @returns undefined | error message (string)
 */
export const validatePostCode = (rules: RegionValidationRules, postCode?: string) => {
  if (rules) {
    const regex = new RegExp(rules.postcodeRegex);
    const result = regex.exec(postCode ?? '');

    if (result === null) {
      return rules.postCodeRegexMessage;
    }
  }

  return;
};

/**
 * checks if country currency symbol == currency
 * @param countryCurrencySymbol country currency symbol
 * @param currency country currency symbol
 * @returns null | error message (string)
 */
export const validateCurrencyByCountry = (countryCurrencySymbol: string, currency?: string) => {
  if (!currency) {
    return 'Please choose the currency you would like to claim.';
  }

  if (countryCurrencySymbol) {
    if (countryCurrencySymbol.toUpperCase() !== currency.toUpperCase()) {
      return `Valid currency is ${countryCurrencySymbol}.`;
    } else {
      return;
    }
  }
  return `Country currency symbol ${countryCurrencySymbol} not found.`;
};

/**
 * check if currency code is valid
 * @param currency currency to validate
 * @returns null | error message (string)
 */
export const validateCurrency = (currency?: string) => {
  if (!currency) {
    return 'Please choose the currency you would like to claim.';
  }
  const result = CurrencyCodeISOList.indexOf(currency.toUpperCase()) >= 0;
  if (result) {
    return null;
  } else {
    return `Currency ${currency} is invalid.`;
  }
};

export const validatePhoneNumber = (
  rules:
    | Pick<PhoneNumberValidationRule, 'phoneNumberRegex' | 'phoneNumberRegexMessage'>
    | undefined,
  phoneNumber: string | undefined,
  errorMessage?: string,
) => {
  if (!phoneNumber) {
    return 'Please enter a valid phone number.';
  }
  if (!rules) {
    return 'Validation rules not found.';
  }

  const regex = new RegExp(rules.phoneNumberRegex);
  const result = regex.exec(phoneNumber.replace(/\s/g, ''));

  if (result === null) {
    return errorMessage ?? rules.phoneNumberRegexMessage;
  }

  return undefined;
};

export const validateIntlPhoneNumber = (
  phone: string,
  countryCode: CountryCode,
  rule?: Pick<PhoneNumberValidationRule, 'phoneNumberRegex' | 'phoneNumberRegexMessage'>,
) => {
  if (rule) {
    return validatePhoneNumber(rule, phone);
  }

  const result = validatePhoneNumberLength(phone, countryCode);
  return result ? `Phone number error: ${lowerCase(result)}` : undefined;
};

/**
 * Emojis are not allowed in the username
 * @param value string to validate
 * @returns null | error message (string)
 */
export const validateEmojis = (fieldName: string, value: string | null | undefined) => {
  if (!value) {
    return `${fieldName}`;
  }
  const regex = new RegExp(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/,
  );
  const result = regex.exec(value);
  if (result === null) {
    return;
  }
  return 'Emoji is not allowed.';
};
/**
 * check if 'YYYY-MM-DD' date is valid
 * @param dateStr date string to validate in format 'YYYY-MM-DD'
 * @returns boolean
 */
export function dateIsValid(dateStr: string | undefined) {
  if (!dateStr) {
    return false;
  }

  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
}

/**
 * valid dates are in format 'YYYY-MM-DD'
 * @param value string to validate
 * @returns undefined | error message (string)
 */
export const validateDate = (value: string | undefined, customMessage?: string) => {
  if (!dateIsValid(value)) {
    return customMessage ?? 'Invalid Date';
  }
  return;
};

/**
 * Validates the number of destinations a user can select
 * @param destinations
 * @param maxDestinations
 */
export function validateDestinations(destinations: TripDestination[], maxDestinations = 10) {
  const isWithinLimit = destinations.length > 0 && destinations.length <= maxDestinations;

  if (destinations.length === 0 || !destinations.length) {
    return 'Please select at least one destination';
  }

  if (!isWithinLimit) {
    return 'Reached max destinations';
  }

  return;
}

/**
 * Validation of primary traveller (user) age
 * @param dateOfBirth string or DateTime object
 * @param params destructred object for min, max age, and country
 * @returns
 */
export const primaryAgeValidation = (
  dateOfBirth: DateTime | string | undefined,
  { max = 99, min = 18, country = REGIONS.AU.code } = {},
  customMessage?: string,
) => {
  const age = dateOfBirth ? getAgeForWeb(dateOfBirth, country) : 0;

  // should never happen due to getAgeForWeb
  if (age === undefined) {
    return 'Incorrect age!';
  }

  if (age < min || age > max) {
    return customMessage ?? `You must be between ${min} and ${max} years of age.`;
  }
  return;
};

/**
 * validation of date of birth for secondary traveller
 * @param dateOfBirth
 * @param param1 destructred object for min, max age, and country
 * @returns undefined | error message if date of birth invalid
 */
export const secondaryAgeValidation = (
  dateOfBirth: string | undefined,
  { max = 99, min = 0, country = REGIONS.AU.code } = {},
  customMessage?: string,
) => {
  if (dateOfBirth && !isInPastForWeb(dateOfBirth, country)) {
    return 'Date of birth must be in the past!';
  }

  if (!dateOfBirth) {
    return 'Please provide a date of birth.';
  }

  const age = dateOfBirth ? getAgeForWeb(dateOfBirth, country) : 0;

  let regionSpecificTraveller = 'Traveller';

  if (country === REGIONS.US.code) {
    regionSpecificTraveller = 'Traveler';
  }

  if (age < min) {
    return customMessage ?? `${regionSpecificTraveller} cannot be under ${min} year of age.`;
  }

  if (age > max) {
    return customMessage ?? `${regionSpecificTraveller} cannot be over ${max} years of age.`;
  }

  return;
};

/**
 * Validates if the expiry date is in the correct format and not in the past.
 * @param expiryDate - format: MM/YY
 * @param currentDate - Current date for comparison
 * @param errorMessageIncomplete - Error message if the date is incomplete
 * @param errorMessagePast - Error message if the date is in the past
 * @returns Error message or undefined if valid
 */
export const validateExpiryDateWeb = (
  expiryDate: string,
  currentDate: DateTime,
  errorMessageIncomplete = "Your card's expiration date is incomplete.",
  errorMessagePast = "Your card's expiration year is in the past.",
) => {
  if (!expiryDate) {
    return errorMessageIncomplete;
  }

  // Expiry date needs to be in MM/YY format
  if (!/^\d{2}\/\d{2}$/.test(expiryDate)) {
    return errorMessageIncomplete;
  }

  const [month, year] = expiryDate.split('/');
  if (!month || !year) {
    return errorMessageIncomplete;
  }
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(currentDate.year.toString().substring(0, 2) + year, 10);

  // Expiry month should be between 1 and 12
  if (monthInt < 1 || monthInt > 12) {
    return errorMessageIncomplete;
  }

  // Check if the expiry date is in the past
  if (
    yearInt < currentDate.year ||
    (yearInt === currentDate.year && monthInt < currentDate.month)
  ) {
    return errorMessagePast;
  }

  return true;
};

/**
 * check if date is in the past
 * @param expiryDate format: MM/YY
 * @param currentDate
 * @param errorMessage error message to return if invalid
 * @returns
 */
export const validateExpiryDate = (
  expiryDate: string,
  currentDate: DateTime,
  errorMessage = 'Expired',
) => {
  if (!expiryDate) {
    return errorMessage;
  }

  //expiry date needs be length of 5 eg. 01/20 (MM/YY)
  if (expiryDate.length !== 5) {
    return errorMessage;
  }
  const [month, year] = expiryDate.split('/');
  const monthInt = parseInt(month ?? '', 10);
  //expiry month should be between 1 and 12
  if (monthInt > 12 || monthInt < 1) {
    return errorMessage;
  }
  const yearInt = parseInt(currentDate.year.toString().substring(0, 2) + year, 10);

  //expiry year should be greater than current year
  //if expiry year is same as current year, then expiry month should be equal or greater than current month
  if (
    yearInt < currentDate.year ||
    (yearInt === currentDate.year && monthInt < currentDate.month)
  ) {
    return errorMessage;
  }

  return;
};

/**
 *
 *
 * @param phoneNumber
 * @param isUS
 * @param prefix
 */
export const validateRegionSpecificPhoneNumber = (
  phoneNumber: string,
  isUS: boolean,
  prefix: string | undefined,
) => {
  if (!(isUS ? /^\d{10}$/g : /^\d{9}$/g).test(phoneNumber)) {
    return `Incorrect phone number. Please provide a correct number eg. ${
      isUS ? `${prefix} 844-246-8480` : `${prefix} 412345678`
    }`;
  }
  return;
};

/**
 * Used to validate the deposit date
 * Specific to Ecomm as it checks for user flow when the modal is opened
 @param isModal
 * @param selectedDepositDate
 * @param tripDepositDate
 * @param isModal
 */
export const validateDepositDate = ({
  selectedDepositDate,
  isModal = false,
  tripDepositDate,
}: {
  selectedDepositDate: string | undefined;
  isModal?: boolean;
  tripDepositDate: string | undefined;
}) => {
  if (!selectedDepositDate) {
    return 'Deposit date not entered';
  }

  if (isModal && tripDepositDate === selectedDepositDate) {
    return 'Deposit date not changed';
    // if modal, we have already selected the deposit date and must compare to what is in the trip store
  }

  return;
};

/**
 *Used to confirm if user can proceed with destinations
 Specific to Ecomm as it checks for user flow when the modal is opened
 * @param isModal
 * @param formTripDestinations
 * @param tripDestinations
 */
export const validateDestinationsSubmitButton = ({
  isModal,
  formTripDestinations,
  tripDestinations,
}: {
  isModal?: boolean;
  formTripDestinations: TripDestination[];
  tripDestinations: TripDestination[];
}) => {
  if (isModal) {
    const canEdit = !deepCompareTripDestinations(formTripDestinations, tripDestinations);
    if (!canEdit) {
      return 'Destinations not changed';
    }
  }
  return;
};

type ValidateTravelDatesSubmitButton = {
  isModal?: boolean;
  startDate: string;
  endDate: string;
  tripStartDate: string;
  tripEndDate: string;
};

/**
 * Used to confirm if user can proceed with travel dates
 * Specific to Ecomm as it checks for user flow when the modal is opened
 * @param isModal
 * @param endDate
 * @param tripEndDate
 * @param tripStartDate
 * @param startDate
 */
export function validateTravelDatesSubmitButton({
  isModal,
  endDate,
  tripEndDate,
  tripStartDate,
  startDate,
}: ValidateTravelDatesSubmitButton) {
  const canProgress = !!startDate && !!endDate;
  const canUpdate = startDate !== tripStartDate || endDate !== tripEndDate;

  if (isModal && !canUpdate) {
    return 'trip dates not changed';
  }

  if (!canProgress) {
    return 'trip dates not selected';
  }

  return;
}

/**
 * Used to confirm if user can proceed with trip cost
 * specific to Ecomm as it checks for user flow when the modal is opened
 * @param tripCost
 * @param formTripCost
 * @param isModal
 */
export function validateTripCostButton({
  tripCost,
  formTripCost,
  isModal,
}: {
  isModal?: boolean;
  tripCost?: string | number | null;
  formTripCost?: string | number;
}) {
  const tripCostValue = currency(tripCost ?? 0).intValue;
  const formTripCostValue = currency(formTripCost ?? 0).intValue;

  if (isModal && tripCostValue === formTripCostValue) {
    return 'trip cost not changed';
  }

  if (!formTripCostValue) {
    return 'trip cost not entered';
  }

  return;
}

/**
 *Used to confirm if all travellers are residents for AU policies
 * @param formPrimaryTravellerIsResident
 * @param formSecondaryTravellers
 */
export function validateResidencyCheckBox({
  formPrimaryTravellerIsResident,
  formSecondaryTravellers,
}: {
  formPrimaryTravellerIsResident: boolean;
  formSecondaryTravellers?: SecondaryTraveller[];
}) {
  if (!formPrimaryTravellerIsResident) {
    return 'Please confirm that all travellers are residents';
  }

  if (formSecondaryTravellers?.length && formSecondaryTravellers.some(it => !it.isResident)) {
    return 'Please confirm that all secondary travellers are residents';
  }

  return;
}

/**
 * This function is used to revalidate the dependency checkbox when the dob is changed
 * @param formattedDate
 * @param residentCheckBoxStatus
 */
export function shouldRevalidateDependencyFlag(
  formattedDate: string,
  residentCheckBoxStatus: boolean,
) {
  return !validateDate(formattedDate) && residentCheckBoxStatus;
}
