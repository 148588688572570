import { FC } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import rhypeRaw from 'rehype-raw';

export const CustomMarkdown: FC<Options> = ({ children, ...rest }) => {
  return (
    <ReactMarkdown
      {...rest}
      components={{
        a: ({ ...props }) => (
          <a className="underline" {...props} target="_blank" rel="noreferrer" />
        ),
        p: ({ children }) => <>{children}</>,
      }}
      rehypePlugins={[rhypeRaw]}>
      {children}
    </ReactMarkdown>
  );
};
