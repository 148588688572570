export const primitiveColors = {
  // Primitive colors freely core v2
  nusa: {
    50: '#E9FFF7',
    100: '#CBFFE9',
    200: '#AAFFDE',
    300: '#5BFAC6',
    400: '#1BECAF',
    500: '#00D499',
    600: '#00AD7F',
    700: '#008A69',
    800: '#006D54',
    900: '#005946',
    950: '#003329',
  },
  cabo: {
    50: '#F6F8F9',
    100: '#EBEFF3',
    200: '#D3DCE4',
    300: '#ACBECD',
    400: '#809CB0',
    500: '#608097',
    600: '#506D85',
    700: '#3E5366',
    800: '#364756',
    900: '#313D49',
  },

  fuji: {
    50: '#BBBEC3',
    100: '#9FA6AE',
    200: '#838C97',
    300: '#667382',
    400: '#526274',
    500: '#3C4F63',
    600: '#2F4153',
    700: '#212F3E',
    800: '#131E29',
    900: '#000814',
  },
  nevada: {
    50: '#F6F7F6',
    100: '#F3F4F3',
    200: '#EFF0EF',
    300: '#E9EBE9',
    400: '#E2E5E2',
    500: '#D8DCD8',
    600: '#B6BDB6',
    700: '#98A398',
    800: '#7F8C7F',
    900: '#6C786C',
    950: '#4E574E',
  },
  santorini: {
    50: '#F2F6FC',
    100: '#E1EBF8',
    200: '#C9DCF4',
    300: '#A4C6EC',
    400: '#78A7E2',
    500: '#5F8DDA',
    600: '#446ECC',
    700: '#3B5BBA',
    800: '#354B98',
    900: '#2F4279',
  },

  green: {
    50: '#EDFFED',
    100: '#E0F8E1',
    200: '#C3EFC6',
    300: '#95E09B',
    400: '#5FC968',
    500: '#3BB545',
    600: '#2A8F32',
    700: '#24712B',
    800: '#215A27',
    900: '#1D4A22',
  },

  red: {
    50: '#FFF1F0',
    100: '#FFDFDE',
    200: '#FFC5C3',
    300: '#FF9E9A',
    400: '#FF655F',
    500: '#FF352E',
    600: '#F41D15',
    700: '#CD0F08',
    800: '#A9110B',
    900: '#8C1410',
  },

  orange: {
    50: '#FFF4E5',
    100: '#FFEED3',
    200: '#FFD8A5',
    300: '#FFBC6D',
    400: '#FF9432',
    500: '#FF750A',
    600: '#FE5B00',
    700: '#CC4002',
    800: '#A1320B',
    900: '#822C0C',
  },

  mono: {
    100: '#FFFFFF',
    200: '#F2F2F2',
    300: '#E5E5E5',
    400: '#B2B2B2',
    500: '#8C8C8C',
    600: '#595959',
    700: '#404040',
    800: '#262626',
    900: '#000000',
  },
  transparency: {
    20: 'rgba(226, 229, 226, 0.2)',
    40: 'rgba(229, 229, 229, 0.4)',
    80: 'rgba(246, 248, 249, 0.8)',
  },
} as const;

export const colors = {
  ...primitiveColors,
  surface: {
    page: primitiveColors.cabo[50],
    primary: primitiveColors.mono[100],
    secondary: primitiveColors.nevada[400],
    subtle: primitiveColors.cabo[50],
    disabled: primitiveColors.mono[300],
    informative: primitiveColors.nusa[50],
    success: primitiveColors.green[50],
    warning: primitiveColors.orange[50],
    error: primitiveColors.red[50],
    brand: {
      1: primitiveColors.nusa[100],
      2: primitiveColors.cabo[600],
      3: primitiveColors.nevada[500],
      4: primitiveColors.fuji[800],
      5: primitiveColors.santorini[600],
      6: primitiveColors.santorini[900],
    },
    action: {
      feature: {
        default: primitiveColors.nusa[200],
        hover: primitiveColors.nusa[300],
        active: primitiveColors.nusa[400],
      },
      primary: {
        default: primitiveColors.fuji[800],
        hover: primitiveColors.nusa[200],
        active: primitiveColors.nusa[300],
      },
      secondary: {
        active: primitiveColors.nusa[300],
        hover: primitiveColors.nusa[200],
      },
      tertiary: {
        active: primitiveColors.cabo[200],
        hover: primitiveColors.cabo[100],
      },
      destructive: {
        default: primitiveColors.red[100],
        active: primitiveColors.red[300],
        hover: primitiveColors.red[200],
      },
      selected: primitiveColors.nusa[50],
      unselected: primitiveColors.cabo[100],
      // Mostly used for toggle/switches
      on: primitiveColors.nusa[300],
      off: primitiveColors.cabo[300],
    },
    card: {
      pressed: primitiveColors.transparency[20],
      disabled: primitiveColors.transparency[40],
    },
  },
  border: {
    bold: primitiveColors.cabo[300],
    subtle: primitiveColors.cabo[100],
    disable: primitiveColors.mono[400],
    action: {
      default: primitiveColors.fuji[800],
      hover: primitiveColors.nusa[200],
      active: primitiveColors.nusa[300],
      focused: primitiveColors.nusa[300],
    },
    information: primitiveColors.nusa[50],
    error: primitiveColors.red[600],
    success: primitiveColors.green[600],
    warning: primitiveColors.orange[600],
  },
  icon: {
    primary: primitiveColors.fuji[800],
    'dark-surface': primitiveColors.mono[100],
    disabled: primitiveColors.mono[400],
    placeholder: primitiveColors.cabo[300],
    brand: {
      1: primitiveColors.nusa[300],
      2: primitiveColors.cabo[600],
    },
    action: primitiveColors.fuji[800],
    error: primitiveColors.red[600],
    success: primitiveColors.green[600],
    warning: primitiveColors.orange[600],
  },
  // text colors start here
  heading: primitiveColors.fuji[800],
  body: primitiveColors.fuji[800],
  'dark-surface': primitiveColors.mono[100],
  subtle: primitiveColors.fuji[400],
  disabled: primitiveColors.mono[400],
  placeholder: primitiveColors.fuji[200],
  brand: {
    1: primitiveColors.nusa[200],
    2: primitiveColors.cabo[600],
  },
  action: primitiveColors.fuji[800],
  'action-dark-surface': primitiveColors.mono[100],
  link: {
    default: primitiveColors.cabo[700],
    hover: primitiveColors.cabo[600],
    active: primitiveColors.cabo[400],
  },
  error: primitiveColors.red[700],
  success: primitiveColors.green[800],
  warning: primitiveColors.orange[800],
  // text colors end here
} as const;
