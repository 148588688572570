import { Text } from '@components';
import { i18n } from '@i18n';

export function UsTravelDatesProtectionModal() {
  return (
    <Text variant="body-16/m" withMarkDown>
      {i18n.t('travelDates.restrictionModal.content')}
    </Text>
  );
}
