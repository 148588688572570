import { SkeletonDiv } from '@elements/skeleton';

const benefitsList = Array.from({ length: 5 }, () => 'benefits');

export const BenefitsSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div className="grid grid-flow-col gap-x-s8 mb-4">
        <SkeletonDiv className="w-full" numberOfRows={2} />
        <SkeletonDiv className="w-1/2 md:w-1/2 justify-self-end" />
      </div>
      <div className="flex flex-col space-y-s8 mb-8">
        <SkeletonDiv numberOfRows={3} />
      </div>
      <div>
        {benefitsList.map((benefit, index) => (
          <div
            key={`${benefit}-${index}`}
            className="border-b grid grid-cols-[3.75rem_1fr] md:grid-cols-[5rem_1fr] py-5 first:pt-0 last:border-b-0">
            <SkeletonDiv className="h-s48 w-s48 md:h-s64 md:w-s64" />
            <div>
              <div className="flex justify-between pb-4 md:pb-2">
                <SkeletonDiv className="w-1/4 sm:w-1/4" numberOfRows={2} />
              </div>
              <div className="flex flex-col space-y-s8">
                <SkeletonDiv />
                <SkeletonDiv className="w-1/2" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
