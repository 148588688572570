import { HTMLAttributes, createContext, useContext, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextWithMd } from '@elements/text/textWithMd';
import { sluggify, testProps } from '@utils';

type TabProps = {
  tabs: Array<{ tabName: string; panel: React.ReactNode }>;
} & HTMLAttributes<HTMLDivElement>;

type TabContextType = TabProps & {
  activeTab: number;
  handleTab: (index: number) => void;
};

const TabContext = createContext<TabContextType>({} as TabContextType);

export const Tab = (props: TabProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTab = (index: number) => {
    setActiveTab(index);
  };
  return (
    <TabContext.Provider value={{ ...props, activeTab, handleTab }}>
      <TabBar />
      <TabPanel />
    </TabContext.Provider>
  );
};

const TabBar = () => {
  const { tabs, activeTab, handleTab, ...rest } = useContext(TabContext);
  const { className } = rest;
  const tabRefs = useRef<HTMLButtonElement[]>([]);

  const [tabWidth, setTabWidth] = useState(0);
  const [tabHeight, setTabHeight] = useState(0);

  useEffect(() => {
    if (tabRefs.current) {
      const dimension = tabRefs.current.map(tab => ({
        width: tab?.clientWidth,
        height: tab?.clientHeight,
      }))[0];

      setTabWidth(dimension.width);
      setTabHeight(dimension.height);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (tabRefs.current) {
        const dimension = tabRefs.current.map(tab => ({
          width: tab?.clientWidth,
          height: tab?.clientHeight,
        }))[0];

        setTabWidth(dimension.width);
        setTabHeight(dimension.height);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={twMerge('bg-surface-secondary rounded-full w-full p-s8 relative', className)}
      {...rest}>
      <div className="flex flex-row">
        {tabs.map((tab, index) => (
          <button
            key={tab.tabName}
            type="button"
            className="flex-1 z-10 py-s12 text-center cursor-pointer rounded-full"
            ref={(el: HTMLButtonElement) => (tabRefs.current[index] = el)}
            onClick={() => handleTab(index)}
            {...testProps(`tab-${sluggify(tab.tabName)}`)}>
            <TextWithMd variant="body-16/r">{tab.tabName}</TextWithMd>
          </button>
        ))}
        <AnimatedBar activeTab={activeTab} tabHeight={tabHeight} tabWidth={tabWidth} />
      </div>
    </div>
  );
};

const TabPanel = () => {
  const { activeTab, tabs } = useContext(TabContext);
  return (
    <div className="p-s12 grid">
      {tabs.map((tab, index) => (
        <div
          key={tab.tabName}
          className={twMerge(
            'duration-300 col-start-1 row-start-1 hidden',
            activeTab === index && 'block',
          )}>
          {tab.panel}
        </div>
      ))}
    </div>
  );
};

const AnimatedBar = ({
  activeTab,
  tabWidth,
  tabHeight,
}: {
  activeTab: number;
  tabWidth: number;
  tabHeight: number;
}) => {
  return (
    <div
      className={twMerge(
        'rounded-full bg-white absolute top-s8 p-s12 transition-transform duration-300 ease-in-out',
      )}
      style={{
        width: tabWidth,
        height: tabHeight,
        transform: `translateX(${activeTab * tabWidth}px)`,
      }}></div>
  );
};
