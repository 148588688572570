import { BOOST_CATEGORY, BOOST_TYPES, Boost } from '@packages/types';

import { ButtonVariant } from '@elements/button';
import { i18n } from '@i18n';

export function getBoostPrimaryButtonAttributes(
  boost?: Boost,
  isEligible?: boolean,
): [string, ButtonVariant] {
  if (!boost) return [i18n.t('boostDetails.boostHeader.primaryButton.add'), 'primary'];
  const isBoostAddedToTrip = boost?.toUpdate?.isAdded ?? boost?.isAdded;

  if (boost?.isMandatory) {
    return [i18n.t('boostDetails.boostHeader.primaryButton.mandatory'), 'secondary'];
  }

  if (!isEligible)
    return [i18n.t('boostDetails.boostHeader.primaryButton.notEligible'), 'destructive'];

  switch (boost.category) {
    case BOOST_CATEGORY.MY_STUFF:
      switch (boost.code) {
        case BOOST_TYPES.EXTRA_CANCELLATION:
          return [i18n.t('boostDetails.boostHeader.primaryButton.limit'), 'primary'];
        case BOOST_TYPES.SPECIFIED_ITEMS: {
          const buttonTextId = isBoostAddedToTrip
            ? i18n.t('boostDetails.boostHeader.primaryButton.removeAll')
            : i18n.t('boostDetails.boostHeader.primaryButton.addItem');
          const buttonVariant = isBoostAddedToTrip ? 'secondary' : 'primary';
          return [buttonTextId, buttonVariant];
        }

        default: {
          const buttonTextId = isBoostAddedToTrip
            ? i18n.t('boostDetails.boostHeader.primaryButton.remove')
            : i18n.t('boostDetails.boostHeader.primaryButton.add');
          const buttonVariant = isBoostAddedToTrip ? 'secondary' : 'primary';
          return [buttonTextId, buttonVariant];
        }
      }
    case BOOST_CATEGORY.MY_ACTIVITIES: {
      const buttonTextId = boost.isAdded
        ? i18n.t('boostDetails.boostHeader.primaryButton.payPerDayEdit')
        : i18n.t('boostDetails.boostHeader.primaryButton.payPerDaySelect');
      const buttonVariant = 'primary';
      return [buttonTextId, buttonVariant];
    }
    default:
      return [
        isBoostAddedToTrip
          ? i18n.t('boostDetails.boostHeader.primaryButton.remove')
          : i18n.t('boostDetails.boostHeader.primaryButton.add'),
        isBoostAddedToTrip ? 'secondary' : 'primary',
      ];
  }
}
