import React, { LegacyRef, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { testProps } from '@utils';

import { CheckboxSVGIcon, CheckboxSVGIconProps } from '../checkboxSVG';
import { Text, TextProps } from '../text';

interface CheckboxProps extends CheckboxSVGIconProps, React.InputHTMLAttributes<HTMLInputElement> {
  labelProps?: TextProps;
  onClick?: () => void;
  hasError?: boolean;
  errorMessage?: string;
  errorTextClassName?: string;
}
export const Checkbox: React.FC<CheckboxProps> = forwardRef(
  (
    {
      isChecked,
      onClick,
      labelProps,
      className,
      hasError,
      errorMessage,
      name,
      errorTextClassName,
      ...rest
    }: CheckboxProps,
    ref?: LegacyRef<HTMLInputElement>,
  ) => {
    const [isSelected, setIsSelected] = useState(isChecked);

    const handleClick = () => {
      setIsSelected(prevSelected => !prevSelected);
      onClick?.();
    };

    return (
      <div className={twMerge('py-2 ', className)}>
        <input ref={ref} type="checkbox" id={name} className="hidden" {...rest} />
        <label
          htmlFor={name}
          className="flex cursor-pointer flex-row "
          onClick={handleClick}
          defaultChecked={isChecked}>
          <div {...testProps('checkbox')}>
            <CheckboxSVGIcon
              hasError={hasError}
              isChecked={isSelected}
              className="mr-3"
              variant={{ shape: 'square' }}
              {...rest}
            />
          </div>
          {labelProps && (
            <Text {...labelProps} className={twMerge('mt-0.5', labelProps?.className)}>
              {labelProps.children}
            </Text>
          )}
        </label>
        {hasError && errorMessage && (
          <Text
            className={twMerge('text-red-500 ml-3 mt-2', errorTextClassName)}
            variant="subHeading-14/r">
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
