import { useCallback, useMemo, useState } from 'react';

import { isAURegionSelector, useRegionStore } from '@packages/stores';
import { EmailQuoteDetails } from '@packages/types';

import { EmailQuoteHeader, ModalComponentName } from '@components';
import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import {
  closeModalSelector,
  openModalSelector,
  useBoostDetailsStore,
  useModalStore,
  useQuoteStore,
} from '@store';
import { sendEmailQuote as handleSendEmailQuote } from '@store/quote';
import { EMAIL_QUOTE_FLOW_TYPE } from '@types';
import { sendAnalyticsEvent, sendAnalyticsUserId } from '@utils';

export const useEmailQuote = () => {
  const openModal = useModalStore(openModalSelector);
  const closeModal = useModalStore(closeModalSelector);
  const emailQuoteLaunchedFrom = useQuoteStore(state => state.emailQuoteLaunchedFrom);
  const setEmailQuoteLaunchedFrom = useQuoteStore(state => state.setEmailQuoteLaunchedFrom);
  const boostId = useBoostDetailsStore(state => state.boostDetails?.boostId);
  const [hasExceedEmailLimit, setHasExceedEmailLimit] = useState(false);
  const [isEmailQuoteSubmitting, setEmailQuoteSubmitting] = useState(false);

  const isAU = useRegionStore(isAURegionSelector);
  const currentPathname = getCurrentPathname();
  const { myQuote, checkout } = ROUTE_PATHS();

  const isEmailQuoteVisible = useMemo(
    () => isAU && (currentPathname === myQuote || currentPathname === checkout) && !!boostId,
    [isAU, currentPathname, myQuote, checkout, boostId],
  );

  const closeEmailQuoteModal = useCallback(() => {
    setEmailQuoteLaunchedFrom(null);

    closeModal();
  }, [setEmailQuoteLaunchedFrom, closeModal]);

  const openEmailQuoteModal = useCallback(
    (
      bodyType: ModalComponentName = 'EmailQuote',
      isResult = false,
      flow = EMAIL_QUOTE_FLOW_TYPE.FOOTER,
      onClose?: () => void,
    ) => {
      setEmailQuoteLaunchedFrom(flow);
      openModal('Modal', {
        body: { type: bodyType, flow },
        size: 'md',
        ImageTitle: <EmailQuoteHeader />,
        bodyContainerClassName: 'p-4 sm:p-6',
        bgColor: isResult ? 'charcoal' : 'snow',
        shouldCloseOnOverlayClick: false,
        onClose: () => {
          closeEmailQuoteModal();
          onClose?.();
        },
      });
    },
    [openModal, setEmailQuoteLaunchedFrom, closeEmailQuoteModal],
  );

  const sendEmailQuote = useCallback(
    async (emailQuoteDetails: EmailQuoteDetails) => {
      setEmailQuoteSubmitting(true);
      const response = await handleSendEmailQuote(emailQuoteDetails);

      sendAnalyticsEvent('Email a quote form submitted', {
        flow: emailQuoteLaunchedFrom,
      });

      if (response === 'Success') {
        closeEmailQuoteModal();
        openEmailQuoteModal('EmailQuoteSuccess', true);
        sendAnalyticsUserId(null, { ...emailQuoteDetails });
      } else if (response === 'Limit') {
        setHasExceedEmailLimit(true);
      } else {
        closeEmailQuoteModal();
        openEmailQuoteModal('EmailQuoteFailed', true);
      }
      setEmailQuoteSubmitting(false);
    },
    [closeEmailQuoteModal, openEmailQuoteModal, emailQuoteLaunchedFrom],
  );

  return {
    openEmailQuoteModal,
    sendEmailQuote,
    closeEmailQuoteModal,
    hasExceedEmailLimit,
    isEmailQuoteVisible,
    isEmailQuoteSubmitting,
  };
};
