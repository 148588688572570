import { Button, ButtonProps } from '@elements/button';

export interface FooterButtonGroupProps {
  nextButtonProps: ButtonProps;
  backButtonProps: ButtonProps;
}

export const FooterButtonGroup = ({ backButtonProps, nextButtonProps }: FooterButtonGroupProps) => (
  <>
    <Button {...nextButtonProps} />
    <Button {...backButtonProps} />
  </>
);
